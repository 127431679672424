import { Form } from "antd";
import GlobalContext from "./GlobalContext";
import { useEffect, useState } from "react";
import {
  useAllDelegationList,
  useFormesJuridiquesList,
  useGovermentList,
  useNationlaityList,
  useSectorList,
} from "./FetchLists";
import { useSessionStorageState } from "ahooks";
import axiosInstance from "../utils/axios";
import PropTypes from "prop-types";

const AppContext = (props) => {
  const [globalData, setGlobalData] = useState(
    sessionStorage.getItem("globalData")
      ? JSON.parse(sessionStorage.getItem("globalData"))
      : {}
  );
  const [isRtl, setIsRtl] = useState(localStorage.getItem("language") === "ar");
  const [form] = Form.useForm();
  const [porteDoc, setPorteDoc] = useState([]);
  const [pageN, setPageN] = useState();
  const [brouillon, setBrouillon] = useState([]);
  const [nbrBrouillon,setNbrBrouillon]=useState("")
  const [personneMorale, setPersonneMorale] = useState(
    sessionStorage.getItem("personneMorale") === "true"
  );
  const [listTasks, setListTasks] = useState([]);
  const [employeurByPatNumPatCL, setEmployeurByPatNumPatCL] =
    useSessionStorageState("employeurByPatNumPatCLData", { defaultValue: [] });
  const [ficheEntrepriseDGI, setFicheEntrepriseDGI] = useSessionStorageState(
    "ficheEntrepriseDGIData",
    { defaultValue: [] }
  );
  const [etablissementSecondaireDGI, setEtablissementSecondaireDGI] =
    useSessionStorageState("etablissementSecondaireDGIData", {
      defaultValue: [],
    });
  const [listActiviteRNE, setListActiviteRNE] = useSessionStorageState(
    "listActiviteRNEData",
    { defaultValue: [] }
  );
  const [entrepriseRegistrationId, setEntrepriseRegistrationId] =
    useSessionStorageState("entrepriseRegistrationIdData", {
      defaultValue: [],
    });

  const [matriculeFiscale, setMatriculeFiscale] = useState(
    sessionStorage?.getItem("matriculeFiscale")
  );
  const [numeroEnregistrement, setNumeroEnregistrement] = useState(
    sessionStorage?.getItem("numeroEnregistrement")
      ? sessionStorage?.getItem("numeroEnregistrement")
      : ""
  );

  const gouvernoratList = useGovermentList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
      id: res?.id,
      name_fr: res?.name_fr,
    };
  });

  const listAllDelegations = useAllDelegationList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
      id: res?.id,
    };
  });

  const nationalityList = useNationlaityList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
    };
  });

  const FormesJuridiquesList = useFormesJuridiquesList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
    };
  });

  const secteurData = useSectorList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
      id: res?.id,
    };
  });

  const nationalityOptions = nationalityList?.map((item) => ({
    label: item?.text,
    value: item?.value,
  }));

  const gouvernoratOptions = gouvernoratList?.map((item) => ({
    label: item?.text,
    value: item?.value,
    id: item?.id,
  }));

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("-x-token-user"))?.Acces_Token) {
      axiosInstance
        .post(
          `${import.meta.env.VITE_APP_GEOPROD_API}/nbr_tache_by_user`,
          {
            status: [
              import.meta.env.VITE_APP_ETAT_VALIDATION_PROVISOIRE,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_VALIDATION_PROVISOIRE,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_EPF_EXISTANT,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_MODIFICATION,
            ],
            brouillon:[
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON
            ]
          },
          {
            headers: {
              Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                ?.Acces_Token,
            },
          }
        )
        .then((res) => {
          setNbrBrouillon(res?.data?.nombre_brouillon)
          setListTasks(res?.data?.nombre_taches);
        });
    }
  }, []);



  return (
    <GlobalContext.Provider
      value={{
        globalData,
        setGlobalData,
        form,
        isRtl,
        setIsRtl,
        porteDoc,
        setPorteDoc,
        pageN,
        setPageN,
        brouillon,
        setBrouillon,
        nationalityOptions,
        gouvernoratOptions,
        secteurData,
        gouvernoratList,
        nationalityList,
        FormesJuridiquesList,
        personneMorale,
        setPersonneMorale,
        matriculeFiscale,
        setMatriculeFiscale,
        numeroEnregistrement,
        setNumeroEnregistrement,
        listAllDelegations,
        employeurByPatNumPatCL,
        setEmployeurByPatNumPatCL,
        ficheEntrepriseDGI,
        setFicheEntrepriseDGI,
        etablissementSecondaireDGI,
        setEtablissementSecondaireDGI,
        listActiviteRNE,
        setListActiviteRNE,
        entrepriseRegistrationId,
        setEntrepriseRegistrationId,
        listTasks,
        setListTasks,nbrBrouillon,setNbrBrouillon
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

AppContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContext;
