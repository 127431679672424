import { notification } from "antd";
import {
  saveAffaire,
  searchByFiscalAndGoverment,
  updateAffaire,
  uploadDocumentAffaire,
} from "../../../../api/geoprod";
import { t } from "i18next";

import {
  fetchCodePostalList,
  fetchDelegationList,
  fetchSpetialityList,
  fetchSubSectorList,
} from "../../../../context/FetchLists";
import { nanoid } from "nanoid";

export const onUploadFiles = (survey, type, option_modification) => {
  survey.onUploadFiles.add((_, options) => {
    const formData = new FormData();
    const id_affaire =
      type === "modifierProfile"
        ? sessionStorage.getItem("id_affaire_profile")
        : type === "changementInvestisseur"
        ? sessionStorage.getItem("idAffaireChangementInvestisseur")
        : type === "modification"
        ? sessionStorage.getItem(`id_affaire_edit_${option_modification}`)
        : type === "epfExistant"
        ? sessionStorage.getItem("idAffaireEpfExistant")
        : type
        ? sessionStorage.getItem("id_affaire_annexe")
        : sessionStorage.getItem("id_affaire");
    const id_type_doc = import.meta.env.VITE_APP_ID_TYPE_DOC;
    options.files.forEach((file) => {
      const newFileName = `${
        options.question.title.split("-")[0] + nanoid(12)
      }.${file.name.split(".").pop()}`;
      const renamedFile = new File([file], newFileName, { type: file.type });
      formData.append("id_affaire", id_affaire);
      formData.append("id_type_doc", id_type_doc);
      formData.append("name_doc", options.name);
      // formData.append("file[]", file);
      formData.append("file[]", renamedFile);
      formData.append("key", options.name);
    });

    uploadDocumentAffaire(formData)
      .then((data) => {
        options.callback(
          options.files.map((file) => {
            return {
              file: file,
              content: data.data.path,
            };
          })
        );

        setTimeout(() => {
          const linkElement = document?.querySelector(
            `a[href="${data?.data?.path}"][title="${data?.data?.path
              .split("/")
              .pop()}"][download="${data?.data?.path?.split("/")?.pop()}"]`
          );

          if (linkElement) {
            linkElement?.setAttribute("target", "_blank");
          } else {
            console.log("Element not found");
          }
        }, [3000]);
      })
      .catch((error) => {
        options.callback(
          [],
          [
            t(
              "Une erreur est survenue lors du téléversement de votre document. Veuillez réessayer."
            ),
          ]
        );
      });
  });
};
export const onDynamicPanelItemValueChanged = (
  survey,
  secteurData,
  nationalityList,
  gouvernoratList,
  type,
  getSurveyData,
  setSurveyData
) => {
  let listSousSecteurs;
  let listSpetialiteDiplomes;

  survey.onDynamicPanelItemValueChanged.add(async function (sender, options) {
    if (options.question.name === "management_data") {
      const panel = options.panel;
      if (options.name?.includes("_governorate")) {
        const delegateKey = options.name.replace("_governorate", "_delegation");
        const selectedGouvernorat = options.value;
        const selectedDelegate = panel.getQuestionByName(delegateKey);
        const id_goverment = gouvernoratList.find(
          (e) => e.value === selectedGouvernorat
        )?.id;
        const delegations = await fetchDelegationList(id_goverment);
        selectedDelegate.choices = delegations || [];
      }
      const nationalityQuestion = panel.getQuestionByName(
        "management_data_staff_nationality"
      );

      nationalityQuestion.choices = nationalityList;
      const govermentQuestion = panel.getQuestionByName(
        "management_data_staff_residence_governorate"
      );

      govermentQuestion.choices = gouvernoratList;
      const tunisianVisibleQuestions = [
        "management_data_staff_nationality",
        "management_data_staff_cin_number",
        "management_data_staff_birth_date",
        "management_data_search",
      ];

      const dynamicPanel = options.question;

      if (options.name === "management_data_staff_nationality") {
        const isTunisian = options.value === "تونسية";

        const nonTunisianQuestions = [
          "management_data_staff_passport_copy",
          "management_data_staff_passport_number",
          "management_data_staff_nationality",
        ];

        const alwaysVisibleQuestions = [
          "management_data_staff_valid",
          "management_data_staff_cin_copy",
          "management_data_staff_date_delivrance",
          "management_data_staff_cin_number",
          "management_data_check_spinner",
          "management_data_search",
        ];

        dynamicPanel.panels.forEach((panel) => {
          if (panel === options.panel) {
            panel.getQuestions().forEach((question) => {
              question.visible = false;

              if (isTunisian) {
                question.visible = tunisianVisibleQuestions.includes(
                  question.name
                );
              } else {
                const valid = panel.getQuestionByName(
                  "management_data_staff_valid"
                );
                valid.value = true;
                const cinDn = [
                  "management_data_staff_cin_number",
                  "management_data_staff_passport_number",
                  "management_data_staff_birth_date",
                ];
                question.visible = nonTunisianQuestions.includes(question.name);
                if (
                  !tunisianVisibleQuestions.includes(question.name) ||
                  cinDn.includes(question.name)
                ) {
                  question.readOnly = false;
                  question.clearValue();
                }
              }

              if (
                !alwaysVisibleQuestions.includes(question.name) &&
                !isTunisian
              ) {
                question.visible = true;
              }
            });
          }
        });
      }

      if (
        (options.name === "management_data_staff_cin_number" ||
          options.name === "management_data_staff_birth_date") &&
        panel.getQuestionByName("management_data_staff_nationality").value ===
          "تونسية"
      ) {
        dynamicPanel.panels.forEach((panel) => {
          if (panel === options.panel) {
            panel.getQuestionByName(
              "management_data_staff_valid"
            ).value = false;

            panel.getQuestions().forEach((question) => {
              const cinDn = [
                "management_data_staff_cin_number",
                "management_data_staff_birth_date",
                "management_data_staff_nationality",
              ];
              if (!tunisianVisibleQuestions.includes(question.name)) {
                question.clearValue();
                if (!cinDn.includes(question.name)) {
                  question.visible = false;
                }
              }
            });
          }
        });
      }
    }

    if (options.question.name === "training_nature_data" && secteurData) {
      if (options.name === "training_type_data_sector") {
        const panel = options.panel;

        const sousSecteurQuestion = panel.getQuestionByName(
          "training_type_data_sub_sector"
        );

        const selectedSector = options.value;

        const id = secteurData.find(
          (elem) => elem.value === selectedSector
        )?.id;

        listSousSecteurs = await fetchSubSectorList(id);
        if (listSousSecteurs) {
          sousSecteurQuestion.choices = listSousSecteurs;
        } else {
          sousSecteurQuestion.choices = [];
        }
      }

      if (options.name === "training_type_data_sub_sector") {
        const panel = options.panel;
        const selectedSubSector = options.value;
        const specialtyQuestion = panel.getQuestionByName(
          "training_type_data_specialty"
        );

        const id = listSousSecteurs.find(
          (elem) => elem.value === selectedSubSector
        )?.id;
        listSpetialiteDiplomes = await fetchSpetialityList(id);

        if (listSpetialiteDiplomes) {
          specialtyQuestion.choices = listSpetialiteDiplomes.map(
            (item) => item[0].spetialite
          );
        } else {
          specialtyQuestion.choices = [];
        }
      }

      if (options.name === "training_type_data_specialty") {
        const panel = options.panel;
        const selectedSpecialty = options.value;
        const diplomaQuestion = panel.getQuestionByName(
          "training_type_data_diploma"
        );

        const selectedSector = panel.getQuestionByName(
          "training_type_data_sector"
        ).value;
        const id_spetialite = listSpetialiteDiplomes.find(
          (elem) => elem[0].spetialite === selectedSpecialty
        )[0]?.id_spetialite;

        if (selectedSector) {
          const id = secteurData.find(
            (elem) => elem.value === selectedSector
          )?.id;

          listSousSecteurs = await fetchSubSectorList(id);
        }

        const selectedSubSector = panel.getQuestionByName(
          "training_type_data_sub_sector"
        ).value;
        if (selectedSubSector) {
          const id = listSousSecteurs.find(
            (elem) => elem.value === selectedSubSector
          )?.id;
          listSpetialiteDiplomes = await fetchSpetialityList(id);
        }
        const specialtyData = listSpetialiteDiplomes?.find(
          (item) => item[0].spetialite === selectedSpecialty
        );

        if (specialtyData) {
          diplomaQuestion.value = specialtyData[0].diplome;
        } else {
          diplomaQuestion.value = "";
        }

        const surveyData = await getSurveyData();

        if (surveyData && type === "edit") {
          surveyData.response.data.training_nature_data[
            survey
              .getQuestionByName("training_nature_data")
              .panels.indexOf(panel)
          ] = {
            ...survey
              .getQuestionByName("training_nature_data")
              .panels.indexOf(panel),
            training_type_data_specialty_id: id_spetialite,
          };
          setSurveyData(surveyData);
        } else {
          surveyData.training_nature_data[
            survey
              .getQuestionByName("training_nature_data")
              .panels.indexOf(panel)
          ] = {
            ...survey
              .getQuestionByName("training_nature_data")
              .panels.indexOf(panel),
            training_type_data_specialty_id: id_spetialite,
          };
          setSurveyData(surveyData);
        }
        survey.data = {
          ...survey.data,
          training_nature_data: survey.data.training_nature_data
            ? survey.data.training_nature_data.map(
                (item, index) =>
                  index ===
                  survey
                    .getQuestionByName("training_nature_data")
                    .panels.indexOf(panel)
                    ? {
                        ...item,
                        training_type_data_specialty_id: id_spetialite,
                      }
                    : item // Keep other panels unchanged
              )
            : [],
        };
      }
      // if (options.name === "training_type_data_type" && type === "edit") {
      //   // const trainerPage = survey.getPageByName("page5");
      //   const spacePage = survey.getPageByName("page6");
      //   const equipmentPage = survey.getPageByName("page7");
      //   // if (options.value !== "منظرة") {
      //   //   const anyPanelIsHomologed = survey
      //   //     .getQuestionByName("training_nature_data")
      //   //     .panels?.some(
      //   //       (panel) =>
      //   //         panel.getQuestionByName("training_type_data_type")?.value ===
      //   //         "منظرة"
      //   //     );

      //   //   if (!anyPanelIsHomologed) {
      //   //     trainerPage.visible = false;
      //   //     spacePage.visible = false;
      //   //     equipmentPage.visible = false;
      //   //   }
      //   // } else {
      //     // trainerPage.visible = true;
      //     spacePage.visible = true;
      //     equipmentPage.visible = true;
      //   // }
      // }
    }

    if (options.question.name === "trainer_data") {
      const panel = options.panel;
      const valid = panel.getQuestionByName("trainer_data_test_valid");
      const cinDn = [
        "trainer_data_passport",
        "trainer_data_cin_number",
        "trainer_data_nationality",
        "trainer_data_test_valid",
      ];

      const nationalityQuestion = panel.getQuestionByName(
        "trainer_data_nationality"
      );

      nationalityQuestion.choices = nationalityList;
      const govermentQuestion = panel.getQuestionByName(
        "trainer_data_governorate"
      );
      govermentQuestion.choices = gouvernoratList;
      if (options.name === "trainer_data_nationality") {
        valid.value = false;

        panel.getQuestions().forEach((question) => {
          if (
            cinDn.includes(question.name) &&
            question.name !== "trainer_data_nationality" &&
            question.name !== "trainer_data_test_valid"
          ) {
            question.clearValue();
          }
        });
      } else if (cinDn.includes(options.name)) {
        valid.value = false;
        panel.getQuestions().forEach((question) => {
          if (!cinDn.includes(question.name)) {
            question.visible = false;
            question.clearValue();
          }
        });
      }

      if (options.name === "trainer_data_situation" && type === "edit") {
        const trainer_data_date_fin_contrat = panel.getQuestionByName(
          "trainer_data_date_fin_contrat"
        );
        const trainer_and_training_data = JSON.parse(
          sessionStorage.getItem("trainer_and_training_data")
        );
        const panel_passeport = panel.getQuestionByName(
          "trainer_data_passport"
        );
        const panle_cin = panel.getQuestionByName("trainer_data_cin_number");
        if (
          trainer_and_training_data.trainer_data?.some(
            (trainer) =>
              trainer.trainer_data_passport === panel_passeport.value ||
              trainer.trainer_data_cin === panle_cin.value
          )
        ) {
          if (
            options.value === "غير قار" &&
            trainer_and_training_data.trainer_data[
              sender.getQuestionByName("trainer_data").panels.indexOf(panel)
            ]?.trainer_data_situation === "قار" &&
            trainer_data_date_fin_contrat
          ) {
            trainer_data_date_fin_contrat.visible = true;
          } else {
            trainer_data_date_fin_contrat.visible = false;
          }
        }
      }
    }
  });
};

export const onComplete = (
  survey,
  setOpenModal,
  setIsLoading,
  annexe,
  handleFinish,
  setSurveyData
) => {
  survey.onCompleting.add(async (sender, options) => {
    options.allowComplete = false;
    sender.allowComplete = false;
    const payload = {
      additional_form: "{}",
      json_prod: { ...survey.data },
      current_step: survey.currentPageNo,
    };

    if (setSurveyData) setSurveyData(survey.data);
    const id_affaire =
      annexe === "epfExistant"
        ? sessionStorage.getItem("idAffaireEpfExistant")
        : annexe == "annexe"
        ? sessionStorage.getItem("id_affaire_annexe")
        : sessionStorage.getItem("id_affaire");

    if (payload) {
      sessionStorage.setItem("payload", payload);

      if (annexe != "annexe") {
        const data = await updateAffaire(id_affaire, payload, "Brouillon");
        if (!data) {
          notification.error({
            description: "une errur est survenue",
          });
          return;
        }
        setOpenModal(true);
      } else {
        await updateAffaire(id_affaire, payload, "Brouillon");
        setIsLoading(true);
        await handleFinish();
      }
    }
  });
};

// export const onCompleteEditing=()=>{

// }

export const onCompleteInvestisseurChanging = (
  survey,
  setIsLoading,
  navigate
) => {
  survey.onComplete.add(async (sender, options) => {
    setIsLoading(true);
    const payload = {
      additional_form: "{}",
      json_prod: { ...survey.data },
    };
    if (payload) {
      try {
        // await updateAffaire(idAffaire, payload, "Brouillon");
        await saveAffaire(
          payload,
          "SoumettreModification",
          setIsLoading,
          "changementInvestisseur"
        );
      } catch {
        notification.error({
          message: t("Erreur"),
          description: t("Erreur lors de la soumission de demande."),
          duration: 0,
        });
        setIsLoading(false);
      } finally {
        notification.success({
          message: t("Votre demande a été envoyé avec succès."),
          duration: 0,
        });
        setIsLoading(false);
        navigate("/demandes");
      }
    }
  });
};

export const onCurrentPageChanged = (survey, secteurData) => {
  survey.onCurrentPageChanged.add(function (sender) {
    if (sessionStorage.getItem("consulter") === "false") {
      sessionStorage.setItem("current_step", survey.currentPageNo);
    }

    const currentPage = survey.currentPage;
    currentPage.questions.forEach((question) => {
      if (
        question.name.includes("_valid") ||
        question.name.includes("_active")
      ) {
        question.visible = false;
      }
    });
    notification.destroy();
  });
};

export const onValueChanging = (
  survey,
  gouvernoratList,
  listAllDelegations,
  type,
  getSurveyData,
  setSurveyJson,
  getSurveyJson,
  setSurveyData,
  annexeediting
) => {
  survey.onValueChanged.add(async function (sender, options) {
    const payload = {
      current_step: survey.currentPageNo,
      json_prod: { ...survey.data },
    };

    sessionStorage.setItem("payload", JSON.stringify(payload));

    if (
      (options.name === "establishment_data_training_type" ||
        options.name === "establishment_data_address") &&
      type === "edit"
    ) {
      const question_name = options.question.name;
      const spacePage = survey.getPageByName("page6");
      const equipmentPage = survey.getPageByName("page7");
      const directorPage = survey.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      const trainerPage = survey.getPageByName("page5");
      const annexe = sessionStorage.getItem("annexe") === "annexe";
      const pages = [
        "page5",
        "page6",
        "page7",
        "Données de Directeur de l’Établissement",
      ];
      const json = getSurveyJson();
      const data_for_comparison = JSON.parse(
        sessionStorage.getItem("data_for_comparison")
      );
      if (data_for_comparison[question_name] !== survey?.data[question_name]) {
        if (options.name === "establishment_data_address") {
          sessionStorage.setItem("establishment_data_address_changed", true);
        }
        sessionStorage.setItem("typeOneTwo", true);
        if (spacePage) spacePage.visible = true;
        if (equipmentPage) equipmentPage.visible = true;
        if (directorPage) directorPage.visible = true;
        if (trainerPage && !annexe) trainerPage.visible = true;

        json.pages.map((page) => {
          if (pages.includes(page.name)) {
            page.visible = true;
          }
        });
        setSurveyJson(json);

        survey.render();
      } else {
        sessionStorage.setItem("typeOneTwo", false);
        if (options.name === "establishment_data_address") {
          sessionStorage.setItem("establishment_data_address_changed", false);
        }
        if (spacePage) spacePage.visible = false;
        if (equipmentPage) equipmentPage.visible = false;
        if (directorPage) directorPage.visible = false;
        if (trainerPage) trainerPage.visible = false;
        json.pages.map((page) => {
          if (pages.includes(page.name)) {
            page.visible = false;
          }
        });
        setSurveyJson(json);
        survey.render();
      }
    }

    if (
      options.name === "establishment_data_training_type" &&
      options.value === "أساسي ومستمر"
    ) {
      const trainingPanel = survey.getQuestionByName("training_nature_data");

      trainingPanel.panels.forEach((panel) => {
        panel.questions.forEach((question) => {
          if (type === "edit") {
            return;
          }
          question.value = null;
        });
      });
      trainingPanel.panelCount = 2;
      trainingPanel.panels.forEach((panel, index) => {
        if (index === 0) {
          const panel_one_type = panel.getQuestionByName(
            "training_type_data_nature"
          );
          panel_one_type.value = "أساسي";
        } else if (index === 1) {
          const panel_two_type = panel.getQuestionByName(
            "training_type_data_nature"
          );
          panel_two_type.value = "مستمر";
        }
      });
    } else if (
      options.name === "establishment_data_training_type" &&
      options.value !== "أساسي ومستمر"
    ) {
      const trainingPanel = sender.getQuestionByName("training_nature_data");
      if (trainingPanel.panels.length > 1) {
        const matchingPanels = trainingPanel.panels.filter(
          (panel) =>
            panel.getQuestionByName("training_type_data_nature").value ===
            options.value
        );

        trainingPanel.panelCount = matchingPanels.length;

        matchingPanels.forEach((matchingPanel, index) => {
          trainingPanel.panels[index].questions.forEach(
            (question, questionIndex) => {
              const matchingQuestion = matchingPanel.questions[questionIndex];
              question.value = matchingQuestion.value;
            }
          );
        });
      }

      trainingPanel.panels.forEach((panel) => {
        panel.questions.forEach((question) => {
          if (question.name !== "training_type_data_nature") {
            if (type === "edit") {
              return;
            }
            question.value = null;
          } else {
            question.value = options.value;
          }
        });
      });
    }

    if (options.name?.includes("_governorate")) {
      const delegateKey = options.name.replace("_governorate", "_delegation");
      const govermentKey = survey.getQuestionByName(
        options.name.replace("_governorate", "_postal_code")
      );

      const delegationQuestion = survey.getQuestionByName(
        options.name.replace("_governorate", "_delegation")
      );

      if (delegationQuestion) {
        delegationQuestion.value = "";
        delegationQuestion.choices = [];
      }
      if (govermentKey) {
        govermentKey.value = "";
        govermentKey.choices = [];
      }
      const selectedGouvernorat = options.value;
      const selectedDelegate = sender.getQuestionByName(delegateKey);

      const id_goverment = gouvernoratList.find(
        (e) => e.value === selectedGouvernorat
      )?.id;

      const delegations = await fetchDelegationList(id_goverment);

      selectedDelegate.choices = delegations || [];
      if (
        options.name === "establishment_data_governorate" ||
        options.name === "establishment_director_data_governorate"
      ) {
        const nomGovernoratFr = gouvernoratList.find(
          (e) => e.value === selectedGouvernorat
        )?.name_fr;
        let surveyData = await getSurveyData();

        survey.data = {
          ...survey.data,
          [options.name + "_fr"]: nomGovernoratFr,
        };
        if (surveyData) {
          surveyData = {
            ...surveyData,
            [options.name + "_fr"]: nomGovernoratFr,
          };
          setSurveyData(surveyData);
        }
      }
    }
    if (options.name?.includes("_delegation")) {
      const selectedDelegation = options.value;

      const postalCodeQuestion = survey.getQuestionByName(
        options.name.replace("_delegation", "_postal_code")
      );

      if (postalCodeQuestion) {
        postalCodeQuestion.value = "";
        postalCodeQuestion.choices = [];
      }
      const idDelegation = listAllDelegations.find(
        (elem) => elem.value === selectedDelegation
      )?.id;
      if (idDelegation && postalCodeQuestion) {
        const listPostalCodes = await fetchCodePostalList(idDelegation);

        if (listPostalCodes) {
          postalCodeQuestion.choices = listPostalCodes.map((code) => ({
            value: code.value,
            text: code.text,
          }));
        } else {
          postalCodeQuestion.choices = [];
        }
      }
    }

    if (
      options.name === "establishment_data_governorate" ||
      options.name === "legal_entity_governorate"
    ) {
      const taxNumberValue =
        options.name === "legal_entity_governorate"
          ? sender.data["legal_entity_tax_number"]
          : sender.data["establishment_data_tax_number"];
      const establishment_data_governorate = survey.getValue(options.name);
      if (taxNumberValue && establishment_data_governorate) {
        const data = await searchByFiscalAndGoverment(
          taxNumberValue,
          establishment_data_governorate
        );
        sessionStorage.setItem(
          "MatriculeFiscaleGoverment",
          JSON.stringify(data)
        );
      } else {
        sessionStorage.setItem(
          "MatriculeFiscaleGoverment",
          JSON.stringify(null)
        );
      }
    }

    if (
      options.name === "legal_entity_tax_number" ||
      options.name === "establishment_data_tax_number"
    ) {
      const question = sender.getQuestionByName(options.name);
      question.clearErrors();
    }

    if (options.name?.includes("_email")) {
      const emailValue = options.value;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const question = sender.getQuestionByName(options.name);
      question.clearErrors();
      if (!emailRegex.test(emailValue) && !options.question.readOnly) {
        question.addError(t("Merci d'entrer une adresse mail valide."));
      }
    }

    if (options?.question?.jsonObj?.title?.toLowerCase()?.includes("arabe")) {
      const fieldValue = options.value;
      const regex = /^[\u0600-\u06FF0-9\s]+$/;
      const question = sender.getQuestionByName(options.name);
      question.clearErrors();
      if (fieldValue && !regex.test(fieldValue) && !options.question.readOnly) {
        question.addError(t("Veuillez entrer uniquement des lettres arabes."));
      }
    }

    if (
      options?.question?.jsonObj?.title?.toLowerCase()?.includes("arabe") &&
      options?.question?.jsonObj?.title?.toLowerCase()?.includes("adresse")
    ) {
      const fieldValue = options.value;
      const regex = /^[\u0600-\u06FF0-9\s]+$/;
      const question = sender.getQuestionByName(options.name);
      question.clearErrors();
      if (fieldValue && !regex.test(fieldValue) && !options.question.readOnly) {
        question.addError(
          t("Veuillez entrer uniquement des lettres arabes et des chiffres.")
        );
      }
    }

    if (
      options?.question?.jsonObj?.title?.toLowerCase()?.includes("français")
    ) {
      const fieldValue = options.value;
      const regex = options?.question?.jsonObj?.title
        ?.toLowerCase()
        ?.includes("adresse")
        ? /^[A-Za-zÀ-ÿ0-9\s,.'-]+$/
        : /^[A-Za-zÀ-ÿ\s-]+$/;
      const question = sender.getQuestionByName(options.name);
      question.clearErrors();
      if (fieldValue && !regex.test(fieldValue) && !options.question.readOnly) {
        question.addError(t("Veuillez entrer uniquement des lettres latines."));
      }
    }

    if (
      options?.question?.jsonObj?.title
        ?.toLowerCase()
        .includes("Numéro de la CIN") ||
      options?.question?.jsonObj?.title?.toLowerCase()?.includes("téléphone") ||
      options?.question?.jsonObj?.title?.toLowerCase()?.includes("fax") ||
      (options?.question?.jsonObj?.title?.toLowerCase()?.includes("cnss") &&
        !options?.question?.jsonObj?.title
          ?.toLowerCase()
          .includes("affiliation"))
    ) {
      const fieldValue = options.value;
      const regex = /^\d{8}$/;
      const question = sender.getQuestionByName(options.name);
      question.clearErrors();
      if (fieldValue && !regex.test(fieldValue) && !options.question.readOnly) {
        question.addError(t("Veuillez entrer exactement 8 chiffres."));
      }
    }

    const page = survey.getPageByName(
      "Données de Directeur de l’Établissement"
    );

    const tunisianVisibleQuestions = [
      "establishment_director_data_nationality",
      "establishment_director_data_identity",
      "establishment_director_data_cin_number",
      "establishment_director_data_birth_date",
      "director_search",
    ];

    if (
      options.name?.includes("establishment_director_data") &&
      type === "edit"
    ) {
      survey.data = {
        ...survey.data,
        establishment_director_data_edited: true,
      };
   
    }

    if (options.name === "establishment_director_data_email" &&  sessionStorage.getItem("demande") !== "true" &&
    sessionStorage.getItem("consulter") !== "true") {
      let surveyData = await getSurveyData();
      survey.data = Object.fromEntries(
        Object.entries(survey.data).filter(
          ([key]) => key !== "mail_directeur_existe"
        )
      );
      if (surveyData && surveyData?.response?.data) {
        surveyData.response.data = Object.fromEntries(
          Object.entries(surveyData.response.data).filter(
            ([key]) => key !== "mail_directeur_existe"
          )
        );
        setSurveyData(surveyData);
      }
      if (surveyData) {
        surveyData = Object.fromEntries(
          Object.entries(surveyData).filter(
            ([key]) => key !== "mail_directeur_existe"
          )
        );
        setSurveyData(surveyData);
      }
    }

    if (
      options.name === "establishment_director_data_nationality" &&
      sender.getQuestionByName("establishment_director_data_identity").value !==
        "المستثمر نفسه" &&
      sessionStorage.getItem("demande") !== "true" &&
      sessionStorage.getItem("consulter") !== "true"
    ) {
      const isTunisian = options.value === "تونسية";

      let nonTunisianQuestions = [
        "establishment_director_data_test_valid",
        "establishment_director_data_passeport_copie",
        "copie_autorisation_ministre",
        "establishment_director_data_ministerial_auth_date",
        "establishment_director_data_passeport",
      ];

      const alwaysVisibleQuestions = [
        "establishment_director_data_test_valid",
        "establishment_director_data_passeport_copie",
        "copie_autorisation_ministre",
        "establishment_director_data_cin_copie",
        "establishment_director_data_date_delivrance",
        "establishment_director_data_ministerial_auth_date",
        "establishment_director_data_passeport",
        "establishment_director_data_cin_number",
        "establishment_director_data_spinner",
        "director_search",
      ];

      page?.questions.forEach((question) => {
        question.visible = false;

        if (isTunisian) {
          question.visible = tunisianVisibleQuestions.includes(question.name);
        } else {
          const cinDn = [
            "establishment_director_data_cin_number",
            "establishment_director_data_birth_date",
          ];
          if (type === "edit" && annexeediting === "annexe") {
            nonTunisianQuestions = nonTunisianQuestions.filter(
              (question) =>
                question !==
                  "establishment_director_data_ministerial_auth_date" &&
                question !== "copie_autorisation_ministre"
            );
          }
          question.visible = nonTunisianQuestions.includes(question.name);
          if (
            !tunisianVisibleQuestions.includes(question.name) ||
            cinDn.includes(question.name)
          ) {
            question.readOnly = false;
            question.clearValue();
          }
        }

        if (!alwaysVisibleQuestions.includes(question.name) && !isTunisian) {
          question.visible = true;
        }
      });

      if (!isTunisian) {
        survey.setValue("establishment_director_data_test_valid", true);
      } else {
        survey.clearValue("establishment_director_data_passeport");
        survey.clearValue("establishment_director_data_passeport_copie");
        survey.setValue("establishment_director_data_test_valid", false);
      }
    }

    if (
      (options.name === "establishment_director_data_cin_number" ||
        options.name === "establishment_director_data_birth_date") &&
      sender.getQuestionByName("establishment_director_data_identity").value !==
        "المستثمر نفسه" &&
      sender.getQuestionByName("establishment_director_data_nationality")
        .value === "تونسية" &&
      sessionStorage.getItem("demande") !== "true"
    ) {
      page.questions.forEach((question) => {
        const cinDn = [
          "establishment_director_data_cin_number",
          "establishment_director_data_birth_date",
          "establishment_director_data_identity",
          "establishment_director_data_nationality",
        ];
        if (!tunisianVisibleQuestions.includes(question.name)) {
          survey.setValue("establishment_director_data_test_valid", false);
          question.clearValue();
          if (!cinDn.includes(question.name)) {
            question.visible = false;
          }
        }
      });
    }

    if (
      options.name === "establishment_director_data_nationality" &&
      sender.getValue(
        "establishment_director_data_identity" !== "المستثمر نفسه"
      )
    ) {
      const questions = [
        "establishment_director_data_cin_number",
        "establishment_director_data_birth_date",
        "director_search",
      ];
      questions.forEach((question) => {
        const questionn = survey.getQuestionByName(question);
        questionn.clearValue();
        sender.getQuestionByName(question).readOnly = false;
      });
    }

    if (options.name === "training_nature_data") {
      const dynamicPanel = options.question;
      dynamicPanel.panels.forEach(async (panel) => {
        panel.getQuestions().forEach((question) => {
          if (question?.title?.toLowerCase().includes("domaine")) {
            const fieldValue = question.value;
            const regex =
              /^[\u0600-\u06FF0-9\s!@#$%^&*()\-_+={}[\]|\\:;"'<>,.?/]+$/;
            question.clearErrors();
            if (
              fieldValue &&
              !regex.test(fieldValue) &&
              !options.question.readOnly
            ) {
              question.addError(
                t("Veuillez entrer uniquement des lettres arabes.")
              );
            }
          }
        });
      });
    }
    if (options.name === "management_data") {
      const dynamicPanel = options.question;
      dynamicPanel.panels.forEach(async (panel) => {
        panel.getQuestions().forEach((question) => {
          if (question?.title?.toLowerCase().includes("arabe")) {
            const fieldValue = question.value;
            const regex = /^[\u0600-\u06FF0-9\s]+$/;
            question.clearErrors();
            if (
              fieldValue &&
              !regex.test(fieldValue) &&
              !options.question.readOnly
            ) {
              question.addError(
                t("Veuillez entrer uniquement des lettres arabes.")
              );
            }
          }

          if (question?.title?.toLowerCase().includes("français")) {
            const fieldValue = question.value;
            const regex = question?.title?.toLowerCase()?.includes("adresse")
              ? /^[A-Za-zÀ-ÿ0-9\s,.'-]+$/
              : /^[A-Za-zÀ-ÿ\s-]+$/;
            question.clearErrors();
            if (
              fieldValue &&
              !regex.test(fieldValue) &&
              !options.question.readOnly
            ) {
              question.addError(
                t("Veuillez entrer uniquement des lettres latines.")
              );
            }
          }

          if (
            question?.title?.toLowerCase().includes("arabe") &&
            question?.title?.toLowerCase().includes("adresse")
          ) {
            const fieldValue = question.value;
            const regex = /^[\u0600-\u06FF0-9\s]+$/;
            question.clearErrors();
            if (fieldValue && !regex.test(fieldValue)) {
              question.addError(
                t(
                  "Veuillez entrer uniquement des lettres arabes et des chiffres."
                )
              );
            }
          }
        });
      });
    }

    if (options.name === "space_data") {
      const dynamicPanel = options.question;
      dynamicPanel.panels.forEach(async (panel) => {
        panel.getQuestions().forEach((question) => {
          if (question?.title?.toLowerCase().includes("arabe")) {
            const fieldValue = question.value;
            const regex = /^[\u0600-\u06FF0-9\s]+$/;
            question.clearErrors();
            if (fieldValue && !regex.test(fieldValue)) {
              question.addError(
                t(
                  "Veuillez entrer uniquement des lettres arabes et des chiffres."
                )
              );
            }
          }
        });
      });
    }

    if (options.name === "inverstor_type" || options.name === "investor_RNE") {
      const legal_entity_page = survey.getPageByName("legal_entity_page");
      const establishment_page = survey.getPageByName("page2");

      legal_entity_page?.questions.map((question) => {
        if (
          question.title.toLowerCase().includes("_date") ||
          question.title.toLowerCase().includes("numéro") ||
          (question.title.toLowerCase().includes("dénomination") &&
            type !== "edit") ||
          question.title.toLowerCase().includes("rne")
        ) {
          question.clearValue();
        }
      });
      establishment_page.questions.map((question) => {
        if (
          question.title.toLowerCase().includes("_date") ||
          question.title.toLowerCase().includes("numéro") ||
          (question.title.toLowerCase().includes("dénomination") &&
            type !== "edit") ||
          question.title.toLowerCase().includes("rne")
        ) {
          question.clearValue();
        }
      });
    }

    const page_entity_establishment = sender.getPageByElement(options.question);
    if (
      page_entity_establishment?.name === "legal_entity_page" &&
      (options.name === "legal_entity_reservation_number_official" ||
        options.name === "legal_entity_tax_number" ||
        (options.name === "legal_entity_reservation_number_commercial" &&
          options.value !== ""))
    ) {
      survey.setValue("legal_entity_validate", false);
    }
    if (
      options.name === "legal_entity_reservation_number_commercial" &&
      options.value === ""
    ) {
      survey.setValue("legal_entity_validate", true);
    }

    if (options.name === "establishment_data_reservation_number_commercial") {
      if (options.value === "" && !survey.getValue("nom_commercial_rne")) {
        survey.clearValue("establishment_data_commercial_name");
        survey.clearValue(
          "establishment_data_reservation_commerciale_expiration_date"
        );
      }
    }
    if (options.name === "legal_entity_reservation_number_commercial") {
      if (options.value === "" && !survey.getValue("nom_commercial_rne")) {
        survey.clearValue("legal_entity_commercial_name");
        survey.clearValue(
          "legal_entity_reservation_commerciale_expiration_date"
        );
      }
    }
    if (options.name === "legal_entity_reservation_number_official") {
      if (options.value === "") {
        survey.clearValue("legal_entity_official_name");
        survey.clearValue(
          "legal_entity_reservation_officielle_expiration_date"
        );
      }
    }

    if (
      page_entity_establishment?.name === "page2" &&
      sender.getQuestionByName("inverstor_type").value !== "شخص معنوي" &&
      (options.name === "establishment_data_tax_number" ||
        options.name === "establishment_data_reservation_number_commercial")
    ) {
      if (options.value !== "") {
        survey.setValue("estblishment_validate", false);
      } else {
        survey.setValue("estblishment_validate", true);
      }
    }

    // const fieldName = options.name.toLowerCase();

    // if (fieldName.includes("reservation_number") || fieldName.includes("tax_number")) {
    //     const page = sender.getPageByElement(options.question);

    //     if (page && page.name === "legal_entity_page") {
    //         survey.setValue("legal_entity_validate", false);
    //     } else if (page && page.name === "establishment_page") {
    //         survey.setValue("estblishment_validate", false);
    //     }
    // } else {
    //     if (sender.getPageByName("legal_entity_page")) {
    //         survey.setValue("legal_entity_validate", true);
    //     } else {
    //         survey.setValue("estblishment_validate", true);
    //     }
    // }
  });
};

export const onValidateQuestion = (survey) => {
  survey.onValidateQuestion.add(function (sender, options) {
    const questionTitle = options?.question?.jsonObj?.title?.toLowerCase();
    const fieldValue = options.value;

    if (fieldValue) {
      if (
        (questionTitle.includes("arabe") &&
          questionTitle.includes("adresse")) ||
        questionTitle.toLowerCase().includes("libellé")
      ) {
        const arabicRegex = /^[\u0600-\u06FF0-9\s]+$/;

        if (
          fieldValue &&
          !arabicRegex.test(fieldValue) &&
          !options.question.readOnly
        ) {
          options.error = t(
            "Veuillez entrer uniquement des lettres arabes et des chiffres."
          );
        }
      } else if (questionTitle.includes("domaine")) {
        const arabicRegex =
          /^[\u0600-\u06FF0-9\s!@#$%^&*()\-_+={}[\]|\\:;"'<>,.?/]+$/;
        if (fieldValue && !arabicRegex.test(fieldValue)) {
          options.error = t("Veuillez entrer uniquement des lettres arabes.");
        }
      } else if (questionTitle.includes("arabe")) {
        const arabicRegex = /^[\u0600-\u06FF0-9\s]+$/;
        if (fieldValue && !arabicRegex.test(fieldValue)) {
          options.error = t("Veuillez entrer uniquement des lettres arabes.");
        }
      } else if (fieldValue && questionTitle.includes("français")) {
        const frenchRegex = questionTitle?.toLowerCase()?.includes("adresse")
          ? /^[A-Za-zÀ-ÿ0-9\s,.'-]+$/
          : /^[A-Za-zÀ-ÿ\s-]+$/;
        if (
          fieldValue &&
          !frenchRegex.test(fieldValue) &&
          !options.question.readOnly
        ) {
          options.error = t("Veuillez entrer uniquement des lettres latines.");
        }
      } else if (
        questionTitle.includes("Numéro de la CIN") ||
        questionTitle.includes("téléphone") ||
        questionTitle.includes("fax") ||
        (questionTitle.includes("cnss") &&
          !questionTitle.includes("affiliation"))
      ) {
        const regex = /^\d{8}$/;
        if (fieldValue && !regex.test(fieldValue)) {
          options.error = t("Veuillez entrer exactement 8 chiffres.");
        }
      }
    }
  });
};

export const onErrorCustomText = (survey) => {
  survey.onErrorCustomText.add(function (sender, options) {
    if (options.name == "required") {
      options.text = t("Veuillez remplir ce champ.");
    }
    if (options.name === "custom") {
      if (options.obj.jsonObj?.name?.includes("_date")) {
        options.text = t(
          "La date sélectionnée ne peut pas être dans le futur. Veuillez choisir une date valide."
        );
      }
      if (
        options.error.text ===
        "Veuillez entrer uniquement des lettres arabes et des chiffres."
      ) {
        options.text = t(
          "Veuillez entrer uniquement des lettres arabes et des chiffres."
        );
      }

      if (
        options.error.text === "Veuillez entrer uniquement des lettres arabes."
      ) {
        options.text = t("Veuillez entrer uniquement des lettres arabes.");
      }
      if (options.error.text?.includes("lettres latines")) {
        options.text = t("Veuillez entrer uniquement des lettres latines.");
      }
      if (options.error.text?.includes("numéro RNE")) {
        options.text = t(
          "Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 1234567A)."
        );
      }
      if (options.error.text?.includes("less than 0")) {
        options.text = t("La valeur ne doit pas être inférieure à 0.");
      }
      if (options.error.text?.includes("less than 1")) {
        options.text = t("La valeur ne doit pas être inférieure à 1.");
      }
      if (options.error.text?.includes("valid e-mail address")) {
        options.text = t("Merci d'entrer une adresse mail valide.");
      }
    }

    if (options.name === "exceedsize") {
      options.text = t("La taille du fichier ne doit pas dépasser 2MB.");
    }

    if (options?.obj?.jsonObj?.name === "copie_autorisation_ministre") {
      notification.info({
        message: t(
          "Vous devez obtenir une autorisation du Ministre chargé de la formation professionnelle pour assurer la direction de l’établissement privée de formation professionnelle."
        ),
        duration: 0,
      });
    }
    if (options.name == "otherempty") {
      if (options.error.text?.includes("Merci de préciser le champ 'Autre'.")) {
        options.text = t("Veuillez remplir ce champ.");
      }
    }
  });
};

export const onCurrentPageChanging = (survey, consult, nom_commercial) => {
  survey.onCurrentPageChanging.add(async function (sender, options) {
    let allowChanging = true;
    let errorDisplayed = false;

    const investorRNEValue = sender.data["investor_RNE"];
    if (sender.currentPage.name === "page4") {
      const establishment_data_training_type =
        sender.data["establishment_data_training_type"];
      const training_nature_data = sender.getQuestionByName(
        "training_nature_data"
      ).value;
      if (establishment_data_training_type === "أساسي ومستمر") {
        const passConditionValues = [];
        training_nature_data.forEach((element) => {
          if (
            !passConditionValues?.includes(element.training_type_data_nature)
          ) {
            passConditionValues.push(element.training_type_data_nature);
          }
          if (
            element.training_type_data_nature === "أساسي" &&
            element.training_type_data_type === "منظرة"
          ) {
            const duration_minimum = parseInt(
              element.training_type_data_duration_minimum
            );
            const duration_hour = parseInt(
              element.training_type_data_duration_hour
            );

            if (
              duration_minimum &&
              duration_hour &&
              duration_minimum > duration_hour
            ) {
              errorDisplayed = true;
            }
          }
        });

        if (options.isNextPage && errorDisplayed) {
          notification.error({
            message: t("Erreur"),
            description: t(
              `La durée minimale en heures doit être inférieure ou égale à la durée totale en heures.`
            ),
            duration: 0,
          });
          allowChanging = false;
        }

        if (
          !(
            passConditionValues?.includes("أساسي") &&
            passConditionValues?.includes("مستمر")
          )
        ) {
          if (options.isNextPage) {
            notification.error({
              message: t("Erreur"),
              description: t(
                "Veuillez sélectionner au moins 2 types de formation (Initiale et Continue)."
              ),
              duration: 0,
            });
            allowChanging = false;
          }
        }
      }

      if (establishment_data_training_type.value === "أساسي") {
        training_nature_data.forEach((element) => {
          if (
            element.training_type_data_nature === "أساسي" &&
            element.training_type_data_type === "منظرة"
          ) {
            const duration_minimum = parseInt(
              element.training_type_data_duration_minimum
            );
            const duration_hour = parseInt(
              element.training_type_data_duration_hour
            );
            if (
              duration_minimum &&
              duration_hour &&
              duration_minimum > duration_hour
            ) {
              errorDisplayed = true;
            }
          }
        });
        if (options.isNextPage && errorDisplayed) {
          notification.error({
            message: t("Erreur"),
            description: t(
              `La durée minimale en heures doit être inférieure ou égale à la durée totale en heures.`
            ),
            duration: 0,
          });
          allowChanging = false;
        }
      }
    }
    if (sender.currentPage.name === "Données de Directeur de l’Établissement") {
      let panels_valid = true;
      let dates = true;

      const exp_years = sender.getQuestionByName(
        "establishment_director_data_experience_years"
      ).value;
      const birthDate = sender.getQuestionByName(
        "establishment_director_data_birth_date"
      ).value;
      const today = new Date();
      const birth = new Date(birthDate);
      const age = today.getFullYear() - birth.getFullYear();
      if (exp_years > age && options.isNextPage) {
        dates = false;
      } else {
        dates = true;
      }
     
      if (
        sender.getQuestionByName("establishment_director_data_test_valid")
          .value === false &&
        options.isNextPage
      ) {
        panels_valid = false;
      }

      if (!panels_valid) {
        notification.error({
          message: t("Erreur"),
          description: t("Veuillez entrer des données valides."),
          duration: 0,
        });
        allowChanging = false;
      } else if (!dates) {
        notification.error({
          message: t("Erreur"),
          description: t("Nombre d'années d'expérience supérieure à votre âge"),
          duration: 0,
        });
        allowChanging = false;
      } else if (
        !survey.data.mail_directeur_existe &&
        survey.data.mail_directeur_existe !== false && options.isNextPage
      ) {
        notification.error({
          message: t("Erreur"),
          description: t(
            "Veuillez valider l'email du directeur en cliquant sur l'icône de validation avant de poursuivre."
          ),
          duration: 0,
        });
        allowChanging = false;
      } else if (survey.data.mail_directeur_existe && options.isNextPage) {
        notification.error({
          message: t("Erreur"),
          description: t(
            consult !== "annexe"
              ? "Le directeur sélectionné possède déjà un compte avec un autre rôle sur la plateforme SIGAF. Veuillez fournir une autre adresse e-mail pour ce directeur afin de pouvoir lui créer ses accès une fois la demande validée."
              : "Le responsable sélectionné possède déjà un compte avec un autre rôle sur la plateforme SIGAF. Veuillez fournir une autre adresse e-mail pour ce responsable."
          ),
          duration: 0,
        });
        allowChanging = false;
      }
    }

    if (sender.currentPage.name === "page8") {
      let dates = true;
      const panels_management_data =
        sender.getQuestionByName("management_data");

      panels_management_data.panels.forEach((panel) => {
        const exp_years = panel.getQuestionByName(
          "management_data_staff_experience_years"
        ).value;
        const birthDate = panel.getQuestionByName(
          "management_data_staff_birth_date"
        ).value;
        const today = new Date();
        const birth = new Date(birthDate);
        const age = today.getFullYear() - birth.getFullYear();
        if (exp_years > age && options.isNextPage) {
          dates = false;
        } else {
          dates = true;
        }
      });
      if (!dates) {
        notification.error({
          message: t("Erreur"),
          description: t("Nombre d'années d'expérience supérieure à votre âge"),
          duration: 0,
        });
        allowChanging = false;
      }
    }

    if (sender.currentPage.name === "page0") {
      const primaryActivity = survey.getQuestionByName(
        "legal_entity_sector_activity"
      );
      const secondaryActivity = survey.getQuestionByName(
        "legal_entity_secondary_sector_activity"
      );

      if (sessionStorage.getItem("consulter") !== "true") {
        if (
          survey.getValue("investor_RNE") ===
          "الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)"
        ) {
          if (primaryActivity) primaryActivity.readOnly = false;
          if (secondaryActivity) secondaryActivity.readOnly = false;
        }
      }
    }

    const requiredReadOnlyFields = sender.currentPage.questions.filter(
      (q) =>
        q.isRequired &&
        q.isReadOnly &&
        q.visible &&
        q.name !== "establishment_data_commercial_name"
    );
    const emptyFields = requiredReadOnlyFields.filter(
      (q) => !sender.data[q.name]
    );
    const validQuestion = sender.currentPage.questions.filter((q) =>
      q.name.includes("_valid")
    );

    const valider_infos_complementaires_epf_existant =
      sender.currentPage.name === "page9" && consult === "existant";
    if (
      (emptyFields.length > 0 ||
        (validQuestion.length > 0 && !validQuestion[0]?.value)) &&
      !valider_infos_complementaires_epf_existant &&
      options.isNextPage &&
      sender.currentPage.name !== "all" &&
      (!consult || consult === "annexe")
    ) {
      allowChanging = false;
      notification.error({
        message: t("Erreur"),
        description: t(
          "Vous avez modifié des données sans les valider via l'icône de recherche."
        ),
        duration: 0,
      });
    } else if (
      sender.currentPage.name === "page2" ||
      sender.currentPage.name === "legal_entity_page"
    ) {
      let matriculeFiscaleGoverment =
        sessionStorage?.getItem("MatriculeFiscaleGoverment") &&
        sessionStorage?.getItem("MatriculeFiscaleGoverment") != "undefined"
          ? JSON?.parse(sessionStorage?.getItem("MatriculeFiscaleGoverment"))
          : null;
      if (
        options.isNextPage &&
        matriculeFiscaleGoverment?.data &&
        Object.keys(matriculeFiscaleGoverment?.data)?.length > 0 &&
        investorRNEValue ===
          "رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)" &&
        consult !== "annexe" &&
        consult !== "modification"
      ) {
        notification.error({
          message: t("Erreur"),
          description: t(
            "Un enregistrement existant avec ce couple RNE / Gouvernorat existe déjà. Il s'agit de la création d'une annexe, et non d'un nouvel EPF."
          ),
          duration: 0,
        });
        allowChanging = false;
      } else if (
        options.isNextPage &&
        sender.currentPage.name === "page2" &&
        !survey.getValue("establishment_data_commercial_name") &&
        sender.getQuestionByName("inverstor_type").value === "شخص طبيعي" &&
        nom_commercial
      ) {
        notification.error({
          message: t("Erreur"),
          description: t(
            "Vous ne disposez pas d’un nom commercial. Pour continuer l’inscription vous êtes invité à prendre attache au RNE pour avoir un nom commercial."
          ),
          duration: 0,
        });
        allowChanging = false;
      }
    }

    const panels = sender.currentPage.questions.filter(
      (q) => q.getType() === "paneldynamic"
    );
    let panel_active = true;
    let recherche_valid = true;

    if (panels[0]) {
      panels[0].panels.map((panel) => {
        const validQuestion = panel.questions.filter((q) =>
          q.name.includes("_valid")
        );

        const activeQuestion = panel.questions.filter((q) =>
          q.name.includes("_active")
        );

        if (sender.currentPage.name === "page5") {
          const trainerPanel = sender.getQuestionByName("trainer_data");
          trainerPanel.panels.forEach((panel) => {
            const active = panel.getQuestionByName("trainer_active");

            if (active && active.value === false && options.isNextPage) {
              panel_active = false;
            } else if (
              validQuestion.length > 0 &&
              !validQuestion[0]?.value &&
              options.isNextPage &&
              !activeQuestion[0]?.value
            ) {
              recherche_valid = false;
            }
          });
        }
      });
      if (!panel_active) {
        notification.error({
          message: t("Erreur"),
          description: t("Le compte du formateur n'est pas activé."),
          duration: 0,
        });
        allowChanging = false;
      }

      if (!recherche_valid && panel_active) {
        notification.error({
          message: t("Erreur"),
          description: t(
            "Vous avez modifié des données sans les valider via l'icône de recherche."
          ),
          duration: 0,
        });
        allowChanging = false;
      }
    }

    options.allowChanging = allowChanging;
  });
};
export const registerFunctionOnPropertyValueChanged = (
  survey,
  setShowingPreview
) => {
  survey.registerFunctionOnPropertyValueChanged("isShowingPreview", () => {
    if (
      !survey.isShowingPreview &&
      (sessionStorage.getItem("consulter") === "false" ||
        !sessionStorage.getItem("consulter"))
    ) {
      // sessionStorage.setItem("showingPreview", false);
      setShowingPreview(false);
    }
  });
};

export const onShowingPreview = (survey, setShowingPreview) => {
  survey.onShowingPreview.add(async function (sender, options) {
    setShowingPreview(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (sender.currentPage.name === "page8") {
      let panel_valid = true;
      const managementPanel = sender.getQuestionByName("management_data");

      managementPanel.panels.forEach((panel) => {
        const validQuestion = panel.getQuestionByName(
          "management_data_staff_valid"
        );
        if (validQuestion && validQuestion.value === false) {
          panel_valid = false;
        }
      });

      if (!panel_valid) {
        notification.error({
          message: t("Erreur"),
          description: t(
            "Veuillez entrer des données valides pour le personnel d'encadrement et de gestion."
          ),
          duration: 0,
        });
        options.allowShowPreview = false;
      }
    }
    if (sender.currentPage.name === "page4") {
      const establishment_data_training_type = sender.getQuestionByName(
        "establishment_data_training_type"
      );

      if (establishment_data_training_type.value === "أساسي ومستمر") {
        const passConditionValues = [];
        const training_nature_data = sender.getQuestionByName(
          "training_nature_data"
        ).value;

        training_nature_data.forEach((element) => {
          if (
            !passConditionValues?.includes(element.training_type_data_nature)
          ) {
            passConditionValues.push(element.training_type_data_nature);
          }
        });

        if (
          !(
            passConditionValues?.includes("أساسي") &&
            passConditionValues?.includes("مستمر")
          )
        ) {
          options.allowShowPreview = false;
          notification.error({
            message: t("Erreur"),
            description: t(
              "Veuillez sélectionner au moins 2 types de formation (Initiale et Continue)."
            ),
            duration: 0,
          });
        }
      }
    }

    if (sender.currentPage.name === "Données de Directeur de l’Établissement") {
      const validQuestion = sender.currentPage.questions.filter((q) =>
        q.name.includes("_valid")
      );
      if (validQuestion.length > 0 && !validQuestion[0]?.value) {
        options.allowShowPreview = false;
        notification.error({
          message: t("Erreur"),
          description: t(
            "Le directeur ajouté est fonctionnel dans un autre EPF."
          ),
          duration: 0,
        });
      }
    }

    sender.getAllQuestions().forEach((question) => {
      if (
        (question.name.toLowerCase().includes("_date") ||
          question.name.toLowerCase().includes("_search") ||
          question.inputType === "api") &&
        sessionStorage.getItem("shownigPreview") === "true"
      ) {
        question.readOnly = true;
      }

      // const signatureQuestion = sender.getQuestionByName("signature_question");
      // if (signatureQuestion) {
      //   signatureQuestion.visible = true;
      //   signatureQuestion.readOnly = false;
      //   signatureQuestion.required = true;
      // }
    });
  });
};
