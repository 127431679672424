import { Button, Checkbox, Modal, Space, notification } from "antd";
import React, {  useState } from "react";
import { useLoader } from "../../../../context/LoaderContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../utils/axios";
import PropTypes from "prop-types";

const ModalModification = (props) => {
  const { t } = useTranslation();

  const { modal, setModal, role, type } = props; 
  const { setIsLoading, setSurveyJson } = useLoader();
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleValidate = async () => {
    if (!selectedOption) {
      notification.error({
        message: t("Erreur"),
        description: t("Veuillez choisir une option !"),
        duration: 0,
      });
      return;
    }

    setIsLoading(true);

    const existing_brouillon_selected_option =
      selectedOption === "avec_bo_validation"
        ? import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
        : selectedOption === "sans_bo_sans_validation"
        ? import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
        : import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO;
    const existe = await axiosInstance
      .get(
        import.meta.env.VITE_APP_GEOPROD_API +
          `/check_exist_demande_modification?id_affaire=${modal}&type_demande=${existing_brouillon_selected_option}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .catch(() => {
        setIsLoading(false);
      });

    if (!existe.data.existe) {
      notification.destroy();

      const ligne_produit =
        selectedOption === "avec_bo_validation"
          ? import.meta.env
              .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
          : selectedOption === "sans_bo_sans_validation"
          ? import.meta.env
              .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
          : import.meta.env
              .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO;

      sessionStorage.removeItem(`id_affaire_edit_${selectedOption}`);

      axiosInstance
        .get(
          `${
            import.meta.env.VITE_APP_GEOPROD_API
          }/admin-bpm/contrat/${ligne_produit}`,
          {
            headers: {
              Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                ?.Acces_Token,
            },
          }
        )
        .then((res) => {
          setIsLoading(false);

          if (selectedOption === "avec_bo_validation") {
            let form = res?.data?.contrat?.form;
            form.pages = res?.data?.contrat?.form.pages.filter(
              (page) => page.name !== "ChnangementInvestisseur"
            );

            if (type === "annexe") {
              form.pages = form.pages.filter(
                (page) => page.name !== "page0" && page.name !== "page4"
              );
            }
            setSurveyJson(form);
          } else if (selectedOption === "sans_bo_avec_validation") {
            let form = res?.data?.contrat?.form;

            if (type === "annexe") {
              form.pages = res?.data?.contrat?.form.pages.filter(
                (page) => page.name !== "page4"
              );
            }

            if (role === "investisseur") {
              form.pages = res?.data?.contrat?.form.pages.filter(
                (page) =>
                  page.name === "Données de Directeur de l’Établissement"
              );
              setSurveyJson(form);
            } else if (role === "directeur") {
              form.pages = res?.data?.contrat?.form.pages.filter(
                (page) =>
                  page.name !== "Données de Directeur de l’Établissement"
              );
              setSurveyJson(form);
            } else {
              setSurveyJson(res?.data?.contrat?.form);
            }
          } else if (
            selectedOption === "sans_bo_sans_validation" &&
            (role === "directeur" || role === "investisseur-directeur")
          ) {
            let form = res?.data?.contrat?.form;
            form.pages = res?.data?.contrat?.form.pages.filter(
              (page) =>
                page.name === "page2" ||
                (type !== "annexe" && page.name === "page8")
            );
            setSurveyJson(form);
          } else {
            setSurveyJson(res?.data?.contrat?.form);
          }

          sessionStorage.setItem("selectedOption", selectedOption);
          navigate(`/etablissements/edit`, {
            state: {
              id_affaire: modal,
              selectedOption: selectedOption,
              ligne_produit: ligne_produit,
              type: type,
            },
          });
          setModal("");
        });
    } else {
      setIsLoading(false);

      notification.info({
        message: t("info"),
        description: t(
          "Une demande de modification pour cet EPF ou l'une de ses annexes est déjà en cours de traitement, ou un brouillon existe déjà pour ce type de demande."
        ),
        duration: 0,
      });
    }
  };

  const handleCancel = () => {
    setModal("");
    setSelectedOption(null);
  };

  const getCardContent = (option) => {
    if (type === "annexe") {
      if (option === "avec_bo_validation") {
        return t(
          "Ce type de modification vous permet de mettre à jour les informations essentielles de votre annexe, notamment son adresse."
        );
      }

      if (option === "sans_bo_avec_validation") {
        return t(
          "En sélectionnant ce type de modification, vous pourrez mettre à jour les informations du responsable de l’annexe. Vous pourrez également ajuster les informations des espaces et des équipements de formation, en modifiant leurs détails ou en ajoutant et supprimant des éléments."
        );
      }

      if (option === "sans_bo_sans_validation") {
        return t(
          "En choisissant ce type de modification, vous pourrez mettre à jour les informations de contact et d’identification de votre annexe, notamment son numéro de téléphone, et son adresse e-mail."
        );
      }

      if (option === "sans_bo_avec_validation_dir") {
        return t(
          "Ce type de modification vous permet de modifier, ajouter ou supprimer des espaces et des équipements de formation."
        );
      }

      if (option === "sans_bo_sans_validation_dir") {
        return t(
          "En sélectionnant ce type de modification, vous pourrez mettre à jour les informations de contact et d’identification de votre annexe, notamment son numéro de téléphone et son adresse e-mail."
        );
      }

      if (option === "sans_bo_avec_validation_investisseur") {
        return t(
          "En choisissant ce type de modification, vous pourrez mettre à jour les informations du responsable de l’annexe, notamment son identité et ses coordonnées."
        );
      }
    } else {
      if (option === "avec_bo_validation") {
        return t(
          "Ce type de modification vous permet de mettre à jour les informations essentielles de votre établissement, notamment son type, son adresse, sa délégation et son code postal. Vous pourrez également modifier la nature de la formation proposée, ajouter ou supprimer des formations et ajuster leurs durées et modes d’apprentissage."
        );
      }

      if (option === "sans_bo_avec_validation") {
        return t(
          "En sélectionnant ce type de modification, vous pourrez mettre à jour les informations du directeur de l’établissement, gérer l’offre de formation en ajoutant, supprimant ou modifiant des formations, et actualiser les données des formateurs, y compris leur situation et leur type de contrat. Vous pourrez également ajuster les informations des espaces et des équipements de formation, en modifiant leurs détails ou en ajoutant et supprimant des éléments selon les besoins de votre établissement."
        );
      }

      if (option === "sans_bo_sans_validation") {
        return t(
          "En choisissant ce type de modification, vous pourrez mettre à jour les informations de contact et d’identification de votre établissement, notamment son numéro de téléphone, son adresse e-mail et son numéro de matricule CNSS. Vous pourrez également gérer le personnel d’encadrement et de gestion en modifiant leurs informations, en ajoutant de nouveaux membres ou en supprimant des personnels existants."
        );
      }

      if (option === "sans_bo_avec_validation_dir") {
        return t(
          "Ce type de modification vous permet de gérer l’offre de formation de votre établissement en ajoutant, supprimant ou modifiant des formations. Vous pourrez également mettre à jour les informations des formateurs, ajuster leur situation ou leur type de contrat, ainsi qu’ajouter ou supprimer des formateurs. De plus, vous pourrez modifier, ajouter ou supprimer des espaces et des équipements de formation."
        );
      }

      if (option === "sans_bo_sans_validation_dir") {
        return t(
          "En sélectionnant ce type de modification, vous pourrez mettre à jour les informations de contact et d’identification de votre établissement, notamment son numéro de téléphone, son adresse e-mail et son numéro de matricule CNSS. Vous pourrez également gérer le personnel d’encadrement et de gestion en modifiant leurs informations, en ajoutant de nouveaux membres ou en supprimant des personnels existants."
        );
      }

      if (option === "sans_bo_avec_validation_investisseur") {
        return t(
          "En choisissant ce type de modification, vous pourrez mettre à jour les informations du directeur de l’établissement, notamment son identité et ses coordonnées."
        );
      }
    }

    return null;
  };

  const renderCheckboxes = () => {
    if (role === "investisseur") {
      return (
        <>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "start",
              border:
                selectedOption === "avec_bo_validation"
                  ? "1px solid #024FA8"
                  : "1px solid #d9d9d9",
              borderRadius: "8px",
              padding: "10px",
              marginTop: "10px",
              backgroundColor:
                selectedOption === "avec_bo_validation" ? "#F0F5FF" : "#FFFFFF",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <Checkbox
              checked={selectedOption === "avec_bo_validation"}
              onChange={() => handleOptionChange("avec_bo_validation")}
              style={{
                fontSize: "16px",
                marginBottom: "10px",
                color: "#333",
              }}
            />
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  paddingTop: 2,
                  paddingBottom: 5,
                }}
              >
                {t(
                  "1- Modification nécessitant la validation de la direction régionale et entraînant une mise à jour du bordereau (BO)"
                )}
              </div>
              <p style={{ margin: 0, color: "#555", fontSize: "12px" }}>
                {getCardContent("avec_bo_validation")}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "start",
              border:
                selectedOption === "sans_bo_avec_validation"
                  ? "1px solid #024FA8"
                  : "1px solid #d9d9d9",
              borderRadius: "8px",
              padding: "10px",
              marginTop: "10px",
              backgroundColor:
                selectedOption === "sans_bo_avec_validation"
                  ? "#F0F5FF"
                  : "#FFFFFF",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <Checkbox
              checked={selectedOption === "sans_bo_avec_validation"}
              onChange={() => handleOptionChange("sans_bo_avec_validation")}
              style={{
                fontSize: "16px",
                marginBottom: "10px",
                color: "#333",
              }}
            />
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  paddingTop: 2,
                  paddingBottom: 5,
                }}
              >
                {t(
                  "2- Modification nécessitant la validation de la direction régionale mais sans impact sur le bordereau (BO)"
                )}
              </div>
              <p style={{ margin: 0, color: "#555", fontSize: "12px" }}>
                {getCardContent("sans_bo_avec_validation_investisseur")}
              </p>
            </div>
          </div>
        </>
      );
    }

    if (role === "directeur") {
      return (
        <>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "start",
              border:
                selectedOption === "sans_bo_avec_validation"
                  ? "1px solid #024FA8"
                  : "1px solid #d9d9d9",
              borderRadius: "8px",
              padding: "10px",
              marginTop: "10px",
              backgroundColor:
                selectedOption === "sans_bo_avec_validation"
                  ? "#F0F5FF"
                  : "#FFFFFF",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <Checkbox
              checked={selectedOption === "sans_bo_avec_validation"}
              onChange={() => handleOptionChange("sans_bo_avec_validation")}
              style={{
                fontSize: "16px",
                marginBottom: "10px",
                color: "#333",
              }}
            />
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  paddingTop: 2,
                  paddingBottom: 5,
                }}
              >
                {t(
                  "1- Modification nécessitant la validation de la direction régionale mais sans impact sur le bordereau (BO)"
                )}
              </div>
              <p style={{ margin: 0, color: "#555", fontSize: "12px" }}>
                {getCardContent("sans_bo_avec_validation_dir")}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "start",
              border:
                selectedOption === "sans_bo_sans_validation"
                  ? "1px solid #024FA8"
                  : "1px solid #d9d9d9",
              borderRadius: "8px",
              padding: "10px",
              marginTop: "10px",
              backgroundColor:
                selectedOption === "sans_bo_sans_validation"
                  ? "#F0F5FF"
                  : "#FFFFFF",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <Checkbox
              checked={selectedOption === "sans_bo_sans_validation"}
              onChange={() => handleOptionChange("sans_bo_sans_validation")}
              style={{
                fontSize: "16px",
                marginBottom: "10px",
                color: "#333",
              }}
            />
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  paddingTop: 2,
                  paddingBottom: 5,
                }}
              >
                {t(
                  "2- Modification ne nécessitant pas la validation de la direction régionale et n’impactant pas le bordereau (BO)"
                )}
              </div>
              <p style={{ margin: 0, color: "#555", fontSize: "12px" }}>
                {getCardContent("sans_bo_sans_validation_dir")}
              </p>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "start",
            border:
              selectedOption === "avec_bo_validation"
                ? "1px solid #024FA8"
                : "1px solid #d9d9d9",
            borderRadius: "8px",
            padding: "10px",
            marginTop: "10px",
            backgroundColor:
              selectedOption === "avec_bo_validation" ? "#F0F5FF" : "#FFFFFF",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        >
          <Checkbox
            checked={selectedOption === "avec_bo_validation"}
            onChange={() => handleOptionChange("avec_bo_validation")}
            style={{
              fontSize: "16px",
              marginBottom: "10px",
              color: "#333",
            }}
          />
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                paddingTop: 2,
                paddingBottom: 5,
              }}
            >
              {t(
                "1- Modification nécessitant la validation de la direction régionale et entraînant une mise à jour du bordereau (BO)"
              )}
            </div>
            <p style={{ margin: 0, color: "#555", fontSize: "12px" }}>
              {getCardContent("avec_bo_validation")}
            </p>
          </div>
        </div>

        {/* Second */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "start",
            border:
              selectedOption === "sans_bo_avec_validation"
                ? "1px solid #024FA8"
                : "1px solid #d9d9d9",
            borderRadius: "8px",
            padding: "10px",
            marginTop: "10px",
            backgroundColor:
              selectedOption === "sans_bo_avec_validation"
                ? "#F0F5FF"
                : "#FFFFFF",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        >
          <Checkbox
            checked={selectedOption === "sans_bo_avec_validation"}
            onChange={() => handleOptionChange("sans_bo_avec_validation")}
            style={{
              fontSize: "16px",
              marginBottom: "10px",
              color: "#333",
            }}
          />
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                paddingTop: 2,
                paddingBottom: 5,
              }}
            >
              {t(
                "2- Modification nécessitant la validation de la direction régionale mais sans impact sur le bordereau (BO)"
              )}
            </div>
            <p style={{ margin: 0, color: "#555", fontSize: "12px" }}>
              {getCardContent("sans_bo_avec_validation")}
            </p>
          </div>
        </div>

        {/* Third */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "start",
            border:
              selectedOption === "sans_bo_sans_validation"
                ? "1px solid #024FA8"
                : "1px solid #d9d9d9",
            borderRadius: "8px",
            padding: "10px",
            marginTop: "10px",
            backgroundColor:
              selectedOption === "sans_bo_sans_validation"
                ? "#F0F5FF"
                : "#FFFFFF",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        >
          <Checkbox
            checked={selectedOption === "sans_bo_sans_validation"}
            onChange={() => handleOptionChange("sans_bo_sans_validation")}
            style={{
              fontSize: "16px",
              marginBottom: "10px",
              color: "#333",
            }}
          />
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                paddingTop: 2,
                paddingBottom: 5,
              }}
            >
              {t(
                "3- Modification ne nécessitant pas la validation de la direction régionale et n’impactant pas le bordereau (BO)"
              )}
            </div>
            <p style={{ margin: 0, color: "#555", fontSize: "12px" }}>
              {getCardContent("sans_bo_sans_validation")}
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      title={
        <div
          style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}
        >
          {t("Choix du type de modification")}
        </div>
      }
      open={!!modal}
      onCancel={handleCancel}
      width={800}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          {t("Annuler")}
        </Button>,
        <Button key="validate" type="primary" onClick={handleValidate}>
          {t("Valider")}
        </Button>,
      ]}
      centered
    >
      <p>
        {t(
          type === "annexe"
            ? "Merci de choisir le type de modification que vous souhaitez apporter à votre Annexe."
            : "Merci de choisir le type de modification que vous souhaitez apporter à votre EPF."
        )}
        <br />
        <strong>
          {t(
            type === "annexe"
              ? "Veuillez noter qu'une seule demande de modification peut être soumise à la fois pour une même Annexe."
              : "Veuillez noter qu'une seule demande de modification peut être soumise à la fois pour un même EPF."
          )}
        </strong>
      </p>
      <Space direction="vertical">{renderCheckboxes()}</Space>
    </Modal>
  );
};


ModalModification.propTypes = {
  modal: PropTypes.string,
  setModal: PropTypes.func,
  role: PropTypes.string,
  type:PropTypes.string
};

export default ModalModification;
