import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ar: {
    translation: {
      "Choix du type de modification": "اختيار نوع التعديل",
      "Ce type de modification vous permet de mettre à jour les informations essentielles de votre annexe, notamment son adresse.":
        "يتيح لك هذا النوع من التعديل تحديث المعلومات الأساسية لفرعك ، بما في ذلك عنوانها.",
      "En sélectionnant ce type de modification, vous pourrez mettre à jour les informations du responsable de l’annexe. Vous pourrez également ajuster les informations des espaces et des équipements de formation, en modifiant leurs détails ou en ajoutant et supprimant des éléments.":
        "من خلال اختيار هذا النوع من التعديل، يمكنك تحديث معلومات  مسؤول الفرع. كما يمكنك ضبط معلومات الفضاءات والمعدات التكوينية، سواء من خلال تعديل تفاصيلها أو إضافة وحذف عناصر.",
      "En choisissant ce type de modification, vous pourrez mettre à jour les informations de contact et d’identification de votre annexe, notamment son numéro de téléphone, et son adresse e-mail.":
        "عند اختيار هذا النوع من التعديل، يمكنك تحديث معلومات الاتصال والتعريف الخاصة بفرعك، بما في ذلك رقم الهاتف و البريد الإلكتروني.",
      "En choisissant ce type de modification, vous pourrez mettre à jour les informations du responsable de l’annexe, notamment son identité et ses coordonnées.":
        "من خلال اختيار هذا النوع من التعديل، يمكنك تحديث معلومات مسؤول الفرع، بما في ذلك هويته ومعلومات الاتصال به.",
      "Ce type de modification vous permet de modifier, ajouter ou supprimer des espaces et des équipements de formation.":
        "يتيح لك هذا النوع من التعديل تحديث الفضاءات والمعدات التكوينية في فرعك، من خلال تعديلها، إضافتها أو حذفها.",
      "En sélectionnant ce type de modification, vous pourrez mettre à jour les informations de contact et d’identification de votre annexe, notamment son numéro de téléphone et son adresse e-mail.":
        "عند اختيار هذا النوع من التعديل، يمكنك تحديث معلومات الاتصال والتعريف الخاصة بفرعك، بما في ذلك رقم الهاتف و البريد الإلكتروني.",
      "Merci de choisir le type de modification que vous souhaitez apporter à votre EPF.":
        "يرجى اختيار نوع التعديل الذي ترغب في إجرائه على مؤسستك الخاصة بالتكوين المهني.",
      "Merci de choisir le type de modification que vous souhaitez apporter à votre Annexe.":
        "يرجى اختيار نوع التعديل الذي ترغب في إجرائه على فرعك.",
      "Veuillez noter qu'une seule demande de modification peut être soumise à la fois pour une même Annexe.":
        "يرجى العلم بأنه لا يمكن تقديم أكثر من طلب تعديل يتطلب موافقة الإدارة في نفس الوقت لنفس الفرع.",
      "Veuillez noter qu'une seule demande de modification peut être soumise à la fois pour un même EPF.":
        "يرجى العلم بأنه لا يمكن تقديم أكثر من طلب تعديل يتطلب موافقة الإدارة في نفس الوقت لنفس المؤسسة.",
      "1- Modification nécessitant la validation de la direction régionale et entraînant une mise à jour du bordereau (BO)":
        "1. تعديل يتطلب مصادقة الإدارة الجهوية ويتضمن تحديث وصل الإيداع",
      "Ce type de modification vous permet de mettre à jour les informations essentielles de votre établissement, notamment son type, son adresse, sa délégation et son code postal. Vous pourrez également modifier la nature de la formation proposée, ajouter ou supprimer des formations et ajuster leurs durées et modes d’apprentissage.":
        "يتيح لك هذا النوع من التعديل تحديث المعلومات الأساسية لمؤسستك، بما في ذلك نوعها، عنوانها، معتمدية تواجدها، ورمزها البريدي. يمكنك أيضًا تعديل طبيعة التكوين المقترح، إضافة أو حذف التكوينات، وضبط مددها وأنماط تدريسها.",
      "2- Modification nécessitant la validation de la direction régionale mais sans impact sur le bordereau (BO)":
        "2. تعديل يتطلب مصادقة الإدارة الجهوية ولكنه لا يؤثر على وصل الإيداع",
      "1- Modification nécessitant la validation de la direction régionale mais sans impact sur le bordereau (BO)":
        "1. تعديل يتطلب مصادقة الإدارة الجهوية ولكنه لا يؤثر على وصل الإيداع",
      "En sélectionnant ce type de modification, vous pourrez mettre à jour les informations du directeur de l’établissement, gérer l’offre de formation en ajoutant, supprimant ou modifiant des formations, et actualiser les données des formateurs, y compris leur situation et leur type de contrat. Vous pourrez également ajuster les informations des espaces et des équipements de formation, en modifiant leurs détails ou en ajoutant et supprimant des éléments selon les besoins de votre établissement.":
        "من خلال اختيار هذا النوع من التعديل، يمكنك تحديث معلومات مدير المؤسسة، تحيين أنواع التكوين والاختصاصات في مؤسستك عبر إضافة، حذف أو تعديل التكوينات، وتحديث بيانات المكونين بما في ذلك وضعيتهم ونوع عقودهم. كما يمكنك ضبط معلومات الفضاءات والمعدات التكوينية، سواء من خلال تعديل تفاصيلها أو إضافة وحذف عناصر حسب احتياجات مؤسستك.",
      "3- Modification ne nécessitant pas la validation de la direction régionale et n’impactant pas le bordereau (BO)":
        "3. تعديل لا يتطلب مصادقة الإدارة الجهوية ولا يؤثر على وصل الإيداع",
      "2- Modification ne nécessitant pas la validation de la direction régionale et n’impactant pas le bordereau (BO)":
        "2. تعديل لا يتطلب مصادقة الإدارة الجهوية ولا يؤثر على وصل الإيداع",
      "En choisissant ce type de modification, vous pourrez mettre à jour les informations de contact et d’identification de votre établissement, notamment son numéro de téléphone, son adresse e-mail et son numéro de matricule CNSS. Vous pourrez également gérer le personnel d’encadrement et de gestion en modifiant leurs informations, en ajoutant de nouveaux membres ou en supprimant des personnels existants.":
        "عند اختيار هذا النوع من التعديل، يمكنك تحديث معلومات الاتصال والتعريف الخاصة بمؤسستك، بما في ذلك رقم الهاتف، البريد الإلكتروني، ورقم الانخراط في الصندوق الوطني للضمان الاجتماعي. يمكنك أيضًا إدارة بيانات أعوان الإحاطة والتأطير من خلال تعديل معلوماتهم، إضافة أعضاء جدد أو حذف أفراد حاليين.",
      "En sélectionnant ce type de modification, vous pourrez mettre à jour les informations de contact et d’identification de votre établissement, notamment son numéro de téléphone, son adresse e-mail et son numéro de matricule CNSS. Vous pourrez également gérer le personnel d’encadrement et de gestion en modifiant leurs informations, en ajoutant de nouveaux membres ou en supprimant des personnels existants.":
        "عند اختيار هذا النوع من التعديل، يمكنك تحديث معلومات الاتصال والتعريف الخاصة بمؤسستك، بما في ذلك رقم الهاتف، البريد الإلكتروني، ورقم الانخراط في الصندوق الوطني للضمان الاجتماعي. يمكنك أيضًا إدارة بيانات أعوان الإحاطة والتأطير من خلال تعديل معلوماتهم، إضافة أعضاء جدد أو حذف أفراد حاليين.",
      "Ce type de modification vous permet de gérer l’offre de formation de votre établissement en ajoutant, supprimant ou modifiant des formations. Vous pourrez également mettre à jour les informations des formateurs, ajuster leur situation ou leur type de contrat, ainsi qu’ajouter ou supprimer des formateurs. De plus, vous pourrez modifier, ajouter ou supprimer des espaces et des équipements de formation.":
        "يتيح لك هذا النوع من التعديل تحيين أنواع التكوين والاختصاصات في مؤسستك من خلال إضافة، حذف أو تعديل التكوينات. كما يمكنك تحديث معلومات المكونين، تعديل وضعيتهم أو نوع عقودهم، بالإضافة إلى إمكانية إضافة أو حذف مكونين. علاوة على ذلك، يمكنك تعديل، إضافة أو حذف الفضاءات والمعدات التكوينية.",
      "En choisissant ce type de modification, vous pourrez mettre à jour les informations du directeur de l’établissement, notamment son identité et ses coordonnées.":
        "من خلال اختيار هذا النوع من التعديل، يمكنك تحديث معلومات مدير المؤسسة، بما في ذلك هويته ومعلومات الاتصال به.",
      Réclamation: "اشكال تقني",
      "L'email du directeur a été changé avec succès":
        "تم تغيير بريد المدير بنجاح",
      "Quelque chose s'est mal passé": "حدث خطأ ما",
      "Création EPF existant": "إضافة مؤسسة تكوين مهني خاصة إلى حسابكم",
      "Création EPF": "إحداث مؤسسة تكوين مهني خاصة",
      "Création EPF annexe": "إحداث فرع مؤسسة تكوين مهني خاص",
      "Modification EPF affectant le BO":
        "تغييرات على مؤسسة تكوين مهني خاصة تؤثر على وصل التسجيل",
      "Modification EPF sans validation":
        "تغييرات على مؤسسة تكوين مهني خاصة لا تطلب موافقة",
      "Modification EPF sans impact sur le BO":
        "تغييرات على مؤسسة تكوين مهني خاصة لا تؤثر على وصل التسجيل",
        "Modification annexe affectant le BO":
        "تغييرات على فرع مؤسسة تكوين مهني خاصة تؤثر على وصل التسجيل",
      "Modification annexe sans validation":
        "تغييرات على فرع مؤسسة تكوين مهني خاصة لا تطلب موافقة",
      "Modification annexe sans impact sur le BO":
        "تغييرات على فرع مؤسسة تكوين مهني خاصة لا تؤثر على وصل التسجيل",
      "Aucune réclamation trouvée": "لم يتم العثور على مطالب",
      "Minimum 4 caractères requis": "يجب أن يحتوي على 4 أحرف كحد أدنى",
      "Nous avons détecté un changement d'adresse e-mail. Pour poursuivre, veuillez saisir le code OTP que nous avons envoyé à cette nouvelle adresse : ":
        "لقد لاحظنا تغييرًا في عنوان البريد الإلكتروني. للمتابعة، يرجى إدخال رمز التحقق الذي أرسلناه إلى هذا العنوان الجديد: ",
      "Aucun document n'est disponible.": "لا توجد وثائق متوفرة حاليًا.",
      "Documents générés via la plateforme": "الوثائق الصادرة عن المنصة",
      "Cliquez sur le nom du document pour le télécharger":
        "اضغط على إسم الوثيقة لتنزيلها",
      "Nouvelle Réclamation Technique": "إبلاغ عن اشكال تقني جديد",
      "Réclamation technique": "الإبلاغ عن اشكال تقني",
      "Type de Réclamation": "نوع المشكلة",
      "Mes Informations": "معلوماتي",
      "Vous n'avez pas reçu le code ?": "لم تتلقى رمز التحقق؟",
      Renvoyer: "إعادة الارسال",
      "Votre réclamation a été enregistrée avec succès": "تم تسجيل مطلبك بنجاح",
      "Vous ne pouvez pas ajouter de commentaire à un ticket fermé ou en cours de traitement":
        "لا يمكنك إضافة تعليق إلى مطلب مغلق أو قيد المعالجة",
      "Votre commentaire a été ajouté avec succès": "تم إضافة تعليقك بنجاح",
      Sujet: "الموضوع",
      Réussi: "تم بنجاح",
      Description: "وصف الإشكال التقني",
      Date: "التاريخ",
      "Ce champ est obligatoire": "هذه الخانة اجبارية",
      "Les espaces blancs ne sont pas autorisés": "المساحات الفارغة غير مقبولة",
      "Minimum 10 caractères requis": "يجب أن يحتوي على 10 أحرف كحد أدنى",
      BO: "وصل الإيداع",
      "J'ai compris": "فهمت",
      "Activation de votre compte Formateur": "تفعيل حسابك كمكون",
      "Veuillez mettre à jour les informations de votre profil et cliquer sur 'Mettre à jour mes données' pour activer votre compte formateur. Sans cette mise à jour, votre compte ne sera pas activé auprès des Etablissements de formation privée.":
        "يرجى تحديث معلومات ملفك الشخصي والنقر على 'تحديث بياناتي' لتفعيل حسابك كمكون. بدون إتمام هذه الخطوة، لن يتم تفعيل حسابك لدى مؤسسات التكوين المهني الخاصة",
      "Error !": "خطأ !",
      "Votre brouillon a bien été annulé.": "تم إلغاء المسودة بنجاح",
      Erreur: "خطأ",
      "Votre inscription est enregistrée": "لقد تم تسجيلك بنجاح.",
      "Merci de confirmer votre compte pour finaliser la création de votre compte.":
        "يرجى تأكيد حسابك لإتمام إنشاء حسابك.",
      "Votre compte a été mis à jour avec succes": "تم تحديث حسابك بنجاح",
      "Le Couple CIN/Date de naissance est erroné":
        "رقم التعريف الشخصي/تاريخ الميلاد غير صحيح",
      "Cin,Date de naissance conformes.Citoyen non vivant":
        "بطاقة التعريف الوطنية وتاريخ الميلاد متوافقان. المواطن غير حي",
      "Aucun citoyen trouv\u00e9 correspondant aux donn\u00e9es saisies":
        ".لم يتم العثور على أي مواطن مطابق للبيانات المدخلة",
      "Sign up": "اشتراك",
      "Adresse e-mail": "البريد الإلكتروني",
      Nationalité: "الجنسية",
      "Date de naissance": "تاريخ الولادة",
      "Numéro de téléphone": "رقم الهاتف",
      Passeport: "رقم جواز السفر",
      "Date d’expiration du passeport": "تاريخ انتهاء جواز السفر",
      "Veuillez entrer uniquement des lettres arabes et des chiffres.":
        "يرجى إدخال الحروف العربية والأرقام فقط.",
      Rechercher: "بحث",
      Investisseur: "المستثمر",
      "Type de personne": "نوع الشركة",
      "Personne Physique": "شخص طبيعي",
      "Personne Morale": "شخص معنوي",
      "Nom de l'investisseur": "اسم المستثمر",
      "Prénom de l'investisseur": "لقب المستثمر",
      "Adresse de résidence": "عنوان الإقامة",
      "Niveau scolaire": "المستوى الدراسي",
      Nature: "الطبيعة",
      Type: "النوع",
      Homologuée: "معترف بها",
      "Non homologuée": "غير معترف بها",
      Formation: "التكوين",
      Secteur: "القطاع",
      "Sous-secteur": "القطاع الفرعي",
      Diplôme: "الشهادة",
      "Niveau requis": "المستوى المطلوب",
      "Durée en (H)": "المدة بالساعات",
      "Durée en (mois)": "المدة بالأشهر",
      "Durée minimale": "المدة الدنيا",
      "Mode de formation": "طريقة التكوين",
      "Ajouter une formation": "إضافة تكوين",
      "Création d'un nouvel EPF": "إحداث مؤسسة تكوين مهني خاصة جديدة",
      Page: "الصفحة",
      "Personne à besoin spécifique": "احتياجات خاصة",
      Genre: "الجنس",
      "Lieu de naissance": "مكان الولادة",
      "Numéro de passeport": "رقم جواز السفر",
      "Gouvernorat de résidence": "ولاية الإقامة",
      "Délégation de résidence": "معتمدية الإقامة",
      "Code postal": "الترقيم البريدي",
      "Dénomination commerciale": "الإسم التجاري",
      "Numéro de réservation": "رقم الحجز",
      "Type de l'EPF": "نوع هيكل التكوين الخاص",
      Logo: "الشارة",
      "Numéro fiscal": "المعرف الجبائي",
      "Numéro CNSS": "رقم الصندوق الوطني للضمان الاجتماعي",
      "Numéro de téléphone 1": "رقم الهاتف 1",
      "Numéro de téléphone 2": "رقم الهاتف 2",
      "Numéro de fax": "رقم الفاكس",
      "Matricule CNSS": "رقم التسجيل في الصندوق الوطني للضمان الاجتماعي",
      "Nature de formation": "طبيعة التكوين",
      "Adresse de l’établissement": "عنوان الهيكل",
      Gouvernorat: "الولاية",
      Délégation: "المعتمدية",
      "Données de l’établissement de formation": "معطيات هيكل التكوين الخاص",
      "Données de Directeur de l’Établissement": "بيانات مدير المؤسسة",
      Nom: "الاسم",
      Prénom: "اللقب",
      "Date d’autorisation de ministre": "تاريخ ترخيص الوزير",
      "Numéro CIN": "رقم بطاقة التعريف الوطنية",
      Adresse: "العنوان",
      "Dossier du directeur": "ملف المدير (السيرة الذاتية وغيرها)",
      "Nombre d’années d’expérience": "عدد سنوات الخبرة",
      "Données sur la nature de formation": "بيانات حول طبيعة التكوين",
      "Données des Formateurs": "بيانات المكونين",
      Dossier: "الملف",
      "Domaines de spécialité": "مجالات التخصص",
      Situation: "الوضعية",
      "Données des Espaces": "بيانات الفضاءات",
      Espace: "فضاء",
      Libellé: "الوصف",
      "Superficie en m²": "المساحة بالمتر المربع",
      "Capacité en personne": "طاقة الإستيعاب للأشخاص",
      "Données des Équipements": "بيانات المعدات",
      Nombre: "العدد",
      "Fiche technique": "البطاقة الفنية",
      "Données du personnel d'encadrement et de gestion":
        "بيانات أعوان الإحاطة والتأطير",
      Personnel: "العون",
      Catégorie: "الفئة",
      "Années d’expérience professionnelle": "سنوات الخبرة المهنية",
      "Dossier de personnel": "ملف الموظف",
      "J’ai lu et accepté les termes du Cahier des Charges (CDC) lié à la création d’un EPF.":
        "أصّرح بأّني اطّلعت ووافقت على كراس الشروط المتعلق بضبط قواعد إحداث وسير هياكل التكوين الخاصة",
      Présentielle: "حضورية",
      "À distance": "عن بعد",
      "En alternance": "بالتناوب",
      Initiale: "أولية",
      Continue: "مستمرة",
      "Initiale et continue": "أولية ومستمرة",
      Vacataire: "متعاقد",
      Principal: "رئيسي",
      Annexe: "الفرع",
      Annexes: "الفروع",
      Principale: "المؤسسة الرئيسية",
      Homme: "ذكر",
      Femme: "أنثى",
      "Créer un nouvel EPF": "إحداث مؤسسة تكوين مهني خاصة",
      "EPF existant": "مؤسسة تكوين مهني خاصة موجودة",
      "Le champ doit contenir 7 chiffres et une lettre à la fin.":
        "يجب أن يحتوي الحقل على 7 أرقام وحرف في النهاية",
      "Le champ doit contenir 3 lettres": "يجب أن يحتوي الحقل على 3 أحرف",
      "Le champ doit contenir 3 chiffres": "يجب أن يحتوي الحقل على 3 أرقام",
      "Sélectionnez la situation d'EPF":
        "إختر وضعية مؤسسة التكوين المهني الخاصة",
      "Merci de remplir les informations de l'établissement existante":
        "يرجى ملء معلومات المؤسسة الموجودة",
      "Type de l'établissement": "نوع المؤسسة",
      "Numéro de la patente": "رقم السجل التجاري",
      "Nouvel EPF": "مؤسسة تكوين مهني خاصة جديدة",
      Valider: "تأكيد",
      Annuler: "إلغاء",
      Consulter: "الإطلاع",
      Modifier: "تعديل",
      Fermer: "إغلاق",
      Actions: "إجراءات",
      Statut: "الحالة",
      Soumettre: "تقديم",
      Confirmation: "تأكيد",
      "Le numéro cin doit être au format 8 chiffres(ex: 11111111).":
        "يجب أن يكون رقم البطاقة الوطنية بصيغة 8 أرقام (مثال: 11111111).",
      "Veuillez valider le numéro de téléphone.": "يرجى تأكيد رقم الهاتف.",
      "Êtes-vous sûr(e) de vouloir soumettre ces informations ?":
        "هل أنت متأكد(ة) من رغبتك في تقديم هذه المعلومات ؟",
      "Si vous êtes certain(e) que toutes les informations sont correctes, cliquez sur 'Valider' pour continuer.":
        " إذا كنت متأكد(ة) أن جميع المعلومات صحيحة، انقر على 'تأكيد' للمتابعة.",
      "Soumettre la demande": "تقديم المطلب",
      "Ajouter un personnel": "إضافة عون",
      "Ajouter un espace": "إضافة فضاء",
      "Dénomination officielle": "التسمية الرسمية",
      "Date d’expiration de la réservation": "تاريخ انتهاء الحجز",
      "Forme Juridique": "الشكل القانوني",
      "Adresse du siège social": "عنوان المقر الرئيسي",
      "Secteur d’activité": "قطاع النشاط",
      "Numéro d’identifiant fiscal / RNE": "رقم المعرف الجبائي / RNE",
      "Numéro de matricule CNSS":
        "رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",
      "Liste de mes EPFs": "مؤسسات التكوين المهني الخاصة بي",
      "Liste de mes établissements": "مؤسسات التكوين المهني الخاصة بي",

      Dénomination: "التسمية",
      Code: "الرمز",
      Directeur: "المدير",
      "Matricule fiscal": "المعرف الجبائي",
      "Mes demandes": "مطالبي",
      "Liste de mes demandes": "قائمة مطالبي",
      Demandes: "المطالب",
      "Mes établissements": "مؤسساتي",
      Formateurs: "المكونين",
      Identité: "الهوية",
      "Continue et Initiale": "مستمرة وأولية",
      "Décrivez si la personne a des besoins spécifiques ou non":
        "حدد إذا كانت هناك احتياجات خاصة للشخص أم لا",
      Oui: "نعم",
      Non: "لا",
      Encadrement: "التأطير",
      Gestion: "الإحاطة",
      Permanent: "قار",
      "Non permanent": "غير قار",
      "Espace investisseur": "فضاء المستثمر",
      Primaire: "ابتدائي",
      Secondaire: "ثانوي",
      Universitaire: "جامعي",
      "Télécharger le logo": "تحميل الشارة",
      "Cliquez ou glissez pour télécharger le CV et le dossier du directeur":
        "انقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
      "Cliquez ou glissez pour télécharger le  dossier du formateur":
        "انقر أو اسحب لتحميل ملف المكون",
      "Reprendre l’activité": "استئناف النشاط",
      "Veuillez remplir tous les champs obligatoires.":
        "يرجى ملء جميع الخانات الإلزامية.",
      "Veuillez remplir ce champ.": "يرجى ملء هذه الخانة.",
      "Veuillez vérifier ce champ.": "يرجى التحقق من هذه الخانة.",
      Baccalauréat: "البكالوريا",
      Licence: "الإجازة",
      Master: "الماجستير",
      Doctorat: "الدكتوراه",
      "Votre demande a été ajouté avec succés": "تم إضافة مطلبك بنجاح",
      "Vous êtes sûr de vouloir soumettre cette demande ?":
        "هل أنت متأكد من رغبتك في تقديم هذا المطلب؟",
      "Numéro d'enregistrement": "رقم التسجيل",
      Domaine: "المجال",
      "Créer un nouveau compte formateur": "إنشاء حساب مكون جديد",
      "Créer un nouveau compte investisseur": "إنشاء حساب مستثمر جديد",
      "Vous-avez déjà un compte ?": "هل لديك حساب ؟",
      "Se connecter": "تسجيل الدخول",
      "Mot de passe": "كلمة المرور",
      "Nouveau mot de passe": "كلمة المرور الجديدة",
      "Confirmer le mot de passe": "تأكيد كلمة المرور",
      "Le mot de passe doit contenir au moins 6 caractères, dont au moins un chiffre (0-9) et au moins un caractère spécial (/ . @ -).":
        "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل، وتشمل رقمًا واحدًا على الأقل (0-9) ورمزًا مميزًا واحدًا مثل (/ . @ -).",
      "Le mot de passe et la confirmation doivent être identiques.":
        "يجب أن تكون كلمة المرور والتأكيد متطابقين.",
      "Vous n'avez pas de compte ?": "ليس لديك حساب؟",
      "Mot de passe changé avec succès !": "تم تغيير كلمة المرور بنجاح!",
      "Créez-en un": " أنشئ حسابًا",
      "Liste des formateurs": "قائمة المكونين",
      formateurs: "المكونين",
      "Espace formateur": "فضاء المكون",
      "Mon profil": "ملفي الشخصي",
      "Se déconnecter": "تسجيل الخروج",
      "Dénomination EPF": "التسمية",
      "Modifier Epf": " تعديل الهيكل",
      Etablissement: "الهيكل",
      Voir: "عرض",
      "J'ai pris connaissance et accepté les termes du CDC relatifs à la création d'un EPF.":
        "لقد قرأت ووافقت على شروط كراس الشروط المتعلقة بإنشاء هيكل تكوين خاص",
      "Je confirme": "تأكيد",
      "Merci de cocher la case pour attester que vous avez pris connaissance des termes du CDC et que vous les acceptez concernant la création d'un EPF.":
        "يرجى وضع علامة في المربع لتأكيد أنك قرأت ووافقت على شروط كراس الشروط المتعلقة بإنشاء هيكل تكوين خاص",
      "Aucun établissement.": "ليس هناك مطالب",
      "Le numéro de CIN du formateur indiqué n'est pas associé à un compte sur la plateforme SIGAF. Veuillez vérifier le numéro de CIN fourni ou inviter le formateur à créer un compte sur la plateforme.":
        "رقم بطاقة التعريف الوطنية للمكون المُدرج غير مرتبط بحساب على منصة SIGAF. يُرجى التحقق من رقم بطاقة التعريف المُقدَّمة أو دعوة المكون لإنشاء حساب على المنصة.",
      "Veuillez parcourir le cahier des charges jusqu'à la fin afin de pouvoir confirmer sa lecture complète.":
        "يرجى تصفح كراس الشروط حتى النهاية لتتمكن من تأكيد قراءته بالكامل.",
      "Votre demande de création d'un nouvel EPF a été soumise avec succès. Vous recevrez un email vous informant des prochaines étapes à suivre.":
        "تم تقديم طلبك لإنشاء هيكل تكوين خاص بنجاح. ستتلقى بريدًا إلكترونيًا يوضح لك الخطوات التالية التي يجب اتباعها",
      "Votre demande de création d'un EPF existant a été soumise avec succès. Vous recevrez un email vous informant des prochaines étapes à suivre.":
        "تم إرسال طلب إنشاء مركز تكوين خاص قائم بنجاح. ستتلقى بريدًا إلكترونيًا لإعلامك بالخطوات التالية الواجب اتباعها.",
      "Liste des documents à fournir": "قائمة الوثائق المطلوب تقديمها",
      "Veuillez patientez ...": "يرجى الانتظار...",
      "Si vous ne savez pas où obtenir votre « Numéro d'établissement secondaire - رقم الفرع الثانوي », vous pouvez consulter votre carte d'identification fiscale":
        "إذا كنتم لا تعلمون من أين تحصلون على « رقم الفرع الثانوي - Numéro d'établissement secondaire »، يمكنكم الاطلاع على بطاقة التعريف الجبائية الخاصة بكم",
      "Voici un exemple de carte d'identification fiscale":
        "إليكم مثالًا على بطاقة التعريف الجبائية.",
      Notifications: "الإشعارات",
      "Nouvelle notification": "إشعار جديد",
      Accéder: "دخول",
      "Changer le mot de passe": "تغيير كلمة العبور",
      "La date sélectionnée ne peut pas être dans le futur. Veuillez choisir une date valide.":
        "خطأ: لا يمكن أن يكون التاريخ المحدد في المستقبل. الرجاء اختيار تاريخ صالح",
      "Veuillez entrer uniquement des lettres arabes.":
        "يرجى إدخال حروف عربية فقط.",
      "Veuillez entrer uniquement des lettres latines.":
        "يرجى إدخال حروف لاتينية فقط.",
      "Mettre à jours mes données": "تحديث بياناتي",
      "Mot de passe oublié ?": "هل نسيت كلمة المرور؟",
      "Veuillez entrer votre adresse e-mail":
        "يرجى إدخال عنوان بريدك الإلكتروني",
      "Sauvegarder au brouillon": "حفظ كمسودة",
      Confirmer: "تأكيد",
      "Liste de mes brouillons": "قائمة المسودات",
      "Liste de mes Annexes": "الفروع",
      "Date de création": "تاريخ الإحداث",
      "Sélectionnez la situation de l'Annexe": "يرجى اختيار حالة الفرع",
      "Créer une nouvelle Annexe": "إنشاء فرع جديد",
      "Vous devez disposer d'au moins un EPF pour pouvoir créer une annexe":
        "يجب أن يكون لديك على الأقل مؤسسة تدريب خاصة للتمكن من إنشاء ملحق",
      "EPF de l'Annexe": "المؤسسة الأم للفرع",
      "Date dernière modification": "تاريخ آخر تغيير",
      "Date de soumission": "تاريخ التقديم",
      "Aucune demande.": "ليس لديك مطالب",
      ID: "معرف",
      "Aucune donnée": "لا يوجد معطيات",
      Acceuil: "الإستقبال",
      acceuil: "الإستقبال",
      Profil: "الملف الشخصي",
      "Aucune notification": "لا يوجد اشعارات",
      "Veuillez vérifier votre email et cliquer sur le lien de confirmation pour valider votre compte.\nPensez à vérifier vos spams si nécessaire.":
        "يرجى التحقق من بريدك الإلكتروني والنقر على رابط التفعيل لتأكيد حسابك. لا تنسَ التحقق من مجلد الرسائل غير المرغوب فيها إذا لم تجد الرسالة في البريد الوارد.",
      "Numéro d’identifiant fiscal / RNE invalide":
        "رقم المعرف الجبائي لدى السجل الوطني للمؤسسات غير صالح.",
      "Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 1234567A).":
        "يجب أن يكون رقم المعرف الجبائي لدى السجل الوطني للمؤسسات بالصيغة المكونة من 7 أرقام متبوعة بحرف كبير (مثال: 1234567A ).",
      "Veuillez entrer des données valides.": "يرجى إدخال بيانات صحيحة.",
      "Annuler la demande": "إلغاء الطلب",
      Supprimer: "حذف",
      "Consulter l'annexe": "الإطلاع على الفرع",
      "Consulter l'établissement": "الإطلاع على المؤسسة",
      "Non renseigné": "غير مذكور",
      "Le numéro de téléphone doit être au format +216 xx xxx xxx.":
        "يجب أن يكون رقم الهاتف بالصيغة +216 xx xxx xxx.",
      "Merci d'entrer une adresse mail valide.":
        "يرجى إدخال عنوان بريد إلكتروني صالح.",
      "Fourniture des Informations et Documents Requis":
        "تقديم المعلومات والوثائق المطلوبة",
      "Cette section est dédiée à la saisie des informations demandées et au téléchargement des documents nécessaires pour compléter votre demande. Assurez-vous de remplir correctement tous les champs requis et de fournir les fichiers dans le format demandé, afin de faciliter le traitement de votre dossier. Avant de valider, vérifiez que toutes les informations et documents ont bien été fournis.":
        "هذا القسم مخصص لإدخال المعلومات المطلوبة وتحميل الوثائق اللازمة لاستكمال مطلبك. تأكد من تعبئة جميع الحقول بشكل صحيح وتقديم الملفات بالشكل المطلوب لتسهيل معالجة ملفك. قبل الإرسال، تحقق من أنك قدمت جميع المعلومات والوثائق المطلوبة.",

      "La valeur ne doit pas être inférieure à 0.": "يجب ألا تقل القيمة عن 0.",
      "La valeur ne doit pas être inférieure à 1.": "يجب ألا تقل القيمة عن 1.",
      "Veuillez sélectionner au moins 2 types de formation (Initiale et Continue).":
        "يرجى اختيار نوعين على الأقل من التكوين (أولي و مستمر).",
      "Attention, l'annulation de la demande entraînera la suppression du brouillon.":
        "تنبيه: إلغاء الطلب سيؤدي إلى حذف المسودة",
      "Le numéro de CIN du formateur n'est pas associé à un compte sur la plateforme.":
        "رقم بطاقة الهوية الوطنية للمدرب غير مرتبط بحساب على المنصة.",
      "Le numéro de passeport du formateur n'est pas associé à un compte sur la plateforme.":
        "رقم جواز السفر الخاص بالمدرب غير مرتبط بحساب على المنصة.",
      "Veuillez entrer des données valides pour le personnel d'encadrement et de gestion.":
        "يرجى إدخال بيانات صحيحة للموظفين الإداريين والمشرفين.",
      "Nombre d'années d'expérience supérieure à votre âge":
        "عدد سنوات الخبرة يتجاوز عمرك",
      "Erreur lors de la création de votre compte.": "خطأ أثناء إنشاء حسابكم",
      "Veuillez remplir le champ avant de lancer la recherche":
        "يرجى ملء الخانة قبل بدء عملية البحث",
      "Adresse mail n'existe pas !": "خطأ ! البريد الإلكتروني غير موجود",
      "Les documents requis pour le complément de dossier":
        "المستندات المطلوبة لاستكمال الملف",
      "Envoyer les documents": "إرسال المعلومات والوثائق",
      "Veuillez télécharger un fichier.": "يرجى تحميل ملف.",
      "Téléchargement en cours...": "جاري التحميل...",
      "Erreur de téléchargement des fichiers.": "خطأ في تحميل الملفات.",
      "Tous les fichiers ont été téléchargés avec succès.":
        "تم تحميل جميع الملفات بنجاح.",
      "Complément de dossier": "إتمام الملف",
      "Vous avez modifié des données sans les valider via l'icône de recherche.":
        "لقد قمت بتعديل البيانات دون التحقق منها عبر أيقونة البحث.",
      "Vous n'êtes pas autorisé à créer un établissement pour cette entreprise.":
        "ليس لديك الصلاحية لإنشاء هذا الهيكل لهذه الشركة.",
      "Vous ne disposez pas d’un nom commercial. Pour continuer l’inscription vous êtes invité à prendre attache au RNE pour avoir un nom commercial.":
        "ليس لديك اسم تجاري. لمتابعة التسجيل، يُرجى الاتصال بالسجل الوطني للمؤسّسات للحصول على اسم تجاري.",
      "Aucune entreprise n'a été trouvée avec ce matricule fiscal.":
        "لم يتم العثور على أي شركة بهذا المعرف الجبائي.",
      "Aucune entreprise n'a été trouvée avec ce numéro enregistrement.":
        "لم يتم العثور على أي شركة بهذا الرقم التسجيل.",
      "Une copie RNE": "نسخة من السجل التجاري",
      "Une copie de PV changement de dirigeant": "نسخة من محضر تغيير المدير",
      "Un enregistrement existant avec ce couple RNE / Gouvernorat existe déjà. Il s'agit de la création d'une annexe, et non d'un nouvel EPF.":
        "يوجد سجل موجود بالفعل بهذا الزوج من رقم التسجيل الوطني و المعتمدية. هذه العملية تخص إنشاء ملحق وليس إنشاء مؤسسة تعليمية خاصة جديدة.",
      "Créer un EPF existant": "إحداث مؤسسة تكوين مهني خاصة موجودة",
      "Changer l'investisseur d'un EPF":
        "تغيير المستثمر لمؤسسة تكوين مهني خاصة",
      "Télécharger les documents": "تحميل المستندات",
      "Vous avez déjà commencé une demande de modification de ce type. Merci de consulter votre brouillon.":
        "لقد بدأتم سابقا بطلب تعديل من هذا النوع. يرجى الاطلاع على المسودة الخاصة بكم",
      info: "إشعار",
      "Choisissez une option": "اختر ما يناسبك",
      "Le numéro de matricule CNSS doit être composé de 8 chiffres (exemple : 11111111).":
        "من 8 أرقام (على سبيل المثال: 11111111) (CNSS) يجب أن يتكون رقم تسجيل الصندوق الوطني للضمان الاجتماعي",
      "Le numéro de patente doit respecter le format suivant : 7 chiffres, suivis de 3 lettres, puis de 3 chiffres (exemple : 1234567ABC123).":
        " (على سبيل المثال: 1234567ABC123). يجب أن يلتزم رقم البطاقة الجبائية بالتنسيق التالي: 7 أرقام، متبوعة بـ 3 أحرف، ثم 3 أرقام",

      "Vous avez modifié des données sans les valider en utilisant le bouton de recherche.":
        "لقد قمتم بتعديل البيانات دون التحقق منها باستخدام زر البحث.",
      "Le numéro de matricule fiscal ou le numéro IURNE doit respecter le format suivant : 7 chiffres suivis d'une lettre majuscule (exemple : 0000000A).":
        "يجب أن يلتزم رقم المعرّف الجبائي أو رقم IURNE بالتنسيق التالي: 7 أرقام تليها حرف كبير (مثال: 0000000A).",
      "Le numéro d'État secondaire doit être composé uniquement de 3 chiffres (exemple : 111).":
        "يجب أن يتكون رقم الحالة الثانوية من 3 أرقام فقط (مثال: 111).",
      "Ajouter une annexe": "إضافة فرع",
      "Mes annexes": "فروع مؤسساتي",
      "L’adresse de l’annexe doit être dans le même gouvernorat que l’EPF principal.":
        "يجب أن يكون عنوان الفرع في نفس الولاية التي تقع فيها المؤسسة الأم",
      "L’adresse de l’annexe doit être dans le même gouvernorat que l’EPF principal. Veuillez accéder à la rubrique ‘nouvel EPF’ pour enregistrer un établissement dans un gouvernorat différent.":
        " يجب أن يكون عنوان الملحق في نفس الولاية التي تقع فيها المؤسسة الأم. يرجى التوجه إلى قسم ''إضافة مؤسسة جديدة'' لتسجيل مؤسسة في ولاية مختلفة",
      Enregistrer: "تسجيل",
      "Matricule Fiscal inexistant": "المعرف الجبائي غير موجود",
      "Étape de l'obtention de la validation finale":
        "مرحلة الحصول على المصادقة النهائية",
      Annulée: "ملغى",
      "Nécessite la validation de la direction régionale concernée et mise à jour du numéro d’enregistrement":
        "تعديلات تؤثر على وصل الإيداع وتتطلب موافقة إلزامية من الإدارة الجهوية.",
      "Veuillez valider les données en utilisant le bouton de recherche.":
        "يرجى التحقق من صحة البيانات باستخدام زر البحث.",
      "Vous pouvez télécharger vos documents (Déclaration, Reçu bureau d'ordre) en utilisant la liste des actions disponible devant chaque demande.":
        "يمكنكم تحميل مستنداتكم (التصريح، وصل مكتب الضبط، وصل إيداع) باستخدام قائمة الإجراءات الموجودة أمام كل طلب.",

      "La réservation n’existe pas, le numéro de réservation est invalide.":
        "الحجز غير موجود، رقم الحجز غير صالح",
      "Il est impossible de supprimer tous les types de formations, car cela entraînerait la fermeture de l'EPF.":
        "لا يمكن حذف جميع أنواع التكوينات لأن ذلك سيؤدي إلى إغلاق مركز التكوين المهني الخاص.",
      "La connexion à votre compte E-Houwya a été réussie. Cependant, aucun compte de directeur n'est associé à votre profil sur la plateforme SIGAF !":
        "تمت عملية تسجيل الدخول إلى حسابك على E-Houwya بنجاح.لكن لا يوجد أي حساب مدير مرتبط بحسابك على منصة SIGAF.",
      "Ajouter un EPF existant": "إضافة مؤسسة تكوين مهني خاصة موجودة",
      "Changer l&#39;investisseur d&#39;un EPF":
        "تغيير المستثمر لمؤسسة تكوين مهني خاصة",
      "Une erreur est survenue lors du téléversement de votre document. Veuillez réessayer.":
        "حدث خطأ أثناء تحميل مستندك. يُرجى المحاولة مرة أخرى.",
      "Vous avez déjà commencé une demande de création d'annexe. Merci de consulter votre brouillon.":
        "لقد بدأتم سابقًا بطلب إنشاء فرع. يرجى الاطلاع على المسودة الخاصة بكم.",
      "Vous avez déjà commencé une demande de création d'un EPF. Merci de consulter votre brouillon.":
        "لقد بدأتم سابقًا بطلب إنشاء مؤسسة تكوين مهني خاصة. يرجى الاطلاع على المسودة الخاصة بكم.",
      "Le responsable ajouté est fonctionnel dans un autre EPF.":
        "المسؤول المضاف يعمل في مؤسسة تكوين مهني خاصة أخرى.",
      "Veuillez entrer exactement 8 chiffres.": "يرجى إدخال 8 أرقام فقط",
      "Commentaires  et Documents de l'Administration":
        "الملاحظات و الوثائق  المقدمة من الإدارة",
      "Administration :": "الإدارة المعنية :",
      "Moi :": "أنا :",
      "Consulter la demande": "الإطلاع على المطلب",
      "Compléter la demande": "استكمال المطلب",
      "Télécharger les documents ": "تحميل الوثائق",
      "Commentaires de l'administration": "ملاحظات الإدارة المعنية ",
      "Téléchargement des Documents Requis": "تحميل الوثائق المطلوبة",
      "Lors du traitement de votre demande, l'administration a ajouté des documents ou des commentaires pour vous guider dans les démarches à suivre, vous les trouverez dans cette section :":
        "أثناء معالجة مطلبك، أرفقت الإدارة وثائق أو تركت ملاحظات لتوجيهك في الخطوات الواجب اتباعها ستجدها في هذا القسم:",
      "Cette section est dédiée au téléchargement des documents demandés par l'administration pour compléter votre demande. Assurez-vous de fournir tous les fichiers requis au bon format afin de faciliter le traitement de votre dossier. Une fois les documents téléchargés, vérifiez que la liste est complète avant de valider.":
        "هذا القسم مخصص لتحميل الوثائق التي طلبتها الإدارة لاستكمال مطلبك. تأكد من تقديم جميع الملفات المطلوبة بالشكل الصحيح لتسهيل معالجة ملفك. بعد تحميل الوثائق، تحقق من اكتمال القائمة قبل الإرسال.",
      Responsable: "المسؤول",
      Liste: "قائمة",
      "Le couple numéro de patente et établissement secondaire est introuvable.":
        "الزوج المكون من رقم المعرف الجبائي والمؤسسة الفرعية غير موجود",
      "Le numéro d'établissement secondaire d'une annexe ne peut pas être défini avec la valeur 000.":
        ".خطأ: لا يمكن تحديد رقم المؤسسة الفرعية للفرع بالقيمة 000",
      "Vous ne disposez pas de commentaire pour cette demande !":
        "لا يوجد لديك أي تعليق لهذا الطلب !",
      "Liste des annexes": "قائمة الفروع",
      "Documents et Commentaires de l'Administration":
        "الملاحظات و الوثائق  المقدمة من الإدارة",

      "Pour finaliser votre demande, veuillez vérifier les commentaires et les documents éventuellement fournis par l'administration dans la première section. Ensuite, téléchargez les documents requis par l'administration dans la deuxième section.":
        "لإتمام مطلبكم، يرجى الاطلاع على الملاحظات والوثائق التي قد تكون قدمتها الإدارة في القسم الأول، ثم تحميل الوثائق المطلوبة من قبل الإدارة في القسم الثاني.",
      "Commentaires de l'administration concernée": "ملاحظات الإدارة المعنية",
      Commentaires: "التعاليق",
      Commentaire: "تعليق",
      "Veuillez consulter votre boîte mail. Un lien de réinitialisation de mot de passe vous a été envoyé.":
        "يرجى التحقق من بريدك الإلكتروني، حيث تم إرسال رابط لإعادة إختيار كلمة المرور",
      "Votre mot de passe a été réinitialisé avec succès.":
        "تم تغيير كلمة المرور الخاصة بك بنجاح",
      "Complétion de Votre Demande": "استكمال مطلبك",
      "EPF principal": "المؤسسة الأم",
      "Vous ne disposez pas d'annexe pour cette EPF !":
        "هذه المؤسسة لا تحتوي على فروع !",
      "Le compte du formateur n'est pas activé.": "حساب المكون غير مفعل",
      "La durée minimale en heures doit être inférieure ou égale à la durée totale en heures.":
        "يجب أن تكون المدة الدنيا بالساعات أقل أو تساوي المدة الإجمالية بالساعات.",
      "Tout marquer comme lu": "اعتبار جميع الإشعارات كمقروءة",
      "Télécharger: Déclaration / Décharge / BO":
        "تحميل: التصريح / وصل مكتب الضبط / وصل الإيداع",
      "Votre demande de création d'une nouvelle annexe a été soumise avec succès.":
        "لقد تم تسجيل طلبكم لإنشاء فرع جديد بنجاح.",
      "Erreur lors de la récupération des données.":
        "حدث خطأ أثناء تحميل البيانات من المصدر",
      "Les informations fournies ont été ajoutées avec succès à votre demande. Vous recevrez bientôt une notification par email ou sur la plateforme.":
        "تم إضافة المعلومات المقدمة بنجاح إلى مطلبكم. ستتلقون إشعارًا قريبًا عبر البريد الإلكتروني أو على المنصة.",
      Brouillon: "مسودة",
      "Saisissez le nouvel e-mail de":
        " أدخل عنوان البريد الإلكتروني الجديد لـ",
      "ci-dessous et confirmez le changement.": "أدناه وقم بتأكيد التغيير.",
      "Changer l'email du directeur": "تغيير عنوان البريد الإلكتروني للمدير",
      Soumise: "تم تلقي المطلب",
      "Veuillez entrer un email": "يرجى إدخال بريد إلكتروني",
      "Veuillez entrer un email valide": "يرجى إدخال بريد إلكتروني صالح",
      "Nouvel email": "البريد الإلكتروني الجديد",
      "Dossier à compléter": "بحاجة إلى تكملة",
      "En cours de traitement": "بصدد المعالجة",
      "Validée provisoirement": "موافقة مؤقتة",
      "Demande validée": "مقبول",
      "Complément soumis": "تم تلقي الملحق",
      "Demande refusée": "مرفوض",
      "Dossier manquant": "ملف منقوص",
      "Enregistrement échoué, merci de réessayer":
        "لم يتم التسجيل بنجاح، يرجى المحاولة مرة أخرى",
      "Saisie invalide, veuillez vérifier vos données.":
        "يرجى التأكد من البيانات المقدمة.",
      "Espace directeur": "فضاء مدير مؤسسة التكوين المهني الخاصة",
      "Êtes-vous sûr de vouloir envoyer les informations et les documents ?":
        " هل أنت متأكد من أنك تريد إرسال المعلومات والوثائق؟",
      "Confirmation d'envoi": "تأكيد الإرسال",
      "Redirection en cours": "جاري إعادة التوجيه",
      "Vous n'avez pas sélectionné d'espace dédié. Vous allez être redirigé vers la page principale pour choisir l'espace qui correspond à votre rôle (investisseur, formateur, etc).":
        "لم تقم بتحديد الفضاء المناسب. سيتم تحويلك إلى الصفحة الرئيسية لاختيار الفضاء الذي يتناسب مع دورك (مستثمر، مكون، إلخ).",
      "Mot de passe erroné.": ".كلمة المرور غير صحيحة",
      "Ce compte existe, mais il est attribué à un autre rôle ou espace.":
        ".الحساب موجود ولكن مرتبط بفضاء و دور مختلف",
      "Aucun compte n'est lié à cette adresse e-mail.":
        ".لا يوجد حساب مرتبط بهذا البريد الإلكتروني",
      "Le compte de cet utilisateur a été désactivé.":
        ".تم إلغاء حساب هذا المستخدم",
      "Votre demande a été validée provisoirement": "قبول مبدئي للمطلب",
      "Un nouveau commentaire a été ajouté à votre demande ":
        "تمت إضافة تعليق جديد إلى مطلبك",
      "Votre demande de modification a été validée":
        "تمت الموافقة على مطلب التعديل",
      "Votre demande a été validée": "تمت الموافقة على المطلب",
      "Complément de dossier requis pour votre demande":
        "عليك استكمال ملف المطلب",
      "Le directeur ajouté est fonctionnel dans un autre EPF.":
        ".المدير المضاف يشغل منصبًا في مؤسسة تكوين مهني خاصة أخرى",
      Reconnecter: "إعادة تسجيل الدخول",
      "Votre session a expiré": "انتهت صلاحية الدخول",
      "Oops, votre session a expiré. Veuillez vous reconnecter pour revenir à l’interface.":
        "عفوًا، انتهت صلاحية الدخول. يرجى إعادة تسجيل الدخول للعودة إلى الواجهة.",
      "Une demande de modification pour cet EPF ou l'une de ses annexes est déjà en cours de traitement, ou un brouillon existe déjà pour ce type de demande.":
        "لديك مطلب تعديل لهذه المؤسسة أو لأحد فروعها قيد المعالجة، أو هناك مسودة من نفس نوع المطلب. لا يمكنك تقديم مطلبي تعديل غير معالجين في نفس الوقت أو امتلاك عدة مسودات من نفس النوع.",
      "Aucune modification détectée": "لم تقوموا بإجراء أي تعديل",
      "Votre demande ne peut pas être soumise, car aucune modification n'a été constatée. Veuillez apporter des changements avant de réessayer":
        "لا يمكن تقديم الطلب لأنّه لم يتم تسجيل أي تعديل. يُرجى إجراء تغييرات قبل إعادة المحاولة",
      "Numéro d'enregistrement de l'EPF prinicipal": "رقم تسجيل المؤسسة الام",
      "Créateur de la demande": "المودع",
      "Êtes-vous sûr de vouloir annuler cette demande ? Cette action est irréversible.":
        "هل أنت متأكد أنك تريد إلغاء هذا المطلب؟ هذه العملية غير قابلة للتراجع.",
      "Votre demande a bien été annulée.": "تم إلغاء مطلبك بنجاح.",
      "Veuillez d'abord remplir le champ précédent !":
        "يرجى إكمال الاختيار السابق أولاً",
      "Votre demande est déjà en cours de traitement et ne peut plus être annulée à ce stade.":
        "مطلبك قيد المعالجة حاليا ولا يمكن إلغاؤه في هذه المرحلة.",
      "Votre profil a été modifié avec succès": "تم تعديل ملفك الشخصي بنجاح",
      "Le numéro d'enregistremessnt doit être composé de 10 chiffres (ex : 1720254273).":
        "يجب أن يتكوّن رقم التسجيل من 10 أرقام (مثال: 1720254273).",
      "Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 0123456A).":
        "يجب أن يكون رقم السجل الوطني للمؤسسات (RNE) مكوّنًا من 7 أرقام متبوعة بحرف (مثال: 0123456A).",
      "Le numéro de l'établissement secondaire doit être composé de 3 chiffres (ex : 000).":
        "يجب أن يتكوّن رقم المؤسسة الفرعية من 3 أرقام (مثال: 000).",
      "Vous pouvez consulter les détails de l'établissement, télécharger les documents associés, et visualiser les commentaires en utilisant la liste des actions disponible devant chaque établissement.":
        "يمكنكم الإطلاع على تفاصيل المؤسسة، تحميل الوثائق الخاصة بالمؤسسة، الإطلاع على التعاليق،  باستخدام قائمة الإجراءات الموجودة أمام كل مؤسسة",
      "Informations manquantes": "معطيات منقوصة",
      "En attente": "في الانتظار",
      Fermé: "مغلق",
      "Impossible de changer l'email": "تعذّر تغيير البريد الإلكتروني",
      "Vous ne pouvez pas changer l'email du directeur si le directeur est le même investisseur":
        "لا يمكنك تغيير البريد الإلكتروني للمدير إذا كان المدير هو نفسه المستثمر",
      "Le code OTP a bien été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception.":
        "تم إرسال رمز التحقق  بنجاح إلى بريدك الإلكتروني. يرجى التحقق من البريد الوارد",
      "Confirmez votre email": "تأكيد بريدك الإلكتروني.",
      "Le code OTP que vous avez saisi est incorrect. Veuillez vérifier et essayer à nouveau.":
        "رمز التحقق الذي أدخلته غير صحيح. يرجى التحقق والمحاولة مرة أخرى",
      "L'adresse e-mail de votre profil a été modifiée avec succès. Veuillez vous reconnecter avec votre nouvelle adresse e-mail.":
        "تم تعديل عنوان بريدك الإلكتروني بنجاح. يرجى تسجيل الدخول باستخدام عنوان بريدك الإلكتروني الجديد.",
      "Une interruption est survenue lors de la récupération des données. Veuillez réessayer.":
        "حدث انقطاع أثناء تحميل البيانات. يرجى المحاولة مرة أخرى",
      "Mes tâches": "مهامي",
      "Liste de mes tâches": " قائمة مهامي",
      "Vous devez obtenir une autorisation du Ministre chargé de la formation professionnelle pour assurer la direction de l’établissement privée de formation professionnelle.":
        "يجب عليك الحصول على ترخيص من الوزير المكلف بالتكوين المهني لتولي إدارة مؤسسة التكوين المهني الخاصة",
      "Vous pouvez consulter les détails de la demande, télécharger les documents associés, et visualiser les commentaires en utilisant la liste des actions disponible devant chaque demande.":
        "يمكنكم الإطلاع على تفاصيل المطلب، تحميل الوثائق الخاصة بالمطلب، الإطلاع على التعاليق،  باستخدام قائمة الإجراءات الموجودة أمام كل مطلب",
      "Liste des formations": "قائمة التكوينات",
      "Exigence d’homologation": "وجوب التنظير",
      Spécialité: "الإختصاص",
      "Spécialité ou Domaine":"الإختصاص أو المجال",
      État: "الحالة",
      Inactive: "غير مفعل",
      Homolguer: "تنظير",
      "Le directeur sélectionné possède déjà un compte avec un autre rôle sur la plateforme SIGAF. Veuillez fournir une autre adresse e-mail pour ce directeur afin de pouvoir lui créer ses accès une fois la demande validée.":
        "المدير الذي تم اختياره يمتلك حسابًا بدور آخر على المنصة.يرجى تقديم عنوان بريد إلكتروني آخر لهذا المدير حتى نتمكن من إنشاء حسابه بمجرد الموافقة على الطلب.",
      "En cours": "قيد المعالجة",
      Historique: "سجل المطالب",
      "Mon établissement": "مؤسستي",
      "Liste de mes demandes en cours": "قائمة مطالبي قيد المعالجة",
      "Numéro de réservation invalide": "رقم الحجز غير صحيح",
      "Liste des directeurs": "قائمة المديرين",
      "VOUS ÊTES DANS :": "أنتم في :",
      "Le numéro RNE doit être au format 9 chiffres(ex: 111111111).":
        "يجب أن يكون رقم المعرف الوحيد لدى السجل الوطني للمؤسسات مكونًا من 9 أرقام (مثال: 111111111).",
      "Un service externe à SIGAF ne répond pas.Veuillez réessayer. Si le problème persiste, essayez plus tard.":
        "إحدى الخدمات الخارجية غير التابعة للمنصة غير متاحة حاليًا. يرجى إعادة المحاولة. إذا استمر الإشكال، يرجى المحاولة لاحقًا.",
      "Date de fin de contrat": "تاريخ نهاية التعاقد",
      "Date de fin": "تاريخ النهاية",
      "Date de début":"تاريخ البداية",
      "DD/MM/YYYY": "اليوم / الشهر / السنة",
      "Veuillez entrer la date du fin de contrat de ce formateur":
        "يرجى إدخال تاريخ نهاية التعاقد",
      "Veuillez entrer la date du fin": "يرجى إدخال تاريخ النهاية",
      "Rajout d'un epf existant": "إضافة مؤسسة تكوين مهني خاصة موجودة",
      "Mon annexe": "فروع مؤسستي",
      "Le responsable sélectionné possède déjà un compte avec un autre rôle sur la plateforme SIGAF. Veuillez fournir une autre adresse e-mail pour ce responsable.":
      "المسؤل الذي تم اختياره يمتلك حسابًا بدور آخر على المنصة.يرجى تقديم عنوان بريد إلكتروني آخر لهذا المسؤل.",
      "Opérationnel":"يعمل مع المؤسسة",
      "Non opérationnel":"محذوف من قبل المؤسسة",
      "supprimée":"محذوفة",
      "Veuillez valider l'email du directeur en cliquant sur l'icône de validation avant de poursuivre.":"يرجى تأكيد بريد المدير الإلكتروني بالنقر على أيقونة التحقق قبل المتابعة.",
      "L'email du directeur a été validé avec succès.":"تم التحقق من بريد المدير الإلكتروني بنجاح."

    },
  },
  fr: {
    translation: {
      "Mon annexe": "Mes annexes",
      "مدير غير المستثمر":"Directeur Autre que l’Investisseur",
      "المستثمر نفسه":"Investisseur lui-même"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallBackLng: "fr",
  lng: localStorage.getItem("language") || "fr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
