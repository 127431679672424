import React, { useContext, } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Modal,
  Popconfirm,
  notification,
  DatePicker,
} from "antd";
import GlobalContext from "../../../context/GlobalContext";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const { Option } = Select;
const CloseModal = (props) => {
  const { globalData, setGlobalData } = useContext(GlobalContext);
  const { stopModal, setStopModal } = props;
  const [form] = useForm();
  const {t}=useTranslation()
  const handleFermetureTypeChange = (value) => {
    setGlobalData({
      ...globalData,
      typeFermeture: value,
    });
    form.setFieldsValue({ objetFermeture: undefined });
  };

  const spetalites = JSON.parse(
    sessionStorage.getItem("globalData")
  )?.naturesDeFormation?.map((e) => {
    return e?.specialiteFormation;
  });

  return (
    <Modal
      title="Demande de fermeture"
      open={stopModal}
      //   onOk={handleOk}
      footer={false}
      onCancel={() => setStopModal(false)}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ maxWidth: "600px", margin: "auto", paddingTop: "20px" }}
      >
        <Form.Item
          label="Type de fermeture"
          name="typeFermeture"
          rules={[
            {
              required: true,
              message:
                "Sélectionner le type de fermeture : provisoire ou définitive",
            },
          ]}
        >
          <Select
            placeholder="Sélectionner le type de fermeture"
            onChange={handleFermetureTypeChange}
          >
            <Option value="provisoire">Provisoire</Option>
            <Option value="definitive">Définitive</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Objet de fermeture"
          name="objetFermeture"
          rules={[
            {
              required: true,
              message:
                "Sélectionner l'objet de la fermeture : EPF ou nature de formation",
            },
          ]}
        >
          <Select
            placeholder="Sélectionner l'objet de fermeture"
            onChange={(e) =>
              setGlobalData({
                ...globalData,
                objetFermeture: e,
              })
            }
          >
            <Option value="EPF">EPF</Option>
            <Option value="natureFormation">Nature de formation</Option>
            {globalData?.typeFermeture !== "provisoire" && (
              <Option value="spetiality">Spécialité</Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Motif de fermeture"
          name="motifFermeture"
          rules={[
            {
              required: true,
              message: "Décrivez le motif de la fermeture",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Décrivez le motif de la fermeture"
            rows={4}
          />
        </Form.Item>

        {globalData?.typeFermeture === "provisoire" && (
          <Row style={{ width: "100%" }} gutter={16}>
            <Col lg={12} xs={24}>
              <Form.Item
                label="Date de début"
                name="debut"
                rules={[
                  {
                    required: true,
                    message:
                      "Veuillez remplir la date de début de fermeture provisoire",
                  },
                ]}
              >
                <DatePicker placeholder="" format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24} s>
              <Form.Item
                label="Date de fin"
                name="fin"
                rules={[
                  {
                    required: true,
                    message:
                      "Veuillez remplir la date de fin de fermeture provisoire",
                  },
                ]}
              >
                <DatePicker placeholder="" format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        )}

        {globalData?.objetFermeture === "spetiality" && (
          <Form.Item
            label="Spétialité"
            name="spetialitéàfermer"
            // rules={[
            //   {
            //     required: true,
            //     message:
            //       "Veuillez choisir la spétialité que vous désirer fermer.",
            //   },
            // ]}
          >
            <Select>
           { spetalites?.map((e,index)=>(
            <Option value={e} index={index}>{e}</Option>
           ))}
           <Option>Informatique</Option>
            
            </Select>
          </Form.Item>
        )}

        <div style={{ textAlign: "center" }}>
          <Popconfirm
            placement="topLeft"
            okText="Oui"
            title="Vous êtes sûr de vouloir soumettre cette demande ?"
            onConfirm={() => {
              setStopModal(false);
              notification.success({
                message: "Votre demande a été ajouté avec succés",
                duration: 0,
              });
            }}
          >
            <Button
              htmlType="submit"
              type="primary"
              style={{
                backgroundColor: "#214082",
                borderColor: "#214082",
                marginRight: "10px",
                width: "7rem",
              }}
            >
              {t("Valider")}
            </Button>
          </Popconfirm>
          <Button
            style={{
              backgroundColor: "#E2E9F7",
              color: "#214082",
              border: "#9BACCF 1px solid",
              marginInline: "0.5rem",
              width: "7rem",
            }}
            onClick={() => setStopModal(false)}
          >
            {t("Annuler")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

CloseModal.propTypes = {
  stopModal: PropTypes.bool,
  setStopModal: PropTypes.func,
};

export default CloseModal;
