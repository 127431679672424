import { useEffect } from "react";
import Loader from "../../../layout/Loader";
import PropTypes from "prop-types";

const RefreshBarComponent = ({ question }) => {
    useEffect(() => {
      question.showRefreshBar = (isVisible) => {
        const refreshBar = document?.querySelector(`div[data-name="${question?.name}"] .refresh-bar`);
        if (refreshBar) {
           setTimeout(() => {
            refreshBar.style.display = "block"; // Show the refresh bar after the delay
          }, 6000);
        }
      };
      return () => {
        delete question.showRefreshBar;
      };
    }, [question]);
  
    return (
       <Loader />
    );
  };

  RefreshBarComponent.propTypes = {
    question: PropTypes.object,
  };
  export default RefreshBarComponent