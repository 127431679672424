import dayjs from "dayjs";

export function getDateParts(date) {
  const parsedDate = dayjs(date);
  return {
    day: parsedDate.date().toString(),
    month: (parsedDate.month() + 1).toString(), // month() returns 0-11, so we add 1
    year: parsedDate.year().toString(),
  };
}

export function generatePassword() {
  const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const specialChars = "*/@-#$";
  const allChars = letters + numbers + specialChars;

  let password = "";

  const getRandomIndex = (length) => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] % length;
  };

  password += letters[getRandomIndex(letters.length)];
  password += numbers[getRandomIndex(numbers.length)];
  password += specialChars[getRandomIndex(specialChars.length)];

  for (let i = 3; i < 12; i++) {
    password += allChars[getRandomIndex(allChars.length)];
  }

  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  return password;
}

export function modifyFileLinks(survey, options) {
  const questionElement = options?.htmlElement;
  const fileLinks = questionElement?.querySelectorAll("a");

  fileLinks?.forEach((link) => {
    link?.setAttribute("target", "_blank");
  });
}


export const downloadFile = (base64Content, fileName) => {
 
  const link = document?.createElement("a");
  link.href = `data:application/pdf;base64,${base64Content}`; 
  link.download = fileName; 
  document?.body?.appendChild(link);
  link?.click(); 
  document?.body?.removeChild(link);
};