import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Card,
  Table,
  Space,
  Spin,
  notification,
  Dropdown,
  Button,
  Modal,
} from "antd";
import { InfoCircleOutlined, DownOutlined } from "@ant-design/icons";
import SelectionModal from "../Etablissements/SelectionModal/SelectionModal";
import classes from "../Etablissements/Etablissements.module.css";
import { useTranslation } from "react-i18next";
import {
  getAffaires,
  getDocByIdAffaire,
  getSchema,
  updateActionAffaire,
} from "../../api/geoprod";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import CommentModal from "../Etablissements/CreateEpf/Modals/CommentModal";
import axiosInstance from "../../utils/axios";
import DownloadDocsModal from "../Etablissements/DownloadDocsModal";

const Demandes = () => {
  const { t, i18n } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setBrouillon, setMatriculeFiscale,setNbrBrouillon,setListTasks } = useContext(GlobalContext);
  const [commentaire, setCommentaire] = useState([]);
  const idAffaire = new URLSearchParams(window.location.search).get("id");
  const [openModal, setOpenModal] = useState([]);
  const navigate = useNavigate();
  const {
    setIsLoading,
    setSurveyJson,
    setSurveyData,
    setSurveyJsonValidationProvisoire,
    setShowingPreview,
  } = useLoader();
  const [loader, setLoader] = useState(false);
  const [docs, setDocs] = useState([]);
  const [openDocsModal, setOpenDocsModal] = useState(false);

  const idsEtatsDemandesInvisiblesInvestisseur = [
    parseInt(import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_CREATION_EPF),
    parseInt(
      import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_MODIFICATION_EPF
    ),
    parseInt(
      import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_CREATION_EPF_EXISTANT
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_PRE_DECISION_CREATION_EPF
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_DECISION_PROPOSEE_CREATION_EPF
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_PRE_DECISION_MODIFICATION_EPF
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_PRE_DECISION_CREATION_EPF_EXISTANT
    ),

    parseInt(import.meta.env.VITE_APP_ETAT_RESERVE_DR_REGIONAL_CREATION_EPF),
    parseInt(
      import.meta.env.VITE_APP_ETAT_RESERVE_DR_REGIONAL_MODIFICATION_EPF
    ),
    parseInt(
      import.meta.env.VITE_APP_ETAT_RESERVE_DR_REGIONAL_CREATION_EPF_EXISTANT
    ),
  ];
  const columns = [
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record?.establishment_data_commercial_name ||
        record?.establishment_data_official_name ||
        record?.legal_entity_official_name,
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        localStorage.getItem("language") === "ar" &&
        record?.establishment_director_data_last_name
          ? record?.establishment_director_data_last_name +
            " " +
            record?.establishment_director_data_first_name
          : record?.establishment_director_data_last_name_fr +
            " " +
            record?.establishment_director_data_first_name_fr,
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record?.establishment_data_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record?.establishment_data_delegation,
    },
    {
      title: t("Matricule fiscal"),
      key: "matricule",
      render: (_, record) =>
        record.establishment_data_tax_number ||
        record.legal_entity_tax_number ||
        record?.numero_matricule_fiscal_numero_IURNE ||
        record?.numero_matricule_fiscal_IURNE ||
        t("Non renseigné"),
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record?.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record?.establishment_data_training_type,
    },
    {
      title: t("Créateur de la demande"),
      key: "Créateur de la demande",
      render: (_, record) =>
        localStorage.getItem("language") === "ar" && record?.nom_createur_ar
          ? record.nom_createur_ar + " " + record?.prenom_createur_ar
          : record?.nom_createur + " " + record?.prenom_createur,
    },
    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return (
          <span
            style={{
              padding: "4px 10px",
              borderRadius: "5px",
              fontSize: "12px",
              backgroundColor: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#EEEEEE"
                : record?.etat_background,
              color: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#000000"
                : record?.etat_color,
            }}
          >
            {idsEtatsDemandesInvisiblesInvestisseur?.includes(
              record?.id_etat_dossier
            )
              ? t("En cours de traitement")
              : t(record?.etat)}
          </span>
        );
      },
    },
    {
      title: t("Type"),
      key: "statut",
      render: (_, record) => {
        return (
          <span
            style={{
              padding: "4px 10px",
              borderRadius: "5px",
              fontSize: "12px",
              backgroundColor: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#EEEEEE"
                : record?.etat_background,
              color: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#000000"
                : record?.etat_color,
            }}
          >
            {(record?.affaire_id_contrat ===
              import.meta.env
                .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO ||
              record?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO ||
              record?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO) &&
            record?.origin_contrat_id ===
              import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
              ? t(record.contrat_type.replace("EPF", "annexe"))
              : t(record.contrat_type)}
          </span>
        );
      },
    },
    {
      title: t("Date de soumission"),
      key: "Date de soumission",
      render: (_, record) =>
        record?.affaire_date_creation
          ? dayjs(record?.affaire_date_creation).format("DD/MM/YYYY HH:MM:ss")
          : t("Non renseigné"),
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => {
                        sessionStorage.setItem("consulter", true);
                        setIsLoading(true);
                        setShowingPreview(true);

                        axiosInstance
                          .get(
                            import.meta.env.VITE_APP_GEOPROD_API +
                              "/get_info_specifique/" +
                              record?.affaire_id,
                            {
                              headers: {
                                Idsession: JSON.parse(
                                  sessionStorage.getItem("-x-token-user")
                                )?.Acces_Token,
                              },
                            }
                          )
                          .then(async (res) => {
                            setIsLoading(false);
                            setSurveyJson(res?.data?.response?.form);

                            sessionStorage.setItem("demande", true);
                            const { form, data } = res?.data?.response;
                            const navigateTo = determineNavigationPath(
                              record,
                              form
                            );
                            if (
                              sessionStorage.getItem("role") === "directeur"
                            ) {
                              sessionStorage.setItem(
                                "user_id_to_get",
                                res?.data?.response?.responsable_EPF
                              );
                            } else {
                              sessionStorage.setItem(
                                "user_id_to_get",
                                res?.data?.response?.id_user
                              );
                            }
                            const type =
                              record?.origin_contrat_id ===
                              import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                                ? "annexe"
                                : "";
                            if (
                              navigateTo === "/etablissements/existante/save"
                            ) {
                              setMatriculeFiscale(
                                data?.numero_matricule_fiscal_IURNE
                              );

                              setSurveyData(res?.data);
                              navigate(navigateTo, {
                                state: {
                                  idAffaireEpfExistant: record.affaire_id,
                                },
                              });
                            } else if (navigateTo === "/etablissements/edit") {
                              setSurveyData(res?.data);
                              navigate(navigateTo, {
                                state: {
                                  type: type,
                                },
                              });
                            } else {
                              navigate(navigateTo, {
                                state: {
                                  id_affaire: record.affaire_id,
                                  etat_demande: record?.id_etat_dossier,
                                  contrat_id: res?.data?.response?.contrat_id,
                                },
                              });
                              setSurveyJsonValidationProvisoire(
                                res?.data?.response?.form_valid
                              );
                              setSurveyData(data);
                            }
                          })
                          .catch(() => {
                            setIsLoading(false);
                            notification.error({
                              message: t("Erreur"),
                              description:
                                "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                              duration: 0,
                            });
                          });
                      }}
                    >
                      {t("Consulter la demande")}
                    </Button>
                  ),
                },
                (record?.id_etat_dossier ===
                  parseInt(import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER) ||
                  record?.id_etat_dossier ===
                    parseInt(
                      import.meta.env
                        .VITE_APP_ETAT_COMPLEMENT_DOSSIER_MODIFICATION
                    ) ||
                  record?.id_etat_dossier ===
                    parseInt(
                      import.meta.env
                        .VITE_APP_ETAT_COMPLEMENT_DOSSIER_EPF_EXISTANT
                    )) &&
                  sessionStorage.getItem("role") !== "directeur" && {
                    label: (
                      <Button
                        block
                        size="small"
                        onClick={async () => {
                          setLoader(true);
                          const data = await getSchema(record.affaire_id);
                          setShowingPreview(false);
                          navigate("/complement/dossier", {
                            state: {
                              id_affaire: record?.affaire_id,
                              data_complement_dossier:
                                data?.response?.complement_dossier,
                              complement_dossier_uploaded:
                                data?.response?.key_urls,
                              affaire_id_contrat: record?.affaire_id_contrat,
                            },
                          });
                          // }
                          setLoader(false);
                        }}
                      >
                        {t("Compléter la demande")}
                      </Button>
                    ),
                  },
                (record?.id_etat_dossier ===
                  parseInt(
                    import.meta.env.VITE_APP_ETAT_VALIDATION_PROVISOIRE
                  ) ||
                  record?.id_etat_dossier ===
                    parseInt(
                      import.meta.env
                        .VITE_APP_ETAT_COMPLEMENT_VALIDATION_PROVISOIRE
                    ) ||
                  record?.id_etat_dossier ===
                    parseInt(
                      import.meta.env
                        .VITE_APP_ETAT_VALIDATION_PROVISOIRE_PREDECISION
                    )) &&
                  sessionStorage.getItem("role") !== "directeur" && {
                    label: (
                      <Button
                        size="small"
                        block
                        onClick={async () => {
                          setShowingPreview(false);
                          sessionStorage.setItem("consulter", false);
                          setIsLoading(true);
                          const data = await getSchema(record.affaire_id);
                          setSurveyJson(data?.response?.form_valid);

                          await setSurveyData(data?.response?.data);
                          setSurveyJsonValidationProvisoire(
                            data?.response?.form_valid
                          );

                          navigate("/complement/validation/provisoire", {
                            state: {
                              id_affaire: record?.affaire_id,
                              etat_demande: record?.id_etat_dossier,
                              //hello
                              contrat_id: data?.response?.contrat_id,
                            },
                          });
                          setIsLoading(false);
                        }}
                      >
                        {t("Étape de l'obtention de la validation finale")}
                      </Button>
                    ),
                  },
                record?.affaire_id_contrat !==
                  import.meta.env
                    .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO &&
                  record?.affaire_id_contrat !==
                    import.meta.env
                      .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO &&
                  record?.affaire_id_contrat !==
                    import.meta.env
                      .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO && {
                    label: (
                      // <Popconfirm
                      //   title="Télécharger les documents "
                      //   description="Êtes-vous sûr de pouvoir télécharger les documents ?"
                      //   onConfirm={async () => {
                      //     setLoader(true);
                      //     try {
                      //       const data = await getDocByIdAffaire(
                      //         record?.affaire_id
                      //       );
                      //       const documents = data?.data?.find(
                      //         (doc) =>
                      //           doc?.md5_id ===
                      //           import.meta.env.VITE_APP_ID_DOCUMENTS_RECU_EMAIL
                      //       );
                      //       if (documents?.files?.length) {
                      //         await Promise.all(
                      //           documents?.files.map((file) =>
                      //             downloadFile(file?.url_base64, file?.name)
                      //           )
                      //         );
                      //       }
                      //     } catch (error) {
                      //       console.error(
                      //         "Erreur lors du téléchargement :",
                      //         error
                      //       );
                      //     } finally {
                      //       setLoader(false);
                      //     }
                      //   }}
                      //   onCancel={() => null}
                      //   okText="Oui"
                      //   cancelText="Non"
                      // >
                      <Button
                        block
                        size="small"
                        disabled={
                          record?.id_etat_dossier ===
                            parseInt(
                              import.meta.env.VITE_APP_ETAT_DEMANDE_REFUSEE
                            ) ||
                          record?.id_etat_dossier ===
                            parseInt(
                              import.meta.env.VITE_APP_ETAT_VALIDATION_FINALE
                            ) ||
                          record?.id_etat_dossier_md5 ===
                            import.meta.env
                              .VITE_APP_ID_STATUS_ANNULE_DEMANDE_CREATION_EPF ||
                          record?.id_etat_dossier_md5 ===
                            import.meta.env
                              .VITE_APP_ID_STATUS_ANNULE_DEAMNDE_MODIFICATION_EPF ||
                          record?.id_etat_dossier_md5 ===
                            import.meta.env
                              .VITE_APP_ID_STATUS_ANNULE_DEAMNDE_CREATION_EPF_EXISTANT ||
                          record?.id_etat_dossier_md5 ===
                            import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF_EXIST
                        }
                        onClick={async () => {
                          setLoader(true);
                          try {
                            const data = await getDocByIdAffaire(
                              record?.affaire_id
                            );
                            const documents = data?.data?.find(
                              (doc) =>
                                doc?.md5_id ===
                                import.meta.env.VITE_APP_ID_DOCUMENTS_RECU_EMAIL
                            );

                            setDocs([documents]);
                            setOpenDocsModal(true);
                            // if (documents?.files?.length) {
                            //   await Promise.all(
                            //     documents?.files.map((file) =>
                            //       downloadFile(file?.url_base64, file?.name)
                            //     )
                            //   );
                            // }
                            // } else {
                            //   setLoader(false);
                            // }
                          } catch (error) {
                            console.error(
                              "Erreur lors du téléchargement :",
                              error
                            );
                          } finally {
                            setLoader(false);
                          }
                        }}
                      >
                        {t("Télécharger les documents ")}
                      </Button>
                      // </Popconfirm>
                    ),
                    // children: documents?.files?.map((file, index) => ({
                    //     label: (
                    //       <a href={file?.url_base64} download={file?.name}>
                    //         {file?.name}
                    //       </a>
                    //     ),
                    //   })),
                  },
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => handleGetComment(record?.affaire_id)}
                    >
                      {t("Commentaires")}
                    </Button>
                  ),
                },
                record?.createur_id_md5 ===
                  JSON.parse(sessionStorage.getItem("-x-token-user"))
                    ?.id_user && {
                  label: (
                    <Button
                      size="small"
                      block
                      disabled={
                        record?.id_etat_dossier_md5 ===
                          import.meta.env
                            .VITE_APP_ID_STATUS_ANNULE_DEMANDE_CREATION_EPF ||
                        record?.id_etat_dossier_md5 ===
                          import.meta.env
                            .VITE_APP_ID_STATUS_ANNULE_DEAMNDE_MODIFICATION_EPF ||
                        record?.id_etat_dossier_md5 ===
                          import.meta.env
                            .VITE_APP_ID_STATUS_ANNULE_DEAMNDE_CREATION_EPF_EXISTANT
                      }
                      onClick={() => {
                        const statusAutorisésPourLannulation = [
                          import.meta.env
                            .VITE_APP_ID_STATUS_SOUMISE_AVEC_VALIDATION,
                          import.meta.env
                            .VITE_APP_ID_STATUS_EPF_EXISTANT_SOOUMISE,
                          import.meta.env.VITE_APP_ID_STATUS_SOUMISE_EPF,
                        ];
                        if (
                          !statusAutorisésPourLannulation.includes(
                            record?.id_etat_dossier_md5
                          )
                        ) {
                          notification.error({
                            message: t("Erreur"),
                            description: t(
                              "Votre demande est déjà en cours de traitement et ne peut plus être annulée à ce stade."
                            ),
                            duration: 0,
                          });
                        } else {
                          setOpenModal([
                            {
                              id_affaire: record?.affaire_id,
                              affaire_id_contrat: record?.affaire_id_contrat,
                            },
                          ]);
                        }
                      }}
                    >
                      {t("Annuler")}
                    </Button>
                  ),
                },
              ],
            }}
            trigger={["click"]}
          >
            <span style={{ cursor: "pointer" }}>
              <Space>
                {t("Liste")}
                <DownOutlined />
              </Space>
            </span>
          </Dropdown>
        </Space>
      ),
    },
  ];
  const determineSelectedOption = (affaire_id_contrat) => {
    switch (affaire_id_contrat) {
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
        return "avec_bo_validation";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        return "sans_bo_sans_validation";
      default:
        return "sans_bo_avec_validation";
    }
  };
  const determineNavigationPath = (record, form) => {
    const { affaire_id, affaire_id_contrat } = record;
    const { pages } = form;

    switch (affaire_id_contrat) {
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE:
        sessionStorage.setItem("id_affaire_annexe", affaire_id);
        return "/annexes/save?affaire_id=" + record?.affaire_parent;
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE:
        sessionStorage.setItem("idAffaireEpfExistant", affaire_id);
        return "/etablissements/existante/save";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        const pageChangementInvestisseur = pages?.find(
          (item) => item?.name === "ChnangementInvestisseur"
        );
        if (pageChangementInvestisseur) {
          sessionStorage.setItem("idAffaireChangementInvestisseur", affaire_id);
          setSurveyJson(pageChangementInvestisseur);
          return "/investisseur/changer";
        } else {
          const selectedOption = determineSelectedOption(affaire_id_contrat);
          sessionStorage.setItem("selectedOption", selectedOption);
          return `/etablissements/edit`;
        }
      default:
        return "/etablissements/save";
    }
  };
  async function fetchData() {
    try {
      const data = await getAffaires({
        id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
        id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user")).user
          .entreprise_id,
        filters: {
          contrat: [],
          status: [],
          excludedStatus: [],
        },
      });
      setDataSource(
        data?.aaData?.filter(
          (elem) =>
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_VALIDER_EPF_EXIST &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env
                .VITE_APP_STATUS_VALIDEE_MODIFICATION_AVEC_VALIDATION &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_ANNULE_DEMANDE_CREATION_EPF &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env
                .VITE_APP_ID_STATUS_ANNULE_DEAMNDE_MODIFICATION_EPF &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env
                .VITE_APP_ID_STATUS_ANNULE_DEAMNDE_CREATION_EPF_EXISTANT &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_REFUSEE_EPF &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_REFUSEE_MODIFICATION &&
            elem?.id_etat_dossier_md5 !==
              import.meta.env.VITE_APP_ID_STATUS_REFUSEE_EPF_EXISTANT 
        )
      );
      let brouillon = data?.aaData.filter(
        (elem) =>
          elem.etat === "Brouillon" &&
          elem.affaire_id_contrat === import.meta.env.VITE_ID_LIGNE_PRODUIT
      );
      const brouillinAnnexe = data?.aaData.filter(
        (elem) =>
          elem.etat === "Brouillon" &&
          elem.affaire_id_contrat ===
            import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
      );
      if (brouillinAnnexe.length > 0) {
        sessionStorage.setItem(
          "id_affaire_annexe",
          brouillinAnnexe[0]?.affaire_id
        );
        setBrouillon(brouillinAnnexe);
        setLoading(false);
      }
      if (brouillon.length > 0) {
        sessionStorage.setItem("id_affaire", brouillon[0]?.affaire_id);
        setBrouillon(brouillon);
        setLoading(false);
      }
      setIsLoading(false);

      setLoading(false);
    } catch (error) {
      setIsLoading(false);

      notification.error({
        message: t("Erreur"),
        description:
          error?.response?.data?.message ||
          "Une interruption est survenue lors de la récupération des données. Veuillez réessayer.",
      });
    }
  }
  useEffect(() => {
    fetchData();

    axiosInstance
      .post(
        `${import.meta.env.VITE_APP_GEOPROD_API}/nbr_tache_by_user`,
        {
          status: [
            import.meta.env.VITE_APP_ETAT_VALIDATION_PROVISOIRE,
            import.meta.env.VITE_APP_ETAT_COMPLEMENT_VALIDATION_PROVISOIRE,
            import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER,
            import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_EPF_EXISTANT,
            import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_MODIFICATION,
          ],
          brouillon:[
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT,
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON
          ]
        },
        {
          headers: {
            Idsession: JSON.parse(
              sessionStorage.getItem("-x-token-user")
            )?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setNbrBrouillon(res?.data?.nombre_brouillon)
        setListTasks(res?.data?.nombre_taches);

      });

    if (idAffaire) {
      handleGetComment(idAffaire);
    }
  }, []);

  const handleGetComment = (id_affaire) => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.info?.length > 0) {
          setCommentaire(res?.data?.info);
        } else {
          notification.warning({
            message: t(
              "Vous ne disposez pas de commentaire pour cette demande !"
            ),
            duration: 0,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const AnnulerDemande = async () => {
    setIsLoading(true);
    setOpenModal([]);

    const id_affaire = openModal[0].id_affaire;
    const affaire_id_contrat = openModal[0].affaire_id_contrat;
    const data = await updateActionAffaire({
      id_affaire: id_affaire,
      id_status:
        affaire_id_contrat ===
          import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO ||
        affaire_id_contrat ===
          import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO
          ? import.meta.env.VITE_APP_ID_STATUS_ANNULE_DEAMNDE_MODIFICATION_EPF
          : affaire_id_contrat ===
            import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
          ? import.meta.env
              .VITE_APP_ID_STATUS_ANNULE_DEAMNDE_CREATION_EPF_EXISTANT
          : import.meta.env.VITE_APP_ID_STATUS_ANNULE_DEMANDE_CREATION_EPF,
    });
    if (data) {
      notification.success({
        message: t("Votre demande a bien été annulée."),
        duration: 0,
      });
      navigate("/historique");
    } else {
      setOpenModal([]);
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={loader} tip="" style={{ width: "100%" }}>
      <div>
        <Modal
          open={openModal.length > 0}
          footer={null}
          width={600}
          onCancel={() => setOpenModal([])}
        >
          <h4>
            {" "}
            {t(
              "Êtes-vous sûr de vouloir annuler cette demande ? Cette action est irréversible."
            )}{" "}
          </h4>
          <Button type="primary" onClick={() => AnnulerDemande()}>
            {t("Oui")}
          </Button>
          <Button
            type="primary"
            style={{ marginInline: "15px" }}
            onClick={() => setOpenModal([])}
          >
            {" "}
            {t("Non")}
          </Button>
        </Modal>
        <SelectionModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        <CommentModal
          commentaire={commentaire}
          setCommentaire={setCommentaire}
        />
        <DownloadDocsModal
          docs={docs}
          open={openDocsModal}
          setOpen={setOpenDocsModal}
        />

        <Row className={classes.listeRow}>
          <Card
            className={classes.listeCard}
            bordered={false}
            title={
              <div>
                <div
                  style={{
                    textAlign: i18n?.dir() === "ltr" ? "left" : "right",
                  }}
                >
                  {t("Liste de mes demandes en cours")}
                </div>
                {dataSource?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                  >
                    <InfoCircleOutlined
                      style={{ color: "#1890ff", marginInline: "8px" }}
                    />
                    <span>
                      {t(
                        "Vous pouvez consulter les détails de la demande, télécharger les documents associés, et visualiser les commentaires en utilisant la liste des actions disponible devant chaque demande."
                      )}
                    </span>
                  </div>
                )}
              </div>
            }
          >
            <Table
              locale={{ emptyText: t("Aucune donnée") }}
              loading={loading}
              columns={columns}
              dataSource={dataSource}
              className="ant-border-space"
              style={{ overflowX: "auto" }}
              pagination={true}
            />
          </Card>
        </Row>
      </div>
    </Spin>
  );
};

export default Demandes;
