import { notification } from "antd";
import dayjs from "dayjs";
import axiosInstance from "../utils/axios";
import axios from "axios";
import { generatePassword } from "../utils";

export const signUpEhouwiya = async (payload) => {
  try {
    const { data } = await axiosInstance.post(
      import.meta.env.VITE_APP_GEOPROD_API + "/admin/create_user",
      payload
    );

    return data;
  } catch (error) {
    return { success: false, error };
    // throw error;
  }
};
export const signUp = async (payload) => {
  try {
    const { data } = await axiosInstance.post(
      import.meta.env.VITE_APP_GEOPROD_API + "/admin/create_user",
      payload
    );

    return data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const login = async (email, password, apiKey, idRole) => {
  try {
    const auth = btoa(`${email}:${password}`);
    const config = {
      headers: {
        Authorization: apiKey ? `Bearer ${apiKey}` : `Basic ${auth}`,
      },
    };
    const { data } = await axios.post(
      import.meta.env.VITE_APP_GEOPROD_API + "/login",
      {
        role: idRole,
      },
      config
    );

    return data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const addAffaire = async (payload) => {
  const { data } = await axiosInstance.post(
    `${import.meta.env.VITE_APP_GEOPROD_API}/add_affaire`,
    payload
  );
  return data;
};

export const addRelationAffaire = async (payload) => {
  const { data } = await axiosInstance.put(
    `${import.meta.env.VITE_APP_GEOPROD_API}/affaire/add_relation`,
    payload,
    {
      headers: {
        Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
          ?.Acces_Token,
      },
    }
  );
  return data;
};

const getIdAffaire = (typeAffaire, selectedOption) => {
  switch (typeAffaire) {
    case "editProfile":
      return sessionStorage.getItem("id_affaire_profile");
    case "changementInvestisseur":
      return sessionStorage.getItem("idAffaireChangementInvestisseur");
    case "epfExistante":
      return sessionStorage.getItem("idAffaireEpfExistant");
    case "edit":
      return sessionStorage.getItem(`id_affaire_edit_${selectedOption}`);
    case "annexe":
      return sessionStorage.getItem("id_affaire_annexe");
    default:
      return sessionStorage.getItem("id_affaire");
  }
};

const updateStatus = async (id_affaire, typeAffaire, selectedOption) => {
  const idStatus =
    typeAffaire === "epfExistante"
      ? import.meta.env.VITE_APP_ID_STATUS_EPF_EXISTANT_SOOUMISE
      : typeAffaire === "changementInvestisseur"
      ? import.meta.env.VITE_APP_ID_STATUS_SOUMISE_AVEC_VALIDATION
      : selectedOption === "sans_bo_sans_validation"
      ? import.meta.env.VITE_APP_ID_STATUS_SOUMISE_SANS_VALIDATION
      : import.meta.env.VITE_APP_ID_STATUS_SOUMISE_AVEC_VALIDATION;

  await updateActionAffaire({ id_affaire, id_status: idStatus });
};

const handleSoumission = async (id_affaire, typeAffaire, selectedOption) => {
  if (typeAffaire !== "annexe") {
    await axiosInstance.post(
      `${import.meta.env.VITE_APP_GEOPROD_API}/print_document?id_affaire=${id_affaire}`,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))?.Acces_Token,
        },
      }
    );
    await updateStatus(id_affaire, typeAffaire, selectedOption);
  } else {
    await updateActionAffaire({
      id_affaire,
      id_status: import.meta.env.VITE_APP_ID_STATUS_SOUMISE_EPF,
    });
  }
};

export const saveAffaire = async (payload, type, setIsLoading, typeAffaire) => {
  try {
    const selectedOption = sessionStorage.getItem("selectedOption");
    const id_affaire = getIdAffaire(typeAffaire, selectedOption);

    const data = await updateAffaire(id_affaire, payload, type);
    if (!data) {
      notification.error({
        description: "Enregistrement échoué, merci de réessayer.",
      });
      return;
    }

    if (type === "soumission") {
      await handleSoumission(id_affaire, typeAffaire, selectedOption);
    } else if (type === "SoumettreModification") {
      await updateStatus(id_affaire, typeAffaire, selectedOption);
    }

    setIsLoading(false);
    return data;
  } catch (error) {
    throw new Error("Enregistrement échoué, merci de réessayer");
  }
};

export const getSchema = async (params) => {
  try {
    const { data } = await axiosInstance.get(
      import.meta.env.VITE_APP_GEOPROD_API + "/get_info_specifique/" + params,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getAffaires = async (params) => {
  try {
    const payloadParams = {
      page: 1,
      limit: 30,
      iSortCol_0: 2,
      sSortDir_0: "desc",
      sSearch: "",
      is_investisseur: true,
      all_filtres: {
        aff: {
          utilisateur: {
            entreprise_call: params.id_entreprise,
            recheche_create_user: [params.id_user],
            recheche_zone: [],
            operateur_recherche: "",
            recherche_groupe: [],
            service: [],
          },
          prospect: {
            prospect_nom_prenom: "",
            prospect_tel: "",
            prospect_mail: "",
            prospect_ville: "",
            RS: "",
            prospect_zone: [],
            tags: [],
            tags_no: [],
            mail_contient: "",
            name: "",
            surname: "",
          },
          affaire: {
            // contrat: [...params.filters.contrat],
            contrat: [],
            cmp: [],
            operateur_cmp: "=",
            operateur_gamme: "=",
            gamme: [],
            operateur_produit: "=",
            produit: [],
            recheche_num_contrat: "",
            pan_ttc_min: "",
            pan_ttc_max: "",
            pose_du_creation: "2024-08-20",
            pose_au_creation: dayjs().format("YYYY-MM-DD"),
            pose_du_souscription: null,
            pose_au_souscription: null,
            date_effet_dossier_du: null,
            date_effet_dossier_au: null,
            operateur_contrat: "=",
            classe: [],
            operateur_recherche_tag: "=",
            tags_aff: [],
            tags_aff_not: [],
            res_env: "",
            pose_du_date_envoi_res: null,
            pose_au_date_envoi_res: null,
            affaire_occ_res: [],
            type_date: "Creation",
          },
          contrat_actuel: {
            deja_assure: "",
            an_cmp: [],
            operateur_pan_ttc: "",
            PAN_TTC: "",
            date_ech: null,
          },
          statut_actuel: {
            status: [...params.filters.status],
            excludedStatus: [...params.filters.excludedStatus],
          },
          qualite: {
            data: {},
          },
          action_user: {
            dernier_action: "",
            date_creation_action: null,
            date_fin_action: null,
            list_user: [],
            enreg_simple: "",
            enreg_avec_action: "",
            status_action: [],
            categorie: [],
          },
        },
        opp: {
          filtre_opp: {
            campagne: [],
            date_debut_creation: null,
            date_fin_creation: null,
            date_debut_rdv: null,
            date_fin_rdv: null,
            cycle_de_vie: [],
            tags_opp: [],
          },
          utilisateur: {
            organismes: [],
            list_createurs: [],
            list_affectation: [],
            groupes_affecte: [],
            groupes_createurs: [],
            zones: [],
            services: [],
            operateur_recherche_group: "",
            operateur_recherche_group_affet: "",
          },
          action_user: {
            dernier_action: "",
            date_creation_action: null,
            date_fin_action: null,
            list_user: [],
            groupes: [],
            enreg_simple: "",
            status_action: [],
            categorie: [],
            operateur: "",
            enreg_avec_action: "",
          },
          rdv: {
            etat_rdv: [],
          },
          telephonie: {
            argumentes_tout: "",
            operateur_telephonie: "",
            value_telephonie: "",
            operateur_duree: "",
            value_duree: "",
          },
        },
      },
      historique: params?.historique,
    };

    const { data } = await axiosInstance.post(
      import.meta.env.VITE_APP_GEOPROD_API + "/list_affaires",
      payloadParams,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const updateActionAffaire = async (params) => {
  try {
    const payload = {
      commentaire_action: "",
      id_affaire: params.id_affaire,
      id_motif: "",
      id_statut: params?.id_status || "170c944978496731ba71f34c25826a34",
    };

    const { data } = await axiosInstance.put(
      import.meta.env.VITE_APP_GEOPROD_API + "/update_action_affaire",
      payload,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );

    return data;
  } catch (error) {}
};

export const getTrainerByNationalityAndIdentity = async (params) => {
  try {
    let queryStringParams = `?nationality=${params.nationality}`;

    if (params.cin) {
      queryStringParams += `&cin=${params.cin}`;
    }
    
    if (params.passport) {
      queryStringParams += `&passport=${params.passport}`;
    }
    

    const { data } = await axiosInstance.get(
      import.meta.env.VITE_APP_GEOPROD_API +
        "/admin/search_user_by_nationality_cin_passport" +
        queryStringParams,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );

    return data;
  } catch (error) {}
};

export const searchByFiscalAndGoverment = async (matricule, goverment) => {
  try {
    const { data } = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/mefp/search_by_fiscal_and_government?i_fiscal=${matricule}&government=${goverment}`
    );

    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getNotifictaions = async () => {
  try {
    const response = await axiosInstance.get(
      import.meta.env.VITE_APP_GEOPROD_API + "/get_notifications",
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const uploadDocumentAffaire = async (formData) => {
  try {
    const response = await axiosInstance.post(
      `${import.meta.env.VITE_APP_GEOPROD_API}/v0/upload_document_affaire`,
      formData,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateAffaire = async (id_affaire, payload, type) => {
  if (type === "soumission") {
    return true;
  }
  try {
    const response = await axiosInstance.put(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/update_info_specifique?id_affaire=${id_affaire}`,
      payload,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const updateAffaireAnnexe = async (payload) => {
  try {
    const response = await axiosInstance.put(
      `${import.meta.env.VITE_APP_GEOPROD_API}/affaire/add_relation`,
      payload,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const updateInfoProfile = async (payload, id_user) => {
  try {
    const response = await axiosInstance.put(
      `${import.meta.env.VITE_APP_GEOPROD_API}/admin/update_user/${id_user}`,
      payload,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getListFormateursByUser = async () => {
  try {
    const id_user = JSON.parse(sessionStorage.getItem("-x-token-user")).id_user;
    const response = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/config/list_users_by_role/trainer/${id_user}`,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getDocByIdAffaire = async (id_affaire) => {
  try {
    const response = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/v0/load_list_document_affaire/${id_affaire}`,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const saveTicket = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${import.meta.env.VITE_APP_GEOPROD_API}/add_ticket${
        payload.choix ? "?choix=" + payload.choix : ""
      }`,
      {
        commentaire: payload.commentaire,
        statut: payload.statut,
        title: payload.title,
      },
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getTicketStatus = async () => {
  try {
    const response = await axiosInstance.get(
      `${import.meta.env.VITE_APP_GEOPROD_API}/get_all_status_tickets`,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getTickets = async () => {
  try {
    const user = JSON.parse(sessionStorage.getItem("-x-token-user"));

    const response = await axiosInstance.post(
      `${import.meta.env.VITE_APP_GEOPROD_API}/list_tickets`,
      {
        filtre_ticket: {
          utilisateur: {
            recheche_create_user: [user.id_user],
            recheche_collab: [],
          },
          prospect: {
            recheche_nom_prenom: "",
          },
          ticket: {
            recheche_num_ticket: "",
            recheche_sujet_ticket: "",
            date_debut_creation: "2024-12-29",
            date_fin_creation: "2029-01-29",
            date_debut_traitement: "",
            date_fin_traitement: "",
            date_debut_limit: "",
            date_fin_limit: "",
            recheche_status: [],
            categorie: [],
          },
        },
        page: 1,
        items_per_page: 100,
        sSortDir_0: "desc",
        sSearch: "",
        iSortCol_0: 0,
      },
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getTicketById = async (id_ticket) => {
  try {
    const response = await axiosInstance.get(
      `${import.meta.env.VITE_APP_GEOPROD_API}/commentaire/get/${id_ticket}/T`,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );

    return response;
  } catch (error) {
    return error.response;
  }
};

export const addCommentToTicket = async (id_ticket, commentaire) => {
  try {
    const response = await axiosInstance.post(
      `${import.meta.env.VITE_APP_GEOPROD_API}/commentaire/add`,
      {
        entity: "T",
        id_entity: id_ticket,
        commentaire: commentaire,
        visible_investisseur: 1,
      },
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );

    return response;
  } catch (error) {
    return error.response;
  }
};

export const getTicketInfo = async (id_ticket) => {
  try {
    const response = await axiosInstance.get(
      `${import.meta.env.VITE_APP_GEOPROD_API}/get_info_ticket/${id_ticket}`,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );

    return response;
  } catch (error) {
    return error.response;
  }
};

export const sendVerificationCode = async (email) => {
  try {
    const response = await axiosInstance.post(
      `${import.meta.env.VITE_APP_GEOPROD_API}/admin/generation_OTP`,
      {
        mail: email,
      },
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );

    return response;
  } catch (error) {
    return error.response;
  }
};

export const verifyCode = async (code) => {
  try {
    const response = await axiosInstance.post(
      `${import.meta.env.VITE_APP_GEOPROD_API}/admin/check_OTP`,
      {
        OTP: code,
      },
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );

    return response;
  } catch (error) {
    return error.response;
  }
};

export const changeDirectorEmail = async (id, affaire_id, email) => {
  try {
    const pwd = generatePassword();
    const response = await axiosInstance.post(
      `${import.meta.env.VITE_APP_GEOPROD_API}/change_mail_directeur`,
      {
        id_directeur: id,
        mail: email,
        id_affaire: affaire_id,
        password: pwd,
      },
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );

    return response;
  } catch (error) {
    return error?.response;
  }
};

export const getListDirecteursByUser = async () => {
  try {
    const response = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/admin/get_directeurs_by_investisseur`,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
