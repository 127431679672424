import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import {
  Button,
  Card,
  Dropdown,
  notification,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import classes from "../Etablissements/Etablissements.module.css";
import { useLoader } from "../../context/LoaderContext";
import { useNavigate } from "react-router-dom";

const ListFomrations = () => {
  const [listFomrations, setListFormations] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const { setIsLoading, setSurveyJson, setSurveyData } = useLoader();
  const columns = [
    {
      title: t("Numéro d'enregistrement"),
      key: "Numéro d'enregistrement",
      render: (_, record) => record?.registration_number,
    },
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) => record?.nom_etablissement,
    },
    {
      title: t("Nature de formation"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        record?.training_nature_data?.training_type_data_nature,
    },
    {
      title: t("Exigence d’homologation"),
      key: "gouvernorat",
      render: (_, record) =>
        record?.training_nature_data?.training_type_data_type &&
        record?.training_nature_data?.training_type_data_nature === "أساسي"
          ? record?.training_nature_data?.training_type_data_type
          : t("غير منظرة"),
    },
      {
        title: t("Spécialité ou Domaine"),
        key: "natureForm",
        render: (_, record) =>
          record?.training_nature_data?.training_type_data_specialty ||
        record?.training_nature_data?.training_type_data_domain ||
          t("Non renseigné"),
      },
      // {
      //   title: t("Domaine"),
      //   key: "Domaine",
      //   render: (_, record) =>
      //     record?.training_nature_data?.training_type_data_domain ||
      //     t("Non renseigné"),
      // },
    {
      title: t("Date de début"),
      key: "datedebut",
      render: (_, record) => record?.date_debut_formation || t("Non renseigné"),
    },
    {
      title: t("Date de fin"),
      key: "datefin",
      render: (_, record) => record?.date_fin_formation || t("Non renseigné"),
    },

    {
      title: t("État"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => 
  
      (
        <Tag color= {record.statut_formation === 0? "#FF808B" : "#FFB300"}>
          {record.statut_formation === 0 ? t("supprimée") : t("Inactive")}{" "}
        </Tag>
      ),
    },

    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      onClick={() => ConsultFormationDetails(record)}
                      size="small"
                      block
                    >
                      {t("Consulter")}
                    </Button>
                  ),
                },
                record?.training_nature_data?.training_type_data_type ===
                  "منظرة" && {
                  label: (
                    <Button disabled size="small" block>
                      {t("Homolguer")}
                    </Button>
                  ),
                },
              ],
            }}
            trigger={["click"]}
          >
            <span style={{cursor:"pointer"}}>
              <Space>
                {t("Liste")}
                <DownOutlined />
              </Space>
            </span>
          </Dropdown>
        </Space>
      ),
    },
  ];
  const navigate = useNavigate();

  const ConsultFormationDetails = (record) => {
    setIsLoading(true);

    axiosInstance
      .get(
        `${import.meta.env.VITE_APP_GEOPROD_API}/admin-bpm/contrat/${
          import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO
        }`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        let form = res?.data?.contrat?.form;
        form.pages = form?.pages.filter((page) => page.name === "page4");
        setSurveyJson(form);
        setSurveyData(record);
        navigate("/formations/details");
      });
  };

  useEffect(() => {
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/admin/get_formations_by_investisseur`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        res?.data?.message?.forEach((item) => {
          // item.training_nature_data.forEach((e) => {
          setListFormations((prevList) => [
            ...prevList,
            {
              training_nature_data: item.training_nature_data,
              nom_etablissement: item.nom_etablissement,
              registration_number: item.registration_number,
              date_debut_formation: item?.date_debut_formation,
              date_fin_formation: item?.date_fin_formation,
              statut_formation:item?.statut_formation
            },
          ]);
          // });
        });
      })
      .catch((res) => {
        setLoading(false);
      });
  }, []);
  return (
    <Row className={classes.listeRow}>
      <Card
        className={classes.listeCard}
        bordered={false}
        title={
          <div>
            <div
              style={{
                textAlign: i18n?.language === "fr" ? "left" : "right",
              }}
            >
              {t("Liste des formations")}
            </div>
          </div>
        }
      >
        <Table
          locale={{ emptyText: t("Aucune donnée") }}
          loading={loading}
          columns={columns}
          dataSource={listFomrations}
          className="ant-border-space"
          style={{ overflowX: "auto" }}
          pagination={false}
        />
      </Card>
    </Row>
  );
};

export default ListFomrations;
