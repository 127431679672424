import { useContext, useEffect } from "react";
import { useLoader } from "../../context/LoaderContext";
import { Model, CustomWidgetCollection, surveyLocalization } from "survey-core";
import { Survey } from "survey-react-ui";
import GlobalContext from "../../context/GlobalContext";
import classes from "../Etablissements/CreateEpf/CreateEpf.module.css";

import {
  fetchCodePostalList,
  fetchDelegationList,
} from "../../context/FetchLists";
import dayjs from "dayjs";

import { Button, ConfigProvider, Space } from "antd";
import DatePikckerComponent from "../Etablissements/CreateEpf/DatePIkcer";
import ButtonComponent from "../Etablissements/CreateEpf/ButtonComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { BookUser } from "lucide-react";
import { useTranslation } from "react-i18next";

surveyLocalization.defaultLocale = "fr";
let surveyProfile = new Model();

const ProfileDirecteur = () => {
  const { getSurveyJson } = useLoader();
  const params = useLocation();
  const info = params?.state?.info;
  const { gouvernoratList, nationalityList, listAllDelegations } =
    useContext(GlobalContext);
  const navigate = useNavigate();
const {t}=useTranslation()
  const displayQuestionsFunction = () => {
    surveyProfile.getAllQuestions().forEach(async (question) => {
      const page = surveyProfile.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (page) {
        page.questions.forEach((question) => {
          question.readOnly = true;
          if (question.name === "director_search") {
            question.visible = false;
          }
          if (
            question.name !== "establishment_director_data_test_valid" &&
            question.name !== "establishment_director_data_passeport_copie" &&
            question.name !== "establishment_director_data_cin_copie" &&
            question.name !== "establishment_director_data_date_delivrance" &&
            question.name !==
              "establishment_director_data_ministerial_auth_date" &&
            question.name !== "establishment_director_data_passeport" &&
            question.name !== "establishment_director_data_cin_number" &&
            question.name !== "establishment_director_data_spinner" &&
            question.name !==
              "establishment_director_date_expiration_passeport" &&
            question.name !== "director_search" &&
            question.name !== "copie_autorisation_ministre"
          ) {
            question.visible = true;
          }
          if (
            surveyProfile.getQuestionByName(
              "establishment_director_data_nationality"
            ).value === "تونسية"
          ) {
            if (
              question.name === "establishment_director_data_cin_copie" ||
              question.name === "establishment_director_data_date_delivrance"
            ) {
              question.visible = true;
            }
          } else if (
            surveyProfile.getQuestionByName(
              "establishment_director_data_nationality"
            ).value !== "تونسية"
          ) {
            if (
              question.name === "establishment_director_data_passeport_copie" ||
              question.name ===
                "establishment_director_data_ministerial_auth_date" ||
              question.name ===
                "establishment_director_date_expiration_passeport" ||
              question.name === "copie_autorisation_ministre"
            ) {
              question.visible = true;
            }
          }
        });
      }
    });
  };

  const parseDate = (dateStr) => {
    if (dayjs(dateStr, "YYYY-MM-DD", true).isValid()) {
      return dayjs(dateStr).format("YYYY-MM-DD");
    }
    if (dayjs(dateStr, "YYYY/MM/DD", true).isValid()) {
      return dayjs(dateStr, "YYYY/MM/DD").format("YYYY-MM-DD");
    }
    if (dayjs(dateStr, "DD/MM/YYYY", true).isValid()) {
      return dayjs(dateStr, "DD/MM/YYYY").format("YYYY-MM-DD");
    }
  };

  const setUserData = (userData) => {
    const fieldsToSet = [
      {
        surveyField: "establishment_director_data_governorate",
        userDataField: "establishment_director_data_governorate",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_delegation",
        userDataField: "establishment_director_data_delegation",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_passeport",
        userDataField: "establishment_director_data_passeport",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_first_name",
        userDataField: "establishment_director_data_first_name",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_last_name",
        userDataField: "establishment_director_data_last_name",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_experience_years",
        userDataField: "establishment_director_data_experience_years",
        readOnly: true,
      },

      {
        surveyField: "establishment_director_data_first_name_fr",
        userDataField: "establishment_director_data_first_name_fr",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_last_name_fr",
        userDataField: "establishment_director_data_last_name_fr",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_email",
        userDataField: "establishment_director_data_email",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_date_expiration_passeport",
        userDataField: "establishment_director_date_expiration_passeport",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_birth_date",
        userDataField: "establishment_director_data_birth_date",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_genre",
        userDataField: "establishment_director_data_genre",
        readOnly: true,
      },

      {
        surveyField: "establishment_director_data_nationality",
        userDataField: "establishment_director_data_nationality",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_cin_number",
        userDataField: "establishment_director_data_cin_number",
        readOnly: true,
      },

      {
        surveyField: "establishment_director_data_date_delivrance",
        userDataField: "establishment_director_data_date_delivrance",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_birth_place",
        userDataField: "establishment_director_data_birth_place",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_education_level",
        userDataField: "establishment_director_data_education_level",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_phone_number",
        userDataField: "establishment_director_data_phone_number",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_address",
        userDataField: "establishment_director_data_address",
        readOnly: true,
      },

      //   {
      //     surveyField: "" ,
      //     userDataField: "code_postal",
      //     readOnly: true,
      //   },
      {
        surveyField: "establishment_director_data_specific_needs",
        userDataField: "establishment_director_data_specific_needs",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_cin_copie",
        userDataField: "establishment_director_data_cin_copie",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_passeport_copie",
        userDataField: "establishment_director_data_passeport_copie",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_director_folder",
        userDataField: "establishment_director_data_director_folder",
        readOnly: true,
      },
      {
        surveyField: "copie_autorisation_ministre",
        userDataField: "copie_autorisation_ministre",
        readOnly: true,
      },
      {
        surveyField: "establishment_director_data_ministerial_auth_date",
        userDataField: "establishment_director_data_ministerial_auth_date",
        readOnly: true,
      },
    ];
    fieldsToSet.forEach((field) => {
      const { surveyField, userDataField, readOnly } = field;
      const value =
        surveyField.includes("_date") && userData[userDataField]
          ? parseDate(userData[userDataField])
          : userData[userDataField];
      const question = surveyProfile.getQuestionByName(surveyField);

      if (!value) {
        if (question) {
          question.readOnly = false;
        }
      } else {
        surveyProfile.setValue(surveyField, value);
        if (readOnly && question) {
          question.readOnly = true;
        }
      }
    });

    getData();
  };

  const getData = () => {
    surveyProfile.getAllQuestions().forEach(async (question) => {
      if (question.name?.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;
        const delegateKey = question.name.replace(
          "_governorate",
          "_delegation"
        );
        const selectedGouvernorat = info[question.name];
        const selectedDelegate = surveyProfile.getQuestionByName(delegateKey);
        const id_goverment = gouvernoratList.find(
          (e) => e.value === selectedGouvernorat
        )?.id;
        if (id_goverment) {
          const delegations = await fetchDelegationList(id_goverment);

          selectedDelegate.choices = delegations || [];

          const selectedDelegateValue = selectedDelegate.value;
          const postalCodeQuestion = surveyProfile.getQuestionByName(
            question.name.replace("_delegation", "_postal_code")
          );

          const idDelegation = listAllDelegations.find(
            (elem) => elem.value === selectedDelegateValue
          )?.id;

          if (idDelegation) {
            const listPostalCodes = await fetchCodePostalList(idDelegation);
            if (listPostalCodes) {
              postalCodeQuestion.choices = listPostalCodes.map((code) => ({
                value: code.value,
                text: code.text,
              }));
            } else {
              postalCodeQuestion.choices = [];
            }
          }
        }
      }

      if (question.name.toLowerCase().includes("_nationality")) {
        question.choices = nationalityList;
      }
      if (question.name.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;
      }
    });

    surveyProfile.showProgressBar = "off";
    surveyProfile.showNavigationButtons = false;
    surveyProfile.showPreviewBeforeComplete = false;
    displayQuestionsFunction();
  };

  useEffect(() => {
    async function initSurvey() {
      const ProfileJson = getSurveyJson();

      if (ProfileJson) {
        surveyProfile.setJsonObject(ProfileJson);
        setUserData(info);
        CustomWidgetCollection.Instance.addCustomWidget({
          name: "datepicker",
          title: "Date picker",
          isFit: function (question) {
            return (
              question.getType() === "text" &&
              question?.jsonObj?.inputType === "date"
            );
          },
          render: function (question, element) {
            return (
              <DatePikckerComponent
                question={question}
                survey={question.survey}
              />
            );
          },
        });

        CustomWidgetCollection.Instance.addCustomWidget({
          name: "Button",
          title: "Button",
          isFit: function (question) {
            return (
              question.getType() === "text" &&
              question?.jsonObj?.inputType?.toLowerCase() === "button"
            );
          },
          render: function (question, element) {
            return <ButtonComponent question={question} />;
          },
        });
      }
    }
    initSurvey();
    return () => {
      surveyProfile.dispose(true, true);
      surveyProfile = new Model();
    };
  }, []);

  return (
    <div>
      <Space className={classes.rowButtonCreate}>
      <Button
        className={classes.buttonCreate}
        onClick={async () => {
          navigate("/directeurs");
        }}
      >
        <BookUser strokeWidth={1.25} />
        {t("Liste des directeurs")}
      </Button> </Space>
      <ConfigProvider>
        <div style={{ direction: "ltr" }}>
          <Survey model={surveyProfile} />
        </div>
      </ConfigProvider>
    </div>
  );
};

export default ProfileDirecteur;
