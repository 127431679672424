/* eslint-disable react/prop-types */
import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const AnnexeLoaderContext = createContext();

export const AnnexeLoaderProvider = ({ children }) => {
  const [isLoadingAnnexe, setIsLoading] = useState(false);
  const [surveyJson, setSurveyJson] = useState(null);

  return (
    <AnnexeLoaderContext.Provider
      value={{ isLoadingAnnexe, setIsLoading, surveyJson, setSurveyJson }}
    >
      {children}
    </AnnexeLoaderContext.Provider>
  );
};

AnnexeLoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook to use loader context
export const useAnnexeLoader = () => useContext(AnnexeLoaderContext);
