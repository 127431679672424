/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Card,
  Table,
  Tooltip,
  Tag,
  Button,
  Space,
  notification,
  Modal,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import classes from "../Etablissements/Etablissements.module.css";
import { useTranslation } from "react-i18next";
import { getAffaires } from "../../api/geoprod";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";
import { useLoader } from "../../context/LoaderContext";
import axiosInstance from "../../utils/axios";

const Brouillons = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { brouillon, setBrouillon, setMatriculeFiscale, setNbrBrouillon } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState("");
  const [idASupprimer, setIdASupprimer] = useState("");
  const { setIsLoading, setSurveyJson, setSurveyData, setShowingPreview } =
    useLoader();

  const determineNavigationPath = (record, form) => {
    const { affaire_id, affaire_id_contrat } = record;
    const { pages } = form;
    switch (affaire_id_contrat) {
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE:
        sessionStorage.setItem("id_affaire_annexe", affaire_id);
        return "/annexes/save?affaire_id=" + record?.affaire_parent;
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE:
        sessionStorage.setItem("idAffaireEpfExistant", affaire_id);
        return "/etablissements/existante/save";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        const pageChangementInvestisseur = pages?.find(
          (item) => item?.name === "ChnangementInvestisseur"
        );
        if (pageChangementInvestisseur) {
          sessionStorage.setItem("idAffaireChangementInvestisseur", affaire_id);
          setSurveyJson(pageChangementInvestisseur);
          return "/investisseur/changer";
        } else {
          const selectedOption = determineSelectedOption(affaire_id_contrat);
          sessionStorage.setItem("selectedOption", selectedOption);
          sessionStorage.setItem(
            `id_affaire_edit_${selectedOption}`,
            affaire_id
          );
          return `/etablissements/edit`;
        }
      default:
        sessionStorage.setItem("id_affaire", affaire_id);
        return "/etablissements/save";
    }
  };

  const determineSelectedOption = (affaire_id_contrat) => {
    switch (affaire_id_contrat) {
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
        return "avec_bo_validation";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        return "sans_bo_sans_validation";
      default:
        return "sans_bo_avec_validation";
    }
  };

  const columns = [
    {
      title: t("Date de création"),
      key: "Date de création",
      render: (_, record) =>
        dayjs(record?.affaire_date_creation).format("DD/MM/YYYY"),
    },
    {
      title: t("Date dernière modification"),
      dataIndex: "Date dernière modification",
      key: "Date dernière modification",
      render: (_, record) =>
        record?.last_update
          ? dayjs(record.last_update).format("DD/MM/YYYY HH:mm:ss")
          : t("Non renseigné"),
    },
    {
      title: t("Numéro d'enregistrement de l'EPF prinicipal"),
      key: "Numéro d'enregistrement de l'EPF prinicipale",
      render: (_, record) => record?.registration_number || t("Non renseigné"),
    },
    {
      title: t("Créateur de la demande"),
      key: "Créateur de la demande",
      render: (_, record) =>
        localStorage.getItem("language") === "ar" && record?.nom_createur_ar
          ? record.nom_createur_ar + " " + record?.prenom_createur_ar
          : record?.nom_createur + " " + record?.prenom_createur,
    },
    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return <Tag color={record?.etat_background}>{t(record?.etat)}</Tag>;
      },
    },
    {
      title: t("Type"),
      key: "statut",
      render: (_, record) => {
        return (
          <Tag color={record?.etat_background}>
            {(record?.affaire_id_contrat ===
              import.meta.env
                .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO ||
              record?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO ||
              record?.affaire_id_contrat ===
                import.meta.env
                  .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO) &&
            record?.origin_contrat_id ===
              import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
              ? t(record.contrat_type.replace("EPF", "annexe"))
              : t(record.contrat_type)}
          </Tag>
        );
      },
    },

    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("Modifier")}>
            <EditOutlined
              onClick={async () => {
                try {
                  setIsLoading(true);
                  setShowingPreview(false);

                  const res = await axiosInstance.get(
                    import.meta.env.VITE_APP_GEOPROD_API +
                      "/get_info_specifique/" +
                      record?.affaire_id,
                    {
                      headers: {
                        Idsession: JSON.parse(
                          sessionStorage.getItem("-x-token-user")
                        )?.Acces_Token,
                      },
                    }
                  );
                  const { form, data, current_step } = res?.data?.response;
                  sessionStorage.setItem(
                    "data_for_comparison",
                    JSON.stringify(data?.data_for_comparison)
                  );
                  sessionStorage.setItem(
                    "trainer_and_training_data",
                    JSON.stringify(data?.trainer_and_training_data)
                  );
                  const navigateTo = determineNavigationPath(record, form);
                  sessionStorage.setItem("current_step", current_step);
                  if (sessionStorage.getItem("role") === "directeur") {
                    sessionStorage.setItem(
                      "user_id_to_get",

                      res?.data.response?.id_user
                    );
                  } else {
                    sessionStorage.setItem(
                      "user_id_to_get",
                      res?.data.response?.responsable_EPF
                    );
                  }
                  const type =
                    record?.origin_contrat_id ===
                    import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                      ? "annexe"
                      : "";
                  if (navigateTo === "/etablissements/edit") {
                    const selectedOption =
                      sessionStorage.getItem("selectedOption");
                    const ligne_produit =
                      selectedOption === "avec_bo_validation"
                        ? import.meta.env
                            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
                        : selectedOption === "sans_bo_sans_validation"
                        ? import.meta.env
                            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
                        : import.meta.env
                            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO;
                    navigate(navigateTo, {
                      state: {
                        type: type,
                        id_affaire: record?.affaire_id,
                        ligne_produit: ligne_produit,
                      },
                    });
                    setSurveyData(res?.data);
                  } else if (
                    record?.affaire_id_contrat ===
                    import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
                  ) {
                    setMatriculeFiscale(data?.numero_matricule_fiscal_IURNE);
                    navigate(navigateTo);
                    setSurveyData(data);
                  } else {
                    navigate(navigateTo);
                    setSurveyData(data);
                  }
                  setSurveyJson(form);
                  sessionStorage.setItem("demande", false);
                } catch {
                  notification.error({
                    message: t("Erreur"),
                    description:
                      "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                    duration: 0,
                  });
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          </Tooltip>
          <Tooltip title={t("Supprimer")}>
            <DeleteOutlined
              onClick={() => {
                setConfirmModal(record?.affaire_id);
                const idAffaire =
                  record?.affaire_id_contrat ===
                  import.meta?.env?.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
                    ? "idAffaireEpfExistant"
                    : record?.affaire_id_contrat ===
                      import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                    ? "id_affaire_annexe"
                    : record?.affaire_id_contrat ===
                        import.meta.env
                          .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO &&
                      record.investor_page === null &&
                      record.origin_contrat_id !==
                        import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                    ? "idAffaireChangementInvestisseur"
                    : record?.affaire_id_contrat ===
                      import.meta.env
                        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO
                    ? "id_affaire_edit_avec_bo_validation"
                    : record?.affaire_id_contrat ===
                      import.meta.env
                        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO
                    ? "id_affaire_edit_sans_bo_avec_validation"
                    : record?.affaire_id_contrat ===
                      import.meta.env
                        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO
                    ? "id_affaire_edit_sans_bo_sans_validation"
                    : "id_affaire";
                setIdASupprimer(idAffaire);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const deleteBrouillon = () => {
    setIsLoading(true);
    axiosInstance
      .delete(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/delete_affaire/${confirmModal}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              .Acces_Token,
          },
        }
      )
      .then((res) => {
        setBrouillon((prevDataSource) =>
          prevDataSource.filter((item) => item.affaire_id !== confirmModal)
        );
        setNbrBrouillon((prevDataSource) => {
          if (prevDataSource >= 1) {
            return prevDataSource - 1;
          }
        });
        setConfirmModal("");
        setIsLoading(false);
        sessionStorage.removeItem(idASupprimer);
        sessionStorage.removeItem("surveyData");
        notification.success({
          message: t("Votre brouillon a bien été annulé."),
          duration: 0,
        });
      })
      .catch(() => {
        setConfirmModal("");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
          filters: {
            contrat: [],
            status: [
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT,
              import.meta.env.VITE_APP_ID_STATUS_BROUILLON,
            ],
            excludedStatus: [],
          },
        });
        setBrouillon(data?.aaData);
        setNbrBrouillon(data?.aaData?.length)
        setLoading(false);
      } catch (error) {
        notification.error({
          message: t("Erreur"),
          description:
            error?.response?.data?.message ||
            "Une interruption est survenue lors de la récupération des données. Veuillez réessayer.",
          duration: 0,
        });
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Modal
        open={confirmModal !== ""}
        footer={null}
        width={600}
        onCancel={() => setConfirmModal("")}
      >
        <h4>
          {" "}
          {t(
            "Attention, l'annulation de la demande entraînera la suppression du brouillon."
          )}{" "}
        </h4>
        <Button type="primary" onClick={deleteBrouillon}>
          {t("Oui")}
        </Button>
        <Button
          type="primary"
          style={{ marginInline: "15px" }}
          onClick={() => setConfirmModal("")}
        >
          {" "}
          {t("Non")}
        </Button>
      </Modal>

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={
            <div
              style={{ textAlign: i18n?.dir() === "ltr" ? "left" : "right" }}
            >
              {t("Liste de mes brouillons")}
            </div>
          }
        >
          <Table
            locale={{ emptyText: t("Aucune donnée") }}
            loading={loading}
            columns={columns}
            dataSource={brouillon}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={{
              pageSize: 5,
            }}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Brouillons;
