import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  Select,
  notification,
  Modal,
  Spin,
  Space,
  Checkbox,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/LogoMinistère.svg";
import republique from "../../assets/tunisia.png";
import { useTranslation } from "react-i18next";
import { getAffaires, login } from "../../api/geoprod";
import classes from "./Signin.module.css";
import axiosInstance from "../../utils/axios";
import { useLoader } from "../../context/LoaderContext";
import logoMobileId from "../../assets/LogoMobileID.png";
import pkceChallenge from "pkce-challenge";
import PropTypes from "prop-types";
const SignIn = (props) => {
  const navigate = useNavigate();
  const { form, globalData, setGlobalData, setIsRtl, setListTasks,setNbrBrouillon } =
    useContext(GlobalContext);
  const [formPassword] = Form.useForm();
  const { setIsLoading, setSurveyJson } = useLoader();
  const {  setRole } = props;
  const { Option } = Select;
  const [openModalPassword, setOpenModalPassword] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loaderChange, setLoaderChange] = useState(false);
  const [loaderForget, setLoaderForget] = useState(false);
  const [openModalForgetPassword, setOpenModalForgetPassword] = useState(false);
  const { t, i18n } = useTranslation();
  const roleParams = new URLSearchParams(window.location.search).get("role");
  const nomEspace = "Espace " + roleParams;
  const roles = ["directeur", "investisseur", "formateur"];
  const idRole =
    roleParams === "investisseur"
      ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR
      : roleParams === "formateur"
      ? import.meta.env.VITE_APP_ID_ROLE_FORMATEUR
      : roleParams === "directeur"
      ? import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR
      : "";

  if (!roles.includes(roleParams)) {
    notification.warning({
      message: t("Redirection en cours"),
      description: t(
        "Vous n'avez pas sélectionné d'espace dédié. Vous allez être redirigé vers la page principale pour choisir l'espace qui correspond à votre rôle (investisseur, formateur, etc)."
      ),
      duration: 0,
    });
    setTimeout(() => {
      window.location.href = import.meta.env.VITE_APP_URL_SITE_VITRINE;
    }, [1000]);
  }
  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);
    setIsRtl(value === "ar");
  };

  const saveLoginCredentials = async (data) => {
    const first_login = data?.first_login;
    const role =
      data.id_role === import.meta.env.VITE_APP_ID_ROLE_FORMATEUR
        ? "formateur"
        : data?.id_role ===
          import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR_DIRCETEUR
        ? "investisseur-directeur"
        : data?.id_role === import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR
        ? "directeur"
        : "investisseur";
    setRole(role);
    sessionStorage.setItem("role", role);
    sessionStorage.setItem("-x-token-user", JSON.stringify(data));

    axiosInstance
      .post(
        `${import.meta.env.VITE_APP_GEOPROD_API}/nbr_tache_by_user`,
        {
          status: [
            import.meta.env.VITE_APP_ETAT_VALIDATION_PROVISOIRE,
            import.meta.env.VITE_APP_ETAT_COMPLEMENT_VALIDATION_PROVISOIRE,
            import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER,
            import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_EPF_EXISTANT,
            import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_MODIFICATION,
          ],
          brouillon:[
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT,
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON
          ]
        },
        {
          headers: {
            Idsession: data?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setNbrBrouillon(res?.data?.nombre_brouillon)
        setListTasks(res?.data?.nombre_taches);

      });

    if (data.id_role === import.meta.env.VITE_APP_ID_ROLE_FORMATEUR) {
      navigate("/profile", {
        state: {
          first_login: first_login,
          ehouwiya: data?.ehouwiya,
        },
      });
    } else {
      axiosInstance
        .get(
          `${import.meta.env.VITE_APP_GEOPROD_API}/admin-bpm/contrat/${
            import.meta.env.VITE_ID_LIGNE_PRODUIT
          }`,
          {
            headers: {
              Idsession: data?.Acces_Token,
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          setSurveyJson(res?.data?.contrat?.form);
        });

      const response = await getAffaires({
        id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
        id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user")).user
          .entreprise_id,
        filters: {
          contrat: [],
          status: [],
          excludedStatus: [],
        },
      });

      let tempData = [];

      response?.aaData?.forEach((element) => {
        if (
          element.etat !== "Demande validée" &&
          element.etat !== "Brouillon"
        ) {
          tempData.push(element);
        }
      });

      if (tempData?.length > 0) {
        navigate("/demandes");
      } else {
        navigate("/etablissements");
      }


    }
  };

  const handleFinish = async (values) => {
    setLoader(true);
    try {
      if (!roles.includes(roleParams)) {
        notification.warning({
          message: t("Redirection en cours"),
          description: t(
            "Vous n'avez pas sélectionné d'espace dédié. Vous allez être redirigé vers la page principale pour choisir l'espace qui correspond à votre rôle (investisseur, formateur, etc)."
          ),
          duration: 0,
        });
        setTimeout(() => {
          window.location.href = import.meta.env.VITE_APP_URL_SITE_VITRINE;
        }, [1000]);
      } else {
        const data = await login(values.email, values.password, "", idRole);
        if (data?.first_login === 1) {
          notification.destroy();

          setUserData(data);

          if (
            data?.id_role == import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR ||
            data?.id_role === import.meta.env.VITE_APP_ID_ROLE_FORMATEUR
          ) {
            setOpenModalPassword(true);
          } else {
            saveLoginCredentials(data);
          }
        } else {
          notification.destroy();
          saveLoginCredentials(data);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      notification.error({
        message: t("Erreur"),
        description:
          t(error?.response?.data?.message) ||
          t(
            "Une interruption est survenue lors de la récupération des données. Veuillez réessayer."
          ),
        duration: 0,
      });
    }
  };

  const changePassword = async () => {
    setLoaderChange(true);
    try {
      const newPassword = formPassword.getFieldValue("password_change");
      await axiosInstance.put(
        `${import.meta.env.VITE_APP_GEOPROD_API}/change_password_user`,
        {
          id_user: userData?.id_user,
          password: newPassword,
        },
        {
          headers: {
            Idsession: userData?.Acces_Token,
          },
        }
      );
      setOpenModalPassword(false);
      notification.success({
        message: t("Mot de passe changé avec succès !"),
        duration: 0,
      });
      setLoaderChange(false);

      saveLoginCredentials(userData);
    } catch (error) {
      setLoaderChange(false);
      notification.error({
        message: t("Erreur lors du changement de mot de passe"),
        description: error.message || t("Veuillez réessayer."),
        duration: 0,
      });
    }
  };
  const forgetPassword = (values) => {
    setLoaderForget(true);

    axiosInstance
      .get(
        `${import.meta.env.VITE_APP_GEOPROD_API}/forget_password?mail=${
          values.email_oublie
        }&role=${idRole}`
      )
      .then((res) => {
        setLoaderForget(false);
        notification.success({
          message: t(
            "Veuillez consulter votre boîte mail. Un lien de réinitialisation de mot de passe vous a été envoyé."
          ),
          duration: 0,
        });
        setOpenModalForgetPassword(false);
      })
      .catch((err) => {
        notification.error({
          message: t("Erreur"),
          description: t(
            "Ce compte existe, mais il est attribué à un autre rôle ou espace."
          ),
          duration: 0,
        });

        setLoaderForget(false);
      });
  };

  return (
    <Row
      gutter={24}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Col
        lg={24}
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignItems: "center",
          }}
        >
          <Col>
            <img
              src={import.meta.env.VITE_APP_LOGO}
              style={{ width: "8rem" }}
              alt="logo"
            />
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem", // Adds spacing between elements
            }}
          >
            <Select
              style={{ width: "8rem" }}
              onChange={handleLanguageChange}
              value={localStorage.getItem("language") || "fr"}
            >
              <Option value="fr">Français</Option>
              <Option value="ar">العربية</Option>
            </Select>
            <img src={logo} style={{ height: "90px" }} alt="logo" />
            <img src={republique} style={{ height: "86px" }} alt="republique" />
          </Col>
        </Row>
      </Col>

      {roleParams && roleParams !== "null" && (
        <h2 className="mb-2">{t(nomEspace)}</h2>
      )}

      <Card
        style={{
          maxWidth: "600px",
          width: "90%",
        }}
      >
        <h2 className="mb-2">{t("Se connecter")}</h2>

        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          disabled={loader}
          style={{ width: "100%" }}
        >
          <Form.Item
            label="Adresse e-mail - البريد الإلكتروني"
            name="email"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("Veuillez vérifier ce champ."),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mot de passe - كلمة المرور"
            name="password"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="recaptcha"
            rules={[
              {
                required: true,
                message: t("Veuillez vérifier ce champ."),
              },
            ]}
          >
            <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" />
          </Form.Item>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item>
              <Space>
                {loader && <Spin />}
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#21A8A8",
                    width: "7rem",
                    color: "white",
                  }}
                  htmlType="submit"
                >
                  {t("Se connecter")}
                </Button>
              </Space>
              {roleParams !== "directeur" && (
                <p>
                  {t("Vous n'avez pas de compte ?")}{" "}
                  <button
                    className={classes.seConnecter}
                    onClick={() => {
                      form.resetFields();
                      if (!roles.includes(roleParams)) {
                        notification.warning({
                          message: t("Redirection en cours"),
                          description: t(
                            "Vous n'avez pas sélectionné d'espace dédié. Vous allez être redirigé vers la page principale pour choisir l'espace qui correspond à votre rôle (investisseur, formateur, etc)."
                          ),
                          duration: 0,
                        });
                        setTimeout(() => {
                          window.location.href =
                            import.meta.env.VITE_APP_URL_SITE_VITRINE;
                        }, [1000]);
                      } else {
                        navigate(`/signup?role=${roleParams}`);
                      }
                    }}
                    style={{
                      all: "unset",
                      color: "#21A8A8",
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                  >
                    {t("Créez-en un")}
                  </button>
                </p>
              )}
              <button
                className={classes.seConnecter}
                onClick={() => {
                  if (!roles.includes(roleParams)) {
                    notification.warning({
                      message: t("Redirection en cours"),
                      description: t(
                        "Vous n'avez pas sélectionné d'espace dédié. Vous allez être redirigé vers la page principale pour choisir l'espace qui correspond à votre rôle (investisseur, formateur, etc)."
                      ),
                      duration: 0,
                    });
                    setTimeout(() => {
                      window.location.href =
                        import.meta.env.VITE_APP_URL_SITE_VITRINE;
                    }, [1000]);
                  } else {
                    setOpenModalForgetPassword(true);
                  }
                }}
                style={{
                  all: "unset",
                  color: "#21A8A8",
                  marginLeft: "0.5rem",
                  cursor: "pointer",
                }}
              >
                {t("Mot de passe oublié ?")}{" "}
              </button>
            </Form.Item>
            <Form.Item>
              <Space>
                <div style={{ backgroundColor: "#f9f9f9", padding: "6px" }}>
                  <Button
                    disabled={
                      roleParams !== "formateur" &&
                      roleParams !== "investisseur" &&
                      roleParams !== "directeur"
                    }
                    style={{
                      color: "#0f313d",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid #0f313d",
                      paddingBlock: "2rem",
                      fontSize: "18px",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "0px",
                      cursor:
                        roleParams === "formateur" ||
                        roleParams === "investisseur" ||
                        roleParams === "directeur"
                          ? "pointer"
                          : "not-allowed",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                    onClick={async () => {
                      const challenge = await pkceChallenge(128);
                      const codeChallenge = challenge?.code_challenge;
                      const codeVerifier = challenge?.code_verifier;
                      localStorage.setItem("code_verifier", codeVerifier);
                      const redirect_uri = `${window.location.origin}/signin/e-houwiya?role=${roleParams}`;
                      const uri = `${
                        import.meta.env.VITE_APP_E_HOUWIYA_API
                      }/oauth2/authorize?response_type=code&client_id=${
                        import.meta.env.VITE_CLIENT_ID_E_HOUWIYA
                      }&redirect_uri=${redirect_uri}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=openid profile email phone identity birthdate address name gender`;
                      window.location.href = uri;
                    }}
                  >
                    <img
                      src={logoMobileId}
                      alt="logo"
                      style={{ width: "90px", height: "40px" }}
                    />
                    <span>تسجيل الدخول</span>
                  </Button>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "8px",
                    }}
                  >
                    <button
                      onClick={() => {
                        if (
                          roleParams === "formateur" ||
                          roleParams === "investisseur" ||
                          roleParams === "directeur"
                        ) {
                          window.open("https://www.mobile-id.tn/home/condition","blank","noopener");
                        }
                      }}
                      style={{
                        fontSize: "16px",
                        color: "#0f313d",
                        textDecoration: "underline",
                        cursor:
                          roleParams === "formateur" ||
                          roleParams === "investisseur" ||
                          roleParams === "directeur"
                            ? "pointer"
                            : "not-allowed",
                        border: "none",
                      }}
                    >
                      طلب الهوية الرقمية
                    </button>
                  </div>
                </div>
                {/* )} */}
              </Space>
            </Form.Item>
          </div>

          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item>
                <Space>
                  {loader && <Spin />}
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#21A8A8",
                      width: "7rem",
                      color: "white",
                    }}
                    htmlType="submit"
                  >
                    {t("Se connecter")}
                  </Button>
                </Space>
              </Form.Item>
              <p style={{ textAlign: "right" }}>
                {t("Vous n'avez pas de compte ?")}{" "}
                <span
                  className={classes.seConnecter}
                  onClick={() => navigate(`/signup?role=${role}`)}
                >
                  {t("Créez-en un")}
                </span>
              </p>
            </div>
            <p
             style={{ textAlign: "left" }}
              className={classes.seConnecter}
              onClick={() => setOpenModalForgetPassword(true)}
            >
              {t("Mot de passe oublié ?")}{" "}
            </p> */}
        </Form>
      </Card>
      {roleParams === "investisseur" ? (
        <Modal
          title={t("Changer le mot de passe")}
          open={openModalPassword}
          footer={null}
          width={600}
          onCancel={() => setOpenModalPassword(false)}
        >
          <Form
            form={formPassword}
            layout="vertical"
            style={{ maxWidth: "600px", margin: "auto", paddingTop: "20px" }}
            onFinish={changePassword}
            disabled={loaderChange}
          >
            <Form.Item
              label={t("Mot de passe")}
              name="password_change"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[*/.@-])[A-Za-z\d*/.@-]{6,}$/,
                  message: t(
                    "Le mot de passe doit contenir au moins 6 caractères, dont au moins un chiffre (0-9) et au moins un caractère spécial (/ . @ -)."
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t("Confirmer le mot de passe")}
              name="confirmPassword"
              dependencies={["password_change"]}
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password_change") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t(
                          "Le mot de passe et la confirmation doivent être identiques."
                        )
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <div style={{ textAlign: "center" }}>
              <Space>
                {loaderChange && <Spin />}
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{
                    backgroundColor: "#214082",
                    borderColor: "#214082",
                    marginRight: "10px",
                    width: "7rem",
                    marginTop: "1rem",
                  }}
                >
                  {t("Enregistrer")}
                </Button>
              </Space>
            </div>
          </Form>
        </Modal>
      ) : (
        <Modal
          title={t("Activation de votre compte Formateur")}
          footer={() => (
            <Button
              type="primary"
              onClick={() => {
                setOpenModalPassword(false);
                saveLoginCredentials(userData);
              }}
            >
              {t("J'ai compris")}
            </Button>
          )}
          width={600}
          open={openModalPassword}
          onCancel={() => setOpenModalPassword(false)}
        >
          <p>
            {t(
              "Veuillez mettre à jour les informations de votre profil et cliquer sur 'Mettre à jour mes données' pour activer votre compte formateur. Sans cette mise à jour, votre compte ne sera pas activé auprès des Etablissements de formation privée."
            )}
          </p>
        </Modal>
      )}

      <Modal
        title={t("Veuillez entrer votre adresse e-mail")}
        open={openModalForgetPassword}
        footer={null}
        width={600}
        onCancel={() => setOpenModalForgetPassword(false)}
      >
        <Form
          // form={form}
          layout="vertical"
          style={{ maxWidth: "600px", margin: "auto", paddingTop: "20px" }}
          onFinish={forgetPassword}
          disabled={loaderForget}
        >
          <Form.Item
            name="email_oublie"
            label="Adresse e-mail - البريد الإلكتروني"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("Veuillez vérifier ce champ."),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            <Space>
              {loaderForget && <Spin />}
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  backgroundColor: "#214082",
                  borderColor: "#214082",
                  marginRight: "10px",
                  width: "7rem",
                  marginTop: "1rem",
                }}
              >
                {t("Valider")}
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>

      {/* <Modal
        
        visible={openModalForgetPassword}
        footer={null}
        width={600}
        onCancel={() => setOpenModalForgetPassword(false)}
      >
        <Form onFinish={forgetPassword} form={form} layout="vertical">
          <Form.Item
            name="email_oublie"
            label="Adresse e-mail - البريد الإلكتروني"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("Veuillez vérifier ce champ."),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
        </Form>
      </Modal> */}
    </Row>
  );
};

SignIn.propTypes = {
  setRole: PropTypes.func,
};


export default SignIn;
