import React, { useState } from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import frFR from "antd/locale/fr_FR";
import AppContext from "./context/AppContext";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/Signup/SignUp";
import Main from "./layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Demandes from "./pages/Demandes/Demandes";
import Rtl from "./pages/Formateurs";
import Etablissements from "./pages/Etablissements/Etablissements";
import CreateEpf from "./pages/Etablissements/CreateEpf/CreateEpf";
import EditEpf from "./pages/Etablissements/EditEpf/EditEpf";
import PdfViewer from "./pages/PdfViewer";
import Profile from "./pages/Profile";
import Brouillons from "./pages/Brouillons/Brouillons";
import { LoaderProvider } from "./context/LoaderContext";
import Home from "./pages/Home";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ComplementDossier from "./pages/Demandes/ComplementDossier/ComplementDossier";
import SignInEHouwiya from "./pages/SignInEHouwiya/SignInEHouwiya";
import ValidationProvisoire from "./pages/Demandes/ValidationProvisoire/ValidationProvisoire";
import AnnexesList from "./pages/Annexes/AnnexesList";
import AnnexesForm from "./pages/Annexes/AnnexesForm";
import { AnnexeLoaderProvider } from "./context/AnnexeLoaderContext";
import CreateEpfExistante from "./pages/Etablissements/CreateEpf/CreateEpfExistant";
import ChangementInvestisseur from "./pages/Etablissements/ChangementInvestisseur/ChangementInvestisseur";
import SurveyProfile from "./pages/SurveyProfile/SurveyProfile";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import Reclamation from "./pages/Reclamation/Reclamation";
import ReclamationRecord from "./pages/Reclamation/ReclamationRecord";
import Taches from "./pages/Taches/Taches";
import ListFomrations from "./pages/Formations/ListFomrations";
import FormationDetails from "./pages/Formations/FormationDetails";
import Historique from "./pages/Historique/Historique";
import Directeurs from "./pages/Directeurs/Directeurs";
import ProfileDirecteur from "./pages/Directeurs/ProfileDirecteur";

function App() {
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const paramRole = new URLSearchParams(window.location.search).get("role");
  const { i18n } = useTranslation();

  React.useEffect(() => {
    // Function to update all anchor tags
    const updateAnchorTags = () => {
      document?.querySelectorAll("a")?.forEach((anchor) => {
        anchor?.setAttribute("target", "_blank"); // Open in a new tab
        anchor?.setAttribute("rel", "noopener noreferrer"); // Security best practice
      });
    };

    // Run initially to modify existing links
    updateAnchorTags();

    // Set up a MutationObserver to handle dynamically added <a> tags
    const observer = new MutationObserver(() => {
      updateAnchorTags();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#024FA8",
          colorBgContainerDisabled:"#EBEBE4"
        },
        components: {
          Input: {
            defaultHoverBorderColor: "#21A8A8",
            controlHeight: 46,
          },
          Radio: {
            colorPrimary: "#21A8A8",
          },

          Modal: {
            colorBgMask: "rgba(2, 79, 168, 0.4)",
          },
          Collapse: {
            headerBg: "#EEF2FB",
            contentBg: "#EEF2FB",
          },
          Select: {
            controlHeight: 46,
          },
          DatePicker: {
            controlHeight: 46,
            colorBgContainerDisabled:"#EBEBE4",
            colorTextDisabled:"black",
            colorBorder:"#EBEBE4"
          },

          Search: {
            controlHeight: 46,
          },
        },
      }}
      locale={frFR}
      direction={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <AppContext>
        <LoaderProvider>
          <AnnexeLoaderProvider>
            <Routes>
              <Route
                path="/signin"
                element={
                  <SignIn  setRole={setRole} role={role} />
                }
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/signup"
                element={
                  paramRole !== "directeur" ? (
                    <SignUp />
                  ) : (
                    <Navigate to={`/signin?role=${paramRole}`} />
                  )
                }
              />
              <Route
                path="*"
                element={<Navigate to={`/signin?role=${paramRole}`} />}
              />
              <Route
                path="/signin/e-houwiya"
                element={
                  <SignInEHouwiya  setRole={setRole} />
                }
              />

              {role !== "formateur" ? (
                <Route element={<Main />}>
                  {/* <Route path="/dashboard" element={<Home />} /> */}
                  <Route path="/demandes" element={<Demandes />} />
                  <Route path="/historique" element={<Historique />} />

                  
                  <Route path="/demandes/:id" element={<Demandes />} />

                  <Route path="/formateurs" element={<Rtl />} />
                  <Route path="/directeurs" element={<Directeurs />} />
                  <Route path="/directeurs/detail" element={<ProfileDirecteur />} />

                  

                  <Route path="/formations" element={<ListFomrations />} />
                  <Route path="/formations/details" element={<FormationDetails />} />


                  <Route path="/annexes" element={<AnnexesList />} />
                  <Route path="/annexes/:id" element={<AnnexesList />} />

                  <Route path="/reclamation" element={<Reclamation />} />
                  <Route path="/reclamation/:id" element={<ReclamationRecord />} />

                  <Route path="/annexes/save" element={<AnnexesForm />} />
                  <Route
                    path="/etablissements"
                    element={<Etablissements role={role} />}
                  />
                  <Route
                    path="/taches"
                    element={<Taches />}
                  />
                  <Route
                    path="/etablissements/:id"
                    element={<Etablissements role={role} />}
                  />
                  <Route path="/etablissements/save" element={<CreateEpf />} />
                  <Route path="/etablissements/edit" element={<EditEpf />} />
                  <Route
                    path="/etablissements/save/:id"
                    element={<CreateEpf />}
                  />
                  <Route
                    path="/etablissements/existante/save"
                    element={<CreateEpfExistante />}
                  />
                  <Route
                    path="/investisseur/changer"
                    element={<ChangementInvestisseur />}
                  />
                  <Route
                    path="/complement/dossier"
                    element={<ComplementDossier />}
                  />

                  <Route
                    path="/complement/validation/provisoire"
                    element={<ValidationProvisoire />}
                  />

                  <Route
                    path="/etablissements/view/:id"
                    element={<CreateEpf />}
                  />
                  <Route path="/pdf/viewer" element={<PdfViewer />} />
                  <Route path="*" element={<Navigate to="/demandes" />} />
                  <Route path="/brouillon" element={<Brouillons />} />
                  <Route path="/userProfil" element={<SurveyProfile />} />
                </Route>
              ) : (
                <Route element={<Main />}>
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/acceuil" element={<Home />} />
                  <Route path="*" element={<Navigate to="/profile" />} />
                </Route>
              )}
            </Routes>
          </AnnexeLoaderProvider>
        </LoaderProvider>
      </AppContext>
    </ConfigProvider>
  );
}

export default App;
