import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showingPreview, setShowingPreview] = useState(false);

  const setSurveyJson = (surveyJson) => {
    sessionStorage.setItem("surveyJson", JSON.stringify(surveyJson));
  };
  const getSurveyJson = () => {
    return JSON.parse(sessionStorage.getItem("surveyJson"));
  };
  const setSurveyData = (surveyData) => {
    sessionStorage.setItem("surveyData", JSON.stringify(surveyData));
  };
  const getSurveyData = async () => {
    return JSON.parse(sessionStorage.getItem("surveyData"));
  };

  const setSurveyJsonValidationProvisoire = (surveyJson) => {
    sessionStorage.setItem(
      "surveyJsonValidationProvisoire",
      JSON.stringify(surveyJson)
    );
  };
  const getSurveyJsonValidationProvisoire = () => {
    return sessionStorage?.getItem("surveyJsonValidationProvisoire") !==
      "undefined"
      ? JSON?.parse(sessionStorage?.getItem("surveyJsonValidationProvisoire"))
      : null;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  return (
    <LoaderContext.Provider
      value={{
        isLoading,
        setIsLoading,
        setSurveyJson,
        getSurveyJson,
        setSurveyData,
        getSurveyData,
        setSurveyJsonValidationProvisoire,
        getSurveyJsonValidationProvisoire,
        showModal,
        hideModal,
        isModalVisible,
        showingPreview,
        setShowingPreview,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

LoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook to use loader context
export const useLoader = () => useContext(LoaderContext);
