import { useState, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Layout, Drawer, Affix, Spin } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header/Header";
import Footer from "./Footer";
import GlobalContext from "../context/GlobalContext";
import { useTranslation } from "react-i18next";
import { useLoader } from "../context/LoaderContext";

const { Header: AntHeader, Content, Sider } = Layout;

function Main() {
  const [visible, setVisible] = useState(false);
  const placement="left"
  const [sidenavColor, setSidenavColor] = useState("#21A8A8");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const { isRtl } = useContext(GlobalContext);
  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);
  const { isLoading } = useLoader();

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  const { t } = useTranslation();


  return (
    <Spin
      spinning={isLoading}
      tip={t("Veuillez patientez ...")}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
      }}
    >
      <Layout
        className={`layout-dashboard ${
          pathname === "profile" ? "layout-profile" : ""
        } ${isRtl ? "layout-dashboard-rtl" : ""}`}
      >
        <Drawer
          title={false}
          placement={placement}
          closable={false}
          onClose={() => setVisible(false)}
          open={visible}
          key={placement}
          width={250}
          className={`drawer-sidebar ${isRtl ? "drawer-sidebar-rtl" : ""}`}
        >
          <Layout
            className={`layout-dashboard ${
              isRtl ? "layout-dashboard-rtl" : ""
            }`}
          >
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary ${
                sidenavType === "#fff" ? "active-route" : ""
              }`}
              style={{ background: sidenavType }}
            >
              <Sidenav color={sidenavColor} />
            </Sider>
          </Layout>
        </Drawer>

        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          width={250}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${
            sidenavType === "#fff" ? "active-route" : ""
          }`}
          style={{ background: sidenavType }}
        >
          <Sidenav color={sidenavColor} />
        </Sider>

        <Layout>
          {fixed ? (
            <Affix>
              <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header
                  onPress={openDrawer}
                  name={pathname}
                  subName={pathname}
                  handleSidenavColor={handleSidenavColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                />
              </AntHeader>
            </Affix>
          ) : (
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          )}
          <Content className="content-ant">
            <Outlet />
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </Spin>
  );
}


export default Main;
