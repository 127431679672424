import React, { useContext, useEffect, useRef, useState } from "react";
import { Survey } from "survey-react-ui";
import {
  Model,
  CustomWidgetCollection,
  FunctionFactory,
  surveyLocalization,
} from "survey-core";
import GlobalContext from "../../../context/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchCodePostalList,
  fetchDelegationList,
  fetchSpetialityList,
  fetchSubSectorList,
} from "../../../context/FetchLists";
import classes from "../CreateEpf/CreateEpf.module.css";
import {
  onCurrentPageChanging,
  onDynamicPanelItemValueChanged,
  onErrorCustomText,
  onShowingPreview,
  onUploadFiles,
  onValueChanging,
} from "../CreateEpf/CreateEpfComponents/surveyEvents";
import DatePikckerComponent from "../CreateEpf/DatePIkcer";
import mime from "mime";
import { useLoader } from "../../../context/LoaderContext";
import SearchInputComponent from "../CreateEpf/SearchInputComponent";
import { useTranslation } from "react-i18next";
import { Button, ConfigProvider, notification, Space } from "antd";
import {
  getTrainerByNationalityAndIdentity,
  saveAffaire,
} from "../../../api/geoprod";
import ButtonComponent from "../CreateEpf/ButtonComponent";
import RefreshBarComponent from "../CreateEpf/RefreshBarComponent";
import { modifyFileLinks } from "../../../utils";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";
import DeleteBrouillonModal from "../CreateEpf/Modals/DeleteBrouillonModal";
import { checkDirectorEmail, getExtraitRegistreEntrepriseParId } from "../CreateEpf/CreateEpfComponents/surveyTriggers";
import { Save, Trash2 } from "lucide-react";
import ModalDateFinContrat from "./ModalDateFinContrat";
surveyLocalization.defaultLocale = "fr";

let surveyEdit = new Model();

const EditEpf = () => {
  const { secteurData, gouvernoratList, nationalityList, listAllDelegations,setNbrBrouillon } =
    useContext(GlobalContext);
  const {
    getSurveyJson,
    getSurveyData,
    setIsLoading,
    setSurveyJson,
    setSurveyData,
    setShowingPreview,
  } = useLoader();
  const ref = useRef();
  const { t } = useTranslation();
  const params = useLocation();
  const [confirmModal, setConfirmModal] = useState(false);
  const [dateFinContrat, setDateFinContrat] = useState("");
  const editJson = getSurveyJson();

  const navigate = useNavigate();
  const expressionsMap = {
    trainer_data_cin_number: {
      apiCall: (survey) => getTrainerWithCin(survey),
      maxLength: 8,
      regex: /^\d{8}$/,
      message: t("Le numéro cin doit être au format 8 chiffres(ex: 11111111)."),
    },
    trainer_data_passport: {
      apiCall: (survey) => getTrainerWithCin(survey),
    },
    establishment_director_data_email:{
      apiCall: (survey) => checkDirectorEmail(survey,setIsLoading,getSurveyData,setSurveyData,t,"edit"),
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: t("Merci d'entrer une adresse mail valide."),

    }
  };

  const displayQuestionsFunction = () => {
    surveyEdit.getAllQuestions().forEach(async (question) => {
      if (question.name === "training_nature_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const sectorQuestion = panel.getQuestionByName(
            "training_type_data_sector"
          );
          const subSectorQuestion = panel.getQuestionByName(
            "training_type_data_sub_sector"
          );
          const specialtyQuestion = panel.getQuestionByName(
            "training_type_data_specialty"
          );

          const selectedSector = sectorQuestion?.value;

          if (selectedSector) {
            const id = secteurData.find(
              (elem) => elem.value === selectedSector
            )?.id;
            const listSousSecteurs = await fetchSubSectorList(id);

            if (listSousSecteurs) {
              subSectorQuestion.choices = listSousSecteurs;
            } else {
              subSectorQuestion.choices = [];
            }

            const selectedSubSector = subSectorQuestion?.value;

            if (selectedSubSector) {
              const id = listSousSecteurs.find(
                (elem) => elem.value === selectedSubSector
              )?.id;
              const listSpecialiteDiplomes = await fetchSpetialityList(id);

              if (listSpecialiteDiplomes) {
                specialtyQuestion.choices = listSpecialiteDiplomes.map(
                  (item) => item[0].spetialite
                );
              } else {
                specialtyQuestion.choices = [];
              }
            }
          }
          const training_type_data_sector = panel.getQuestionByName(
            "training_type_data_sector"
          );
          const training_type_data_sub_sector = panel.getQuestionByName(
            "training_type_data_sub_sector"
          );
          const training_type_data_specialty = panel.getQuestionByName(
            "training_type_data_specialty"
          );
          const training_type_data_domain = panel.getQuestionByName(
            "training_type_data_domain"
          );
          const training_type_data_type = panel.getQuestionByName(
            "training_type_data_type"
          );
          const training_type_data_nature = panel.getQuestionByName(
            "training_type_data_nature"
          );
          const trainer_and_training_data = JSON.parse(
            sessionStorage.getItem("trainer_and_training_data")
          );
          const training_type_already_exists =
            trainer_and_training_data.training_nature_data?.some(
              (trainer) =>
                trainer.training_type_data_sector ===
                  training_type_data_sector.value &&
                trainer.training_type_data_sub_sector ===
                  training_type_data_sub_sector.value &&
                trainer.training_type_data_specialty ===
                  training_type_data_specialty.value
            );
          if (training_type_already_exists) {
            training_type_data_sector.readOnly = true;
            training_type_data_sub_sector.readOnly = true;
            training_type_data_specialty.readOnly = true;
            training_type_data_domain.readOnly = true;
            training_type_data_type.readOnly = true;
            training_type_data_nature.readOnly = true;
            training_type_data_nature.enableIf = "";
          }
        });
      }

      if (question.name.toLowerCase().includes("_nationality")) {
        question.choices = nationalityList;
      }

      if (question.name === "training_nature_data") {
        question?.panels?.forEach((panel) => {
          panel?.questions.forEach((question) => {
            if (question.name === "training_type_data_sector") {
              question.choices = secteurData;
            }
          });
        });
      }

      if (question.name.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;
      }

      if (question.name?.includes("_delegation")) {
        const selectedDelegation = question.value;
        const postalCodeQuestion = surveyEdit.getQuestionByName(
          question.name.replace("_delegation", "_postal_code")
        );

        const idDelegation = listAllDelegations.find(
          (elem) => elem.value === selectedDelegation
        )?.id;

        if (idDelegation && postalCodeQuestion) {
          const listPostalCodes = await fetchCodePostalList(idDelegation);
          if (listPostalCodes) {
            postalCodeQuestion.choices = listPostalCodes.map((code) => ({
              value: code.value,
              text: code.text,
            }));
          } else {
            postalCodeQuestion.choices = [];
          }
        }
      }

      if (question.name === "trainer_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "trainer_data_governorate"
          );

          const nationalityQuestion = panel.getQuestionByName(
            "trainer_data_nationality"
          );
          const govermentQuestion = panel.getQuestionByName(
            "trainer_data_governorate"
          );
          nationalityQuestion.choices = nationalityList;

          govermentQuestion.choices = gouvernoratList;

          // }
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "trainer_data_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }

          if (surveyEdit.getValue("trainer_data_test_valid")) {
            panel.getQuestions().forEach(async (question) => {
              if (
                question.name !== "trainer_data_cin" &&
                question.name !== "trainer_data_cin_number" &&
                question.name !== "trainer_data_cin_date_delivrance" &&
                question.name !== "trainer_data_passeport" &&
                question.name !== "trainer_data_folder" &&
                question.name !== "trainer_data_test_valid" &&
                question.name !== "trainer_active" &&
                question.name !== "trainer_data_passport" &&
                question.name !== "cin_check_spinner" &&
                question.name !== "dossier_autoriser" &&
                question.name !== "trainer_date_expiration_passeport" &&
                question.name !== "trainer_data_date_debut_contrat" &&
                question.name !== "trainer_data_date_fin_contrat"
              ) {
                question.visible = true;
              }

              if (panel.getQuestionByName("dossier_autoriser").value === true) {
                if (question.name === "trainer_data_folder") {
                  question.visible = true;
                }
              }

              if (
                panel.getQuestionByName("trainer_data_nationality").value ===
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_cin" ||
                  question.name === "trainer_data_cin_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (
                panel.getQuestionByName("trainer_data_nationality").value !==
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_passeport" ||
                  question.name === "trainer_date_expiration_passeport"
                ) {
                  question.visible = true;
                }
              }
              if (
                panel.getQuestionByName("trainer_data_situation") === "غير قار"
              ) {
                if (question.name === "trainer_data_date_debut_contrat") {
                  question.visible = false;
                }
              }
            });
            const panle_cin = panel.getQuestionByName(
              "trainer_data_cin_number"
            );
            const panel_passeport = panel.getQuestionByName(
              "trainer_data_passport"
            );
            const panel_nationality = panel.getQuestionByName(
              "trainer_data_nationality"
            );
            const trainer_and_training_data = JSON.parse(
              sessionStorage.getItem("trainer_and_training_data")
            );
            const trainer_already_exists =
              trainer_and_training_data.trainer_data?.some(
                (trainer) =>
                  trainer.trainer_data_passport === panel_passeport.value ||
                  trainer.trainer_data_cin === panle_cin.value
              );
            if (trainer_already_exists) {
              panle_cin.readOnly = true;
              panel_passeport.readOnly = true;
              panel_nationality.readOnly = true;
            }
          }
        });
      }

      const page = surveyEdit.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (page) {
        if (sessionStorage.getItem("role") === "directeur") {
          page.visible = false;
        }
        const dataSurvey = await getSurveyData();

        if (
          surveyEdit.getValue("establishment_director_data_identity") ===
          "المستثمر نفسه"
        ) {
          page.questions.forEach((question) => {
            if (question.name !== "establishment_director_data_identity") {
              if (
                question.name !== "establishment_director_data_identity" &&
                question.name !==
                  "establishment_director_data_experience_years" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "copie_autorisation_ministre"
              )
                question.readOnly = true;
            

              if (
                question.name !== "establishment_director_data_test_valid" &&
                question.name !==
                  "establishment_director_data_passeport_copie" &&
                question.name !== "copie_autorisation_ministre" &&
                question.name !== "establishment_director_data_cin_copie" &&
                question.name !==
                  "establishment_director_data_date_delivrance" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "establishment_director_data_passeport" &&
                question.name !== "establishment_director_data_cin_number" &&
                question.name !== "establishment_director_data_spinner" &&
                question.name !== "director_search" &&
                question.name !==
                  "establishment_director_date_expiration_passeport"
              ) {
                question.visible = true;
              }
            }

            if (
              surveyEdit.getQuestionByName(
                "establishment_director_data_nationality"
              ).value === "تونسية"
            ) {
              if (
                question.name === "establishment_director_data_cin_copie" ||
                question.name === "establishment_director_data_date_delivrance"
              ) {
                question.visible = true;
              }
            } else if (
              surveyEdit.getQuestionByName(
                "establishment_director_data_nationality"
              ).value !== "تونسية"
            ) {
              if (
                question.name ===
                  "establishment_director_data_passeport_copie" ||
                question.name === "copie_autorisation_ministre" ||
                question.name ===
                  "establishment_director_data_ministerial_auth_date" ||
                question.name ===
                  "establishment_director_date_expiration_passeport"
              ) {
                question.visible = true;
              }
            }
          });
        } else {
          // question.readOnly = true;
          if (question.name === "establishment_director_data_nationality") {
            const isTunisian = question.value === "تونسية";
            const tunisianVisibleQuestions = [
              "establishment_director_data_nationality",
              "establishment_director_data_identity",
              "establishment_director_data_cin_number",
              "establishment_director_data_birth_date",
              "director_search",
            ];

            const nonTunisianQuestions = [
              // "establishment_director_data_test_valid",
              "establishment_director_data_passeport_copie",
              "copie_autorisation_ministre",
              "establishment_director_data_ministerial_auth_date",
              "establishment_director_data_passeport",
              "establishment_director_date_expiration_passeport",
            ];

            const alwaysVisibleQuestions = [
              "establishment_director_data_test_valid",
              "establishment_director_data_passeport_copie",
              "copie_autorisation_ministre",
              "establishment_director_data_cin_copie",
              "establishment_director_data_date_delivrance",
              "establishment_director_data_ministerial_auth_date",
              "establishment_director_data_passeport",
              "establishment_director_data_cin_number",
              "establishment_director_data_spinner",
              "director_search",
            ];

            const page = surveyEdit.getPageByName(
              "Données de Directeur de l’Établissement"
            );

            page.questions.forEach((question) => {
              question.visible = false;

              if (isTunisian) {
                question.visible = tunisianVisibleQuestions.includes(
                  question.name
                );
                if (
                  !dataSurvey?.response?.data
                    ?.establishment_director_data_edited
                ) {
                  question.readOnly = !tunisianVisibleQuestions.includes(
                    question.name
                  );
                }
              } else {
                const nationalityIdentity = [
                  "establishment_director_data_nationality",
                  "establishment_director_data_identity",
                ];
                question.readOnly = !dataSurvey?.response?.data
                  ?.establishment_director_data_edited
                  ? !nationalityIdentity.includes(question.name)
                  : false;

                question.visible = nonTunisianQuestions.includes(question.name);
              }

              if (
                !alwaysVisibleQuestions.includes(question.name) &&
                !isTunisian
              ) {
                question.visible = true;
              }
            });

            if (!isTunisian) {
              surveyEdit.setValue(
                "establishment_director_data_test_valid",
                true
              );
            } else {
              surveyEdit.clearValue("establishment_director_data_passeport");
              surveyEdit.clearValue(
                "establishment_director_data_passeport_copie"
              );
            }
          }

          if (surveyEdit.getValue("establishment_director_data_test_valid")) {
            page.questions.map((question) => {
              if (sessionStorage.getItem("demande") === "true") {
                question.readOnly = true;
              }

              if (
                question.name !== "establishment_director_data_test_valid" &&
                question.name !==
                  "establishment_director_data_passeport_copie" &&
                question.name !== "copie_autorisation_ministre" &&
                question.name !== "establishment_director_data_cin_copie" &&
                question.name !==
                  "establishment_director_data_date_delivrance" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "establishment_director_data_passeport" &&
                question.name !== "establishment_director_data_cin_number" &&
                question.name !== "establishment_director_data_spinner" &&
                question.name !== "director_search" &&
                question.name !==
                  "establishment_director_date_expiration_passeport"
              ) {
                question.visible = true;
              }
              if (
                surveyEdit.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value === "تونسية"
              ) {
                const tunisianVisibleQuestions = [
                  "establishment_director_data_nationality",
                  "establishment_director_data_identity",
                  "establishment_director_data_cin_number",
                  "establishment_director_data_birth_date",
                  "director_search",
                ];

                question.readOnly =
                  sessionStorage.getItem("demande") === "false"
                    ? !dataSurvey?.response?.data
                        ?.establishment_director_data_edited
                      ? !tunisianVisibleQuestions.includes(question.name)
                      : false
                    : true;

                if (
                  question.name === "establishment_director_data_cin_copie" ||
                  question.name ===
                    "establishment_director_data_date_delivrance" ||
                  question.name === "director_search"
                ) {
                  question.visible = true;
                }
              } else if (
                surveyEdit.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value !== "تونسية"
              ) {
                if (
                  // question.name === "establishment_director_data_test_valid" ||
                  question.name ===
                    "establishment_director_data_passeport_copie" ||
                  question.name === "copie_autorisation_ministre" ||
                  question.name ===
                    "establishment_director_data_ministerial_auth_date" ||
                  question.name ===
                    "establishment_director_date_expiration_passeport"
                ) {
                  question.visible = true;
                }
              }
              if (
                surveyEdit.getQuestionByName(
                  "establishment_director_data_nationality"
                ).value === "تونسية"
              ) {
                const genre = surveyEdit.getQuestionByName(
                  "establishment_director_data_genre"
                );
                const lieuNaiss = surveyEdit.getQuestionByName(
                  "establishment_director_data_birth_place"
                );
                const nom_ar = surveyEdit.getQuestionByName(
                  "establishment_director_data_last_name"
                );
                const prenom_ar = surveyEdit.getQuestionByName(
                  "establishment_director_data_first_name"
                );
                nom_ar.readOnly = true;
                prenom_ar.readOnly = true;
                genre.readOnly = true;
                lieuNaiss.readOnly = true;
                const name_fr = surveyEdit.getQuestionByName(
                  "establishment_director_data_last_name_fr"
                );
                const prenom_fr = surveyEdit.getQuestionByName(
                  "establishment_director_data_first_name_fr"
                );
                if (dataSurvey?.response?.data.establishment_data_names_fr) {
                  name_fr.readOnly = true;
                  prenom_fr.readOnly = true;
                }
              }
            });
          }
        }
      }

      if (question.name === "management_data") {
        const dynamicPanel = question;
        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "management_data_staff_residence_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }

          const nationalityQuestion = panel.getQuestionByName(
            "management_data_staff_nationality"
          );
          nationalityQuestion.choices = nationalityList;
          const govermentQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );

          govermentQuestion.choices = gouvernoratList;

          panel.getQuestions().forEach(async (question) => {
            if (
              question.name !== "management_data_staff_cin_number" &&
              question.name !== "management_data_staff_passport_number" &&
              question.name !== "management_data_staff_date_delivrance" &&
              question.name !== "management_data_staff_cin_copy" &&
              question.name !== "management_data_staff_passport_copy" &&
              question.name !== "management_data_staff_valid" &&
              question.name !== "management_data_check_spinner" &&
              question.name !== "management_data_search" &&
              question.name !==
                "management_data_staff_date_expiration_passeport"
            ) {
              question.visible = true;
            }

            if (
              panel.getQuestionByName("management_data_staff_nationality")
                .value === "تونسية"
            ) {
              if (
                question.name === "management_data_staff_cin_copy" ||
                question.name === "management_data_staff_date_delivrance"
              ) {
                question.visible = true;
              }
            } else if (
              panel.getQuestionByName("management_data_staff_nationality")
                .value !== "تونسية"
            ) {
              if (
                question.name === "management_data_staff_passport_copy" ||
                question.name ===
                  "management_data_staff_date_expiration_passeport"
              ) {
                question.visible = true;
              }
            }
            const nom_fr = panel.getQuestionByName(
              "management_data_staff_last_name_fr"
            );
            const prenom_fr = panel.getQuestionByName(
              "management_data_staff_first_name_fr"
            );

            const surveyData = await getSurveyData();
            if (surveyData?.response?.data?.management_data_names_fr) {
              nom_fr.readOnly = surveyData?.response?.data
                ?.management_data_names_fr[
                surveyEdit
                  .getQuestionByName("management_data")
                  .panels.indexOf(panel)
              ]
                ? surveyData?.response?.data?.management_data_names_fr[
                    surveyEdit
                      .getQuestionByName("management_data")
                      .panels.indexOf(panel)
                  ]
                : false;
              prenom_fr.readOnly = surveyData?.response?.data
                ?.management_data_names_fr[
                surveyEdit
                  .getQuestionByName("management_data")
                  .panels.indexOf(panel)
              ]
                ? surveyData?.response?.data?.management_data_names_fr[
                    surveyEdit
                      .getQuestionByName("management_data")
                      .panels.indexOf(panel)
                  ]
                : false;
            }
          });
        });
      }

     
    });
    const current_step = sessionStorage.getItem("current_step");
    if (current_step) {
      surveyEdit.currentPageNo = parseInt(current_step);
    }
  };

  const populateDelegationOnLoad = () => {
    surveyEdit.getAllQuestions().forEach(async (question) => {
      if (question.name?.includes("_governorate")) {
        const delegateKey = question.name.replace(
          "_governorate",
          "_delegation"
        );
        const selectedGouvernorat = question.value;
        const selectedDelegate = surveyEdit.getQuestionByName(delegateKey);
        const id_goverment = gouvernoratList.find(
          (e) => e.value === selectedGouvernorat
        )?.id;
        if (id_goverment) {
          const delegations = await fetchDelegationList(id_goverment);

          selectedDelegate.choices = delegations || [];
        }
      }

      if (question.name?.includes("_delegation")) {
        const selectedDelegation = question.value;
        const postalCodeQuestion = surveyEdit.getQuestionByName(
          question.name.replace("_delegation", "_postal_code")
        );

        const idDelegation = listAllDelegations.find(
          (elem) => elem.value === selectedDelegation
        )?.id;

        if (idDelegation && postalCodeQuestion) {
          const listPostalCodes = await fetchCodePostalList(idDelegation);
          if (listPostalCodes) {
            postalCodeQuestion.choices = listPostalCodes.map((code) => ({
              value: code.value,
              text: code.text,
            }));
          } else {
            postalCodeQuestion.choices = [];
          }
        }
      }

      if (question.name === "management_data") {
        const dynamicPanel = question;
        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "management_data_staff_residence_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }
        });
      }

      if (question.name === "trainer_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "trainer_data_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "trainer_data_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }
        });
      }
    });
  };

  const getData = async () => {
    const data = await getSurveyData();
    setIsLoading(true);

    if (data) {
      let id_edit = `id_affaire_edit_${sessionStorage.getItem(
        "selectedOption"
      )}`;

      if (
        !sessionStorage.getItem(id_edit) &&
        sessionStorage.getItem("demande") !== "true"
      ) {
        // sessionStorage.setItem(
        //   `id_affaire_edit_${params?.state?.selectedOption}`,
        //   params?.state?.id_affaire
        // );
        const payload = {
          id_affaire: params?.state?.id_affaire,
          data_form_prod: JSON.stringify(data?.response?.data),
          ligne_produit: params?.state?.ligne_produit,
          etat_affaire:
            params?.state?.selectedOption === "sans_bo_sans_validation"
              ? import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION
              : import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
        };

        axiosInstance
          .post(import.meta.env.VITE_APP_GEOPROD_API + "/update_EPF", payload, {
            headers: {
              Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                ?.Acces_Token,
            },
          })
          .then((res) => {
            sessionStorage.setItem(id_edit, res?.data?.new_affaire);
            setIsLoading(false);
          })
          .catch(() => {
            navigate("/etablissements");
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    }
  };

  const checkDirectorIdentity = (value) => {
    if (value[0] === "المستثمر نفسه") {
      const page = surveyEdit.getPageByName(
        "Données de Directeur de l’Établissement"
      );

      let cin = surveyEdit.data.investor_cin;
      let passeport = surveyEdit.data.investor_passport;
      let nationality = surveyEdit.data.investor_nationality;

      let payload = {
        role: import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR_DIRCETEUR,
      };
      if (nationality === "تونسية") {
        payload.cin = cin;
      } else {
        payload.passport = passeport;
      }
      page.questions.map((question) => {
        if (question.name !== "establishment_director_data_identity")
          question.clearValue()
      });
      axiosInstance
        .post(
          `${import.meta.env.VITE_APP_GEOPROD_API}/admin/check_cin_role`,
          payload
        )
        .then((res) => {
          if (res.data.cin_pass) {
            surveyEdit.setValue(
              "establishment_director_data_test_valid",
              false
            );

            page.questions.map((question) => {
              if (question.name !== "establishment_director_data_identity")
                question.readOnly = true;
            });
            notification.error({
              message: t("Erreur"),
              description: t(
                "Le directeur ajouté est fonctionnel dans un autre EPF."
              ),
              duration: 0,
            });
            return;
          } else {
            surveyEdit.setValue("establishment_director_data_test_valid", true);
            page.questions.forEach((question) => {
              if (
                question.name !== "establishment_director_data_identity" &&
                question.name !==
                  "establishment_director_data_experience_years" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "copie_autorisation_ministre"
              ) {
                question.readOnly = true;
              } else {
                question.readOnly = false;
              }

              if (
                question.name !== "establishment_director_data_test_valid" &&
                question.name !==
                  "establishment_director_data_passeport_copie" &&
                question.name !== "copie_autorisation_ministre" &&
                question.name !== "establishment_director_data_cin_copie" &&
                question.name !==
                  "establishment_director_data_date_delivrance" &&
                question.name !==
                  "establishment_director_data_ministerial_auth_date" &&
                question.name !== "establishment_director_data_passeport" &&
                question.name !== "establishment_director_data_cin_number" &&
                question.name !== "establishment_director_data_spinner" &&
                question.name !== "director_search" &&
                question.name !==
                  "establishment_director_date_expiration_passeport"
              ) {
                question.visible = true;
              } else {
                question.visible = false;
              }
              if (surveyEdit.data.investor_nationality === "تونسية") {
                if (
                  question.name === "establishment_director_data_cin_copie" ||
                  question.name ===
                    "establishment_director_data_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (surveyEdit.data.investor_nationality !== "تونسية") {
                if (
                  question.name ===
                    "establishment_director_data_passeport_copie" ||
                  question.name === "copie_autorisation_ministre" ||
                  question.name ===
                    "establishment_director_data_ministerial_auth_date" ||
                  question.name ===
                    "establishment_director_date_expiration_passeport"
                ) {
                  question.visible = true;
                }
              }
            });
            surveyEdit.setValue("establishment_director_data_test_valid", true);
            surveyEdit.setValue(
              "establishment_director_data_last_name",
              surveyEdit.data.investor_last_name_ar
            );
            surveyEdit.setValue(
              "establishment_director_data_last_name_fr",
              surveyEdit.data.investor_last_name_fr
            );
            surveyEdit.setValue(
              "establishment_director_date_expiration_passeport",
              surveyEdit.data.investor_date_expiration_passeport
            );
            
            
            surveyEdit.setValue(
              "establishment_director_data_date_delivrance",
              surveyEdit.data.investor_date_delivrance
            );
            surveyEdit.setValue(
              "establishment_director_data_genre",
              surveyEdit.data.investor_civility
            );
            surveyEdit.setValue(
              "establishment_director_data_passeport",
              surveyEdit.data.investor_passport
            );
            surveyEdit.setValue(
              "establishment_director_data_passeport_copie",
              surveyEdit.data.investor_copie_passeport
            );

            surveyEdit.setValue(
              "establishment_director_data_cin_copie",
              surveyEdit.data.investor_copie_cin
            );
            surveyEdit.setValue(
              "establishment_director_data_director_folder",
              surveyEdit.data.investor_copie_cv
            );
            surveyEdit.setValue(
              "establishment_director_data_first_name",
              surveyEdit.data.investor_first_name_ar
            );

            surveyEdit.setValue(
              "establishment_director_data_first_name_fr",
              surveyEdit.data.investor_first_name_fr
            );

            surveyEdit.setValue(
              "establishment_director_data_email",
              surveyEdit.data.investor_email
            );
            surveyEdit.setValue(
              "establishment_director_data_education_level",
              surveyEdit.data.investor_eduction_level
            );
            surveyEdit.setValue(
              "establishment_director_data_nationality",
              surveyEdit.data.investor_nationality
            );
            surveyEdit.setValue(
              "establishment_director_data_birth_date",
              surveyEdit.data.investor_birth_date
            );
            surveyEdit.setValue(
              "establishment_director_data_birth_place",
              surveyEdit.data.investor_birth_place
            );
            surveyEdit.setValue(
              "establishment_director_data_specific_needs",
              surveyEdit.data.investor_specific_needs
            );
            surveyEdit.setValue(
              "establishment_director_data_cin_number",
              surveyEdit.data.investor_cin
            );
            surveyEdit.setValue(
              "establishment_director_data_passport",
              surveyEdit.data.investor_passport
            );
            surveyEdit.setValue(
              "establishment_director_data_address",
              surveyEdit.data.investor_residence_address
            );
            surveyEdit.setValue(
              "establishment_director_data_governorate",
              surveyEdit.data.investor_residence_governorate
            );
            surveyEdit.setValue(
              "establishment_director_data_delegation",
              surveyEdit.data.investor_residence_delegation
            );
            surveyEdit.setValue(
              "establishment_director_data_phone_number",
              surveyEdit.data.investor_phone_number
            );
          }
        });
    } else {
      const page = surveyEdit.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (page) {
        page.questions.forEach((question) => {
          surveyEdit.setValue(
            "establishment_director_data_nationality",
            "تونسية"
          );
          const valid = surveyEdit.getQuestionByName(
            "establishment_director_data_test_valid"
          );
          valid.value = false;

          if (question.name !== "establishment_director_data_identity") {
            surveyEdit.clearValue(question.name);
          }

          if (
            question.name !== "establishment_director_data_nationality" &&
            question.name !== "establishment_director_data_identity" &&
            question.name !== "establishment_director_data_passeport" &&
            question.name !== "establishment_director_data_cin_number" &&
            question.name !== "establishment_director_data_birth_date" &&
            question.name !== "director_search"
          ) {
            question.visible = false;
          }
          question.readOnly = false;
        });
      }
    }
  };

  const getTrainerWithCin = async (survey, name) => {
    const trainer_data = survey.getQuestionByName("trainer_data");
    trainer_data?.panels.map(async (panel) => {
      const spinner = panel.getQuestionByName("cin_check_spinner");
      const active = panel.getQuestionByName("trainer_active");
      const changedData = panel.getValue();
      const trainerCin = changedData.trainer_data_cin_number;
      const trainerPassport = changedData.trainer_data_passport;
      const trainerNationality = changedData.trainer_data_nationality;

      try {
        spinner.visible = true;
        const params = {
          nationality: trainerNationality,
          cin: null,
          passport: null,
        };

        if (trainerNationality === "تونسية") {
          params.cin = trainerCin;
        } else {
          params.passport = trainerPassport;
        }

        const data = await getTrainerByNationalityAndIdentity(params);

        const excludedQuestions = [
          "trainer_data_nationality",
          "trainer_data_passport",
          "trainer_data_cin_number",
        ];

        if (!data) {
          survey.setValue("trainer_data_test_valid", false);

          spinner.visible = false;
          notification.error({
            message: t("Erreur"),
            description:
              trainerNationality === "تونسية"
                ? t(
                    "Le numéro de CIN du formateur n'est pas associé à un compte sur la plateforme."
                  )
                : t(
                    "Le numéro de passeport du formateur n'est pas associé à un compte sur la plateforme."
                  ),
            duration: 0,
          });

          panel.getQuestions().forEach((question) => {
            if (!excludedQuestions.includes(question.name)) {
              question.visible = false;
            }
          });
        } else {
          survey.setValue("trainer_data_test_valid", true);

          if (data.first_login === 1) {
            active.value = false;
            spinner.visible = false;

            notification.error({
              message: t("Erreur"),
              description: t("Le compte du formateur n'est pas activé."),
              duration: 0,
            });
          } else {
            active.value = true;
            panel.getQuestions().forEach((question) => {
              if (
                question.name !== "trainer_data_cin" &&
                question.name !== "trainer_data_cin_number" &&
                question.name !== "trainer_data_cin_date_delivrance" &&
                question.name !== "trainer_data_passeport" &&
                question.name !== "trainer_data_folder" &&
                question.name !== "trainer_data_test_valid" &&
                question.name !== "trainer_active" &&
                question.name !== "trainer_data_passport" &&
                question.name !== "dossier_autoriser" &&
                question.name !== "trainer_date_expiration_passeport" &&
                question.name !== "trainer_data_date_fin_contrat"
              ) {
                question.visible = true;
              }

              if (
                panel.getQuestionByName("trainer_data_nationality").value ===
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_cin" ||
                  question.name === "trainer_data_cin_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (
                panel.getQuestionByName("trainer_data_nationality").value !==
                "تونسية"
              ) {
                if (
                  question.name === "trainer_data_passeport" ||
                  question.name === "trainer_date_expiration_passeport"
                ) {
                  question.visible = true;
                }
              }
            });
            const delegationQuestion = panel.getQuestionByName(
              "trainer_data_delegation"
            );

            const id_goverment = gouvernoratList.find(
              (e) => e.value === data?.goverment
            )?.id;

            const delegations = await fetchDelegationList(id_goverment);

            delegationQuestion.choices = delegations || [];
            spinner.visible = false;

            if (delegations) {
              panel.getQuestionByName("trainer_data_last_name").value =
                data?.nom_ar || "";
              panel.getQuestionByName("trainer_data_first_name").value =
                data?.prenom_ar || "";
              panel.getQuestionByName("trainer_data_email").value =
                data?.mail || "";
              panel.getQuestionByName("trainer_data_birth_date").value =
                dayjs(data?.date_naiss_user) || "";
              panel.getQuestionByName("trainer_data_civility").value =
                data?.civilite;
              panel.getQuestionByName("trainer_data_phone_number").value =
                data?.mobile || "";
              panel.getQuestionByName("trainer_data_address").value =
                data?.adresse || "";
              panel.getQuestionByName("trainer_data_birth_place").value =
                data?.lieu_naissance || "";
              panel.getQuestionByName(
                "trainer_date_expiration_passeport"
              ).value = dayjs(data?.expiration_passeport, "DD/MM/YYYY") || "";
              panel.getQuestionByName("trainer_data_governorate").value =
                data?.goverment || "";
              panel.getQuestionByName("trainer_data_delegation").value =
                data?.delegation || "";
              panel.getQuestionByName("trainer_data_postal_code").value =
                data?.code_postal || "";
              panel.getQuestionByName("trainer_data_specialty_domain").value =
                data?.domaines_specialite || "";
              panel.getQuestionByName("trainer_data_education_level").value =
                data?.niveau_scolaire || "";
              panel.getQuestionByName(
                "trainer_data_cin_date_delivrance"
              ).value = dayjs(data?.delivrance_date, "DD/MM/YYYY") || "";
              panel.getQuestionByName("trainer_data_specific_needs").value =
                data?.besoins_specifiques;

              const apiUrl = `${
                import.meta.env.VITE_APP_GEOPROD_API
              }/document_formateur/getByCinPassport`;
              const param =
                trainerNationality === "تونسية"
                  ? `cin=${trainerCin}`
                  : `passport=${trainerPassport}`;
              axiosInstance
                .get(
                  `${apiUrl}?${param}`,

                  {
                    headers: {
                      Idsession: JSON.parse(
                        sessionStorage.getItem("-x-token-user")
                      ).Acces_Token,
                    },
                  }
                )
                .then((res) => {
                  if (trainerNationality === "تونسية") {
                    const response_file = res?.data.response?.find(
                      (e) => e.key === "copie_cin"
                    );
                    let fileType = mime.getType(response_file?.name);
                    const file = [
                      {
                        content: response_file.url,
                        name: response_file?.name,
                        type: fileType,
                      },
                    ];
                    panel.getQuestionByName("trainer_data_cin").value = file;
                    panel.getQuestionByName("trainer_data_cin").visible = true;
                  } else {
                    const response_file = res?.data.response?.find(
                      (e) => e.key === "copie_passeport"
                    );

                    const file = [
                      {
                        content: response_file.url,
                        name: response_file?.name,
                        type: mime.getType(response_file?.name),
                      },
                    ];
                    panel.getQuestionByName("trainer_data_passeport").value =
                      file;
                    panel.getQuestionByName(
                      "trainer_data_passeport"
                    ).visible = true;
                  }

                  if (data?.autoriser_dossier === "نعم") {
                    const response_file = res?.data.response?.find(
                      (e) => e.key === "dossier_file"
                    );
                    const file = [
                      {
                        content: response_file.url,
                        name: response_file?.name,
                        type: mime.getType(response_file?.name),
                      },
                    ];
                    panel.getQuestionByName("trainer_data_folder").value = file;
                    panel.getQuestionByName(
                      "trainer_data_folder"
                    ).visible = true;
                    panel.getQuestionByName("dossier_autoriser").value = true;
                  } else {
                    panel.getQuestionByName("dossier_autoriser").value = false;
                  }
                });
            }
          }
        }
      } catch (error) {
        spinner.visible = false;
        notification.error({
          message: t("Erreur"),
          description: error?.response?.data?.message || "An error occurred.",
          duration: 0,
        });
      }
    });
  };

  const handleClickOutside = async (event) => {
    let json = getSurveyJson();
    const data_for_comparison = JSON.parse(
      sessionStorage.getItem("data_for_comparison")
    );
    const trainer_and_training_data = JSON.parse(
      sessionStorage.getItem("trainer_and_training_data")
    );

    const payload = {
      current_step: surveyEdit.currentPageNo,
      json_prod: {
        ...surveyEdit.data,
        data_for_comparison: data_for_comparison,
        trainer_and_training_data: trainer_and_training_data,
      },
      model: json,
    };

    sessionStorage.setItem("payload", JSON.stringify(payload));
    sessionStorage.removeItem("current_step");
  };

  useEffect(() => {
    if (editJson !== null) {
      surveyEdit?.setJsonObject({
        ...editJson,
      });
     

      onValueChanging(
        surveyEdit,
        gouvernoratList,
        listAllDelegations,
        "edit",
        getSurveyData,
        setSurveyJson,
        getSurveyJson,
        setSurveyData,
        params?.state?.type
      );
      onDynamicPanelItemValueChanged(
        surveyEdit,
        secteurData,
        nationalityList,
        gouvernoratList,
        "edit",
        getSurveyData,
        setSurveyData
      );
      const selectedOption = sessionStorage.getItem("selectedOption");
      onUploadFiles(surveyEdit, "modification", selectedOption);
      onCurrentPageChanging(surveyEdit, "modification", null, getSurveyData);
      onShowingPreview(surveyEdit, setShowingPreview);
      onErrorCustomText(surveyEdit);

      surveyEdit.onAfterRenderQuestion.add(function (sender, options) {
        if (
          options.question.name === "investor_nationality" ||
          options.question.name === "inverstor_type" ||
          options.question.name === "establishment_data_email" ||
          options.question.name === "training_type_data_sub_sector" ||
          options.question.name === "establishment_director_data_nationality"
        ) {
          displayQuestionsFunction();
        }
        if (options.question.getType() === "file") {
          setTimeout(() => {
            modifyFileLinks(surveyEdit, options);
          }, [1000]);
        }
        if (options.question.title.includes("(En arabe)")) {
          options.htmlElement.style.direction = "rtl";
        }
        const currentPage = surveyEdit.currentPage;

        currentPage.questions.forEach((question) => {
          if (
            question.name.includes("_valid") ||
            question.name.includes("_validate")
          ) {
            question.visible = false;
          }

          if (sender.isShowingPreview) {
            const managementPanel = sender.getQuestionByName("management_data");
            managementPanel?.panels.forEach((panel) => {
              const dateDelivranceQuestion = panel.getQuestionByName(
                "management_data_staff_date_delivrance"
              );
              const birthDateQuestion = panel.getQuestionByName(
                "management_data_staff_birth_date"
              );
              const search_button = panel.getQuestionByName(
                "management_data_search"
              );
              if (dateDelivranceQuestion)
                dateDelivranceQuestion.readOnly = true;
              if (birthDateQuestion) birthDateQuestion.readOnly = true;
              if (search_button) search_button.readOnly = true;
            });
            const trainer_data = sender.getQuestionByName("trainer_data");
            trainer_data?.panels.forEach((panel) => {
              const trainer_data_cin_number = panel.getQuestionByName(
                "trainer_data_cin_number"
              );

              if (trainer_data_cin_number)
                trainer_data_cin_number.readOnly = true;
            });

            if (question.inputType === "api") {
              question.readOnly = true;
            }
          }
        });

        const fileInputButton =
          options?.htmlElement?.querySelector(".sd-file__wrapper");
        if (fileInputButton) {
          const spanElement = fileInputButton?.querySelector("span");
          if (spanElement) {
            spanElement.textContent = "Télécharger le fichier - تحميل الملف";
          }
        }
        const fileInputButtonBeforeChoose = options?.htmlElement?.querySelector(
          ".sd-file__choose-btn"
        );
        if (fileInputButtonBeforeChoose) {
          const spanElement =
            fileInputButtonBeforeChoose?.querySelector("span");
          if (spanElement) {
            spanElement.textContent = "Télécharger le fichier - تحميل الملف";
          }
        }

        const emptyTextDiv = options?.htmlElement?.querySelector(
          ".sv-list__empty-text"
        );
        if (
          (options.question.name.includes("_delegation") ||
            options.question.name.includes("_postal_code") ||
            options.question.name.includes("_sub_sector") ||
            options.question.name.includes("_specialty")) &&
          emptyTextDiv
        ) {
          emptyTextDiv.textContent = t(
            "Veuillez d'abord remplir le champ précédent !"
          );
        }

        const placeholderNoFile = options?.htmlElement?.querySelector(
          ".sd-file__decorator"
        );
        if (placeholderNoFile) {
          const placeholder = placeholderNoFile?.querySelector(
            ".sd-file__drag-area-placeholder"
          );
          if (placeholder) {
            const spanElement = placeholder?.querySelector(".sv-string-viewer");
            if (
              spanElement &&
              (spanElement?.textContent === "No file selected" ||
                spanElement?.textContent === "Aucun fichier ajouté")
            ) {
              spanElement.textContent =
                "Aucun fichier téléchargé - لم يتم تحميل أي ملف";
            }
          }
        }
      });

      surveyEdit.onDynamicPanelRemoving.add((survey, options) => {
        const panelDynamic = survey.getQuestionByName("training_nature_data");
        if (survey.currentPage.name === "page4") {
          if (panelDynamic.panelCount <= 1) {
            options.allow = false;
            notification.error({
              title: "Erreur",
              description: t(
                "Il est impossible de supprimer tous les types de formations, car cela entraînerait la fermeture de l'EPF."
              ),
              duration: 0,
            });
          }
        }
        if (survey.currentPage.name === "page5") {
          const panle_cin = options.panel.getQuestionByName(
            "trainer_data_cin_number"
          );
          const panel_passeport = options.panel.getQuestionByName(
            "trainer_data_passport"
          );

          const trainer_and_training_data = JSON.parse(
            sessionStorage.getItem("trainer_and_training_data")
          );
          
          const trainer_already_exists =
            (panel_passeport.value || panle_cin.value) &&
            trainer_and_training_data.trainer_data?.find(
              (trainer) =>
                trainer.trainer_data_passport === panel_passeport.value ||
                trainer.trainer_data_cin_number === panle_cin.value
            );
          if (
            trainer_already_exists &&
            Object.keys(trainer_already_exists).length > 0 &&
            trainer_already_exists.trainer_data_situation === "قار"
          ) {
            options.allow = false;
            setDateFinContrat({
              panel: options,
              trainer: true,
            });
          }
        }
      });

      surveyEdit.onCompleting.add(async (sender, options) => {
        options.allowComplete = false;
        sender.allowComplete = false;
        if (
          params?.state?.type === "annexe" &&
          sessionStorage.getItem("selectedOption") === "avec_bo_validation" &&
          (sessionStorage.getItem("establishment_data_address_changed") ===
            "false" ||
            !sessionStorage.getItem("establishment_data_address_changed"))
        ) {
          notification.error({
            message: t("Aucune modification détectée"),
            description: t(
              "Votre demande ne peut pas être soumise, car aucune modification n'a été constatée. Veuillez apporter des changements avant de réessayer"
            ),
            duration: 0,
          });
        } else {
          setIsLoading(true);

          const typeOneTwo = sessionStorage.getItem("typeOneTwo");
          const json = getSurveyJson();
          const payload = {
            additional_form: "{}",
            json_prod: {
              ...surveyEdit.data,
              modification_avec_sans_bo: typeOneTwo === "true",
            },
            model: json,
          };
          const data = await saveAffaire(
            payload,
            "SoumettreModification",
            setIsLoading,
            "edit"
          );
          if (data) {
            setIsLoading(false);
            sessionStorage.removeItem(`id_affaire_edit_${selectedOption}`);
            navigate("/demandes");
            sessionStorage.removeItem("establishment_data_address_changed");
            setShowingPreview(false);
          } else {
            setIsLoading(false);

            notification.error({
              description: t("Enregistrement échoué, merci de réessayer."),
            });
          }
        }

       
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "datepicker",
        title: "Date picker",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question?.jsonObj?.inputType === "date"
          );
        },
        render: function (question, element) {
          return (
            <DatePikckerComponent question={question} survey={surveyEdit} />
          );
        },
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "refreshbar",
        title: "Refresh Bar",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question?.jsonObj?.inputType === "refreshbar"
          );
        },
        render: function (question, element) {
          return <RefreshBarComponent question={question} />;
        },
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "callApi",
        title: "callApi",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question?.jsonObj?.inputType === "api"
          );
        },
        render: function (question, element) {
          const fieldName = question.name;

          const mapping = expressionsMap[fieldName];

          if (!mapping) {
            return null;
          }

          const { expression, apiCall, minLength, maxLength, regex, message } =
            mapping;

          if (typeof apiCall !== "function") {
            return null;
          }

          const handleClick = () => {
            const survey_ = question.survey;
            return apiCall(survey_);
          };

          return (
            <SearchInputComponent
              question={question}
              expression={expression}
              apiCall={handleClick}
              minLength={minLength}
              maxLength={maxLength}
              regex={regex}
              message={message}
              survey={surveyEdit}
            />
          );
        },
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "Button",
        title: "Button",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question?.jsonObj?.inputType?.toLowerCase() === "button"
          );
        },
        render: function (question, element) {
          return <ButtonComponent question={question} />;
        },
      });

      FunctionFactory.Instance.register(
        "checkDirectorIdentity",
        checkDirectorIdentity
      );

      sessionStorage.setItem("consulter", false);
      getData();
      getAllData();
      if (
        sessionStorage.getItem("demande") === "false" ||
        !sessionStorage.getItem("demande")
      ) {
        document.addEventListener("mousedown", handleClickOutside);
      }
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      surveyEdit.dispose(true, true);
      surveyEdit = new Model();
    };
  }, []);

  const getAllData = async () => {
    const data = await getSurveyData();
    const currentData = {
      ...data?.response?.data,
    };

  
    surveyEdit.data = currentData;

    if (params?.state?.type === "annexe") {
      sessionStorage.setItem("annexe", params?.state?.type);
      const dircetorPage = surveyEdit.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (dircetorPage) {
        surveyEdit.setValue("establishment_director_data_test_valid", true);
        dircetorPage.title =
          "Données de Responsable de l'Annexe - بيانات مسؤول الفرع";
        dircetorPage.questions.map((question) => {
          if (
            question.name ===
              "establishment_director_data_ministerial_auth_date" ||
            question.name === "copie_autorisation_ministre"
          ) {
            question.visible = false;
          }
        });

        const identityField = surveyEdit.getQuestionByName(
          "establishment_director_data_identity"
        );
        if (identityField) {
          const choices = identityField.choices;
          if (choices && choices[0]) {
            choices[0].text =
              "Responsable autre que l’investisseur - مسؤول غير المستثمر";
          }
        }
      }

      const page2 = surveyEdit.getPageByName("page2");

      if (page2) {
        page2.title =
          "Données de l’établissement de formation (Annexe) - معطيات مؤسسة التكوين (الفرع)";
      }

      const questionAdr = surveyEdit.getQuestionByName(
        "establishment_data_address"
      );
      if (questionAdr) {
        questionAdr.title =
          "Adresse de l'annexe (En arabe) - عنوان الفرع (بالعربية)";
      }
      const legal_entity_cnss_registration_number =
        surveyEdit.getQuestionByName("legal_entity_cnss_registration_number");
      if (legal_entity_cnss_registration_number) {
        legal_entity_cnss_registration_number.visible = false;
      }
    } else {
      sessionStorage.setItem("annexe", null);
    }

    surveyEdit.getAllQuestions().forEach(async (question) => {
      if (question.name?.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;

        const delegateKey = question.name.replace(
          "_governorate",
          "_delegation"
        );
        const selectedGouvernorat = question.value;
        const selectedDelegate = surveyEdit.getQuestionByName(delegateKey);
        const id_goverment = gouvernoratList.find(
          (e) => e.value === selectedGouvernorat
        )?.id;
        if (id_goverment) {
          const delegations = await fetchDelegationList(id_goverment);

          selectedDelegate.choices = delegations || [];
        }
      }

      if (sessionStorage.getItem("demande") === "true") {
        question.readOnly = true;
        surveyEdit.showCompleteButton = false;
        surveyEdit.editText = "Consulter - الإطلاع";
      }

      if (question.name === "trainer_data") {
        question.panels.forEach((panel) => {
          panel.questions.forEach((question) => {
            if (question.name.toLowerCase().includes("_nationality")) {
              question.choices = nationalityList;
            }
            if (question.name.toLowerCase().includes("_governorate")) {
              question.choices = gouvernoratList;
            }
            if (sessionStorage.getItem("demande") === "true") {
              question.readOnly = true;
            }
          });

          const isTestValid = surveyEdit.getValue("trainer_data_test_valid");

          if (isTestValid) {
            panel.getQuestions().forEach(async (question) => {
              const questionName = question.name;

              const alwaysVisibleFields = [
                "trainer_data_cin",
                "trainer_data_cin_number",
                "trainer_data_cin_date_delivrance",
                "trainer_data_passeport",
                "trainer_data_folder",
                "trainer_data_test_valid",
                "trainer_active",
                "trainer_data_passport",
                "cin_check_spinner",
                "dossier_autoriser",
                "trainer_date_expiration_passeport",
                "trainer_data_date_debut_contrat",
                "trainer_data_date_fin_contrat",
              ];

              if (!alwaysVisibleFields.includes(questionName)) {
                question.visible = true;
              }

              if (
                panel.getQuestionByName("dossier_autoriser")?.value === true
              ) {
                if (questionName === "trainer_data_folder") {
                  question.visible = true;
                }
              }
              if (
                panel.getQuestionByName("trainer_data_situation") === "غير قار"
              ) {
                if (question.name === "trainer_data_date_debut_contrat") {
                  question.visible = false;
                }
              }

              const nationality = panel.getQuestionByName(
                "trainer_data_nationality"
              )?.value;

              if (nationality === "تونسية") {
                if (
                  [
                    "trainer_data_cin",
                    "trainer_data_cin_date_delivrance",
                  ].includes(questionName)
                ) {
                  question.visible = true;
                }
              } else if (nationality) {
                if (
                  questionName === "trainer_data_passeport" ||
                  question.name === "trainer_date_expiration_passeport"
                ) {
                  question.visible = true;
                }
              }
            });
          }
        });
      }

      if (question.name === "management_data") {
        const dynamicPanel = question;
        dynamicPanel?.panels.forEach(async (panel) => {
          const governorateQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );
          if (!governorateQuestion) return;
          const selectedGouvernorat = governorateQuestion.value;
          const delegateKey = "management_data_staff_residence_delegation";
          const selectedDelegate = panel.getQuestionByName(delegateKey);

          const id_goverment = gouvernoratList.find(
            (e) => e.value === selectedGouvernorat
          )?.id;
          if (id_goverment) {
            const delegations = await fetchDelegationList(id_goverment);

            selectedDelegate.choices = delegations || [];
          }

          const nationalityQuestion = panel.getQuestionByName(
            "management_data_staff_nationality"
          );
          nationalityQuestion.choices = nationalityList;
          const govermentQuestion = panel.getQuestionByName(
            "management_data_staff_residence_governorate"
          );

          govermentQuestion.choices = gouvernoratList;

          if (
            panel.getQuestionByName("management_data_staff_valid").value ===
              true ||
            panel.getQuestionByName("management_data_staff_nationality")
              .value !== "تونسية"
          ) {
            panel.getQuestions().forEach((question) => {
              if (
                question.name !== "management_data_staff_cin_number" &&
                question.name !== "management_data_staff_passport_number" &&
                question.name !== "management_data_staff_date_delivrance" &&
                question.name !== "management_data_staff_cin_copy" &&
                question.name !== "management_data_staff_passport_copy" &&
                question.name !== "management_data_staff_valid" &&
                question.name !== "management_data_check_spinner" &&
                question.name !== "management_data_search" &&
                question.name !==
                  "management_data_staff_date_expiration_passeport"
              ) {
                question.visible = true;
              }
              if (sessionStorage.getItem("demande") === "true") {
                question.readOnly = true;
              }

              if (
                panel.getQuestionByName("management_data_staff_nationality")
                  .value === "تونسية"
              ) {
                if (
                  question.name === "management_data_staff_cin_copy" ||
                  question.name === "management_data_staff_date_delivrance"
                ) {
                  question.visible = true;
                }
              } else if (
                panel.getQuestionByName("management_data_staff_nationality")
                  .value !== "تونسية"
              ) {
                if (
                  question.name === "management_data_staff_passport_copy" ||
                  question.name ===
                    "management_data_staff_date_expiration_passeport"
                ) {
                  question.visible = true;
                }
              }
            });
          }
        });
      }

      if (params?.state?.type === "annexe") {
        const trainer_data = surveyEdit.getPageByName("page5");
        trainer_data.visible = false;
        if (question.name === "establishment_data_training_type") {
          question.readOnly = true;
        }
        if (
          (question.name === "establishment_data_delegation" &&
            sessionStorage.getItem("selectedOption") ===
              "avec_bo_validation") ||
          (question.name === "establishment_data_postal_code" &&
            sessionStorage.getItem("selectedOption") === "avec_bo_validation")
        ) {
          question.readOnly = true;
        }

        if (question.name === "establishment_director_data_identity") {
          question.choices = [
            {
              value: "مدير غير المستثمر",
              text: "Responsable Autre que l’Investisseur - مسؤول غير المستثمر",
            },
            {
              value: "المستثمر نفسه",
              text: "Investisseur lui-même - المستثمر نفسه",
            },
          ];
        }
        if (question.name === "establishment_director_data_director_folder") {
          question.title =
            "Dossier du responsable de l'annexe (CV et autres) - ملف  المسؤول عن الفرع (السيرة الذاتية وغيرها)";
        }
      }

      if (question.name.toLowerCase().includes("_nationality")) {
        question.choices = nationalityList;
      }
      if (question.name === "training_nature_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const sectorQuestion = panel.getQuestionByName(
            "training_type_data_sector"
          );
          const subSectorQuestion = panel.getQuestionByName(
            "training_type_data_sub_sector"
          );
          const specialtyQuestion = panel.getQuestionByName(
            "training_type_data_specialty"
          );
          sectorQuestion.choices = secteurData;
          const selectedSector = sectorQuestion?.value;

          if (selectedSector) {
            const id = secteurData.find(
              (elem) => elem.value === selectedSector
            )?.id;
            const listSousSecteurs = await fetchSubSectorList(id);

            if (listSousSecteurs) {
              subSectorQuestion.choices = listSousSecteurs;
            } else {
              subSectorQuestion.choices = [];
            }

            const selectedSubSector = subSectorQuestion?.value;

            if (selectedSubSector) {
              const id = listSousSecteurs.find(
                (elem) => elem.value === selectedSubSector
              )?.id;
              const listSpecialiteDiplomes = await fetchSpetialityList(id);

              if (listSpecialiteDiplomes) {
                specialtyQuestion.choices = listSpecialiteDiplomes.map(
                  (item) => item[0].spetialite
                );
              } else {
                specialtyQuestion.choices = [];
              }
            }
          }
        
        });
      }
    });
    populateDelegationOnLoad();
    displayQuestionsFunction();

    const getRne = async () => {
      // vérifier la condition de   if (
       // surveyEdit.data.numero_etablissement_secondaire === "000" ||
       // surveyEdit.data.numero_etat_secondaire === "000"
    //  ) dans la partie validation provisoire
      const registreRne = await getExtraitRegistreEntrepriseParId(surveyEdit, data, t);
    
      if (registreRne) {
        surveyEdit.setValue(
          "establishment_data_commercial_name",
          registreRne?.nomCommercial ||
          registreRne?.nomCommercialLatin ||
            registreRne?.nomAssociation||registreRne?.nomAssociationLatin ||
           registreRne?.nomSociete ||  registreRne?.nomSocieteLatin
        );
      }
    };
    

    if (sessionStorage.getItem("selectedOption") === "avec_bo_validation") {
      getRne();
    }
  };

  return (
    <div>
      <ConfigProvider direction="ltr">
        <div style={{ direction: "ltr" }}>
          <Survey model={surveyEdit} ref={ref} />
        </div>
      </ConfigProvider>
      <DeleteBrouillonModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        typeAffaire="modification"
      />

      <ModalDateFinContrat
        dateFinContrat={dateFinContrat}
        setDateFinContrat={setDateFinContrat}
        surveyEdit={surveyEdit}
      />

      <Space className={classes.rowButtonCreate}>
        {sessionStorage.getItem("consulter") === "false" && (
          <Space>
            <Button
              className={classes.buttonCancel}
              onClick={() => setConfirmModal(true)}
            >
              <Trash2 strokeWidth={1.25} />
              {t("Annuler la demande")}
            </Button>
            <Button
              className={classes.buttonCreate}
              onClick={async () => {
                const json = getSurveyJson();
                const data_for_comparison = JSON.parse(
                  sessionStorage.getItem("data_for_comparison")
                );
                const trainer_and_training_data = JSON.parse(
                  sessionStorage.getItem("trainer_and_training_data")
                );
                const payload = {
                  current_step: surveyEdit.currentPageNo,
                  json_prod: {
                    ...surveyEdit.data,
                    data_for_comparison: data_for_comparison,
                    trainer_and_training_data: trainer_and_training_data,
                  },
                  model: json,
                };

                await saveAffaire(payload, "Brouillon", setIsLoading, "edit");
                sessionStorage.removeItem("current_step");
                setNbrBrouillon((prevDataSource) => {
                  if (prevDataSource >= 1) {
                    return prevDataSource + 1;
                  }
                });
                navigate("/brouillon");
              }}
            >
              <Save strokeWidth={1.25} />
              {t("Sauvegarder au brouillon")}
            </Button>{" "}
          </Space>
        )}
       
      </Space>
    </div>
  );
};

export default EditEpf;
