import classes from "./CustomLoader.module.css";
import PropTypes from "prop-types";

const CustomLoader = (props) => {
  const { text, transparent } = props;
  return (
    <div
      className={classes.loaderContainer}
      style={{
        position: transparent ? "fixed" : "none",
        top: transparent ? "0" : "none",
        left: transparent ? "0" : "none",
        width: transparent ? "100vw" : "none",
        height: text ? "60vh" : "100vh",
        backgroundColor: transparent ? "rgba(2, 79, 168, 0.4)" : "none",
        zIndex: transparent ? "1000" : "none",
      }}
    >
      <svg
        className={classes.loader}
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={classes.loaderCircle}
          cx="48"
          cy="48"
          r="45"
          stroke="#045cab"
          strokeWidth="6"
        />
        <circle cx="48" cy="48" r="36" stroke="#f69021" strokeWidth="6" />
      </svg>
      {text && <p className={classes.textLoader}>{text}</p>}
    </div>
  );
};

CustomLoader.propTypes = {
  text: PropTypes.string,
  transparent: PropTypes.bool,
};

export default CustomLoader;
