import { Menu, Row, Badge } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import GlobalContext from "../context/GlobalContext";
import { useContext } from "react";
import { saveAffaire } from "../api/geoprod";
import { useLoader } from "../context/LoaderContext";
import axiosInstance from "../utils/axios";
import {
  Building2,
  Files,
  FileUser,
  MessagesSquare,
  NotepadTextDashed,
  ScreenShareOff,
  Split,
  UserRoundPen,
  UsersRound,
  LayoutList,
  BookOpen,
  HistoryIcon,
  Loader as LoaderIcon,
  BookUser,
} from "lucide-react";
import PropTypes from "prop-types";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { t } = useTranslation();
  const role = sessionStorage.getItem("role");
  const navigate = useNavigate();
  const { form, listTasks, nbrBrouillon } = useContext(GlobalContext);
  const { setIsLoading, setSurveyJson, setShowingPreview } = useLoader();
  const user = JSON.parse(sessionStorage.getItem("-x-token-user"));

  const redirectRole =
    role === "investisseur-directeur" ? "investisseur" : role;

  const getType = (page) => {
    switch (page) {
      case "etablissements/existante/save":
        return "epfExistante";
      case "etablissements/edit":
        return "edit";
      case "investisseur/changer":
        return "changementInvestisseur";
      case "annexes/save":
        return "annexe";
      default:
        return null;
    }
  };

  const handleClickOutside = async () => {
    setShowingPreview(false);
    await saveIfNecessary(page);
    sessionStorage.removeItem("current_step");
  };

  const saveIfNecessary = async (page) => {
    const pagesToSave = [
      "etablissements/save",
      "etablissements/edit",
      "investisseur/changer",
      "etablissements/existante/save",
      "annexes/save",
    ];

    if (pagesToSave.includes(page)) {
      const payload = JSON.parse(sessionStorage.getItem("payload"));
      const type = getType(page);
      await saveAffaire(payload, "Brouillon", setIsLoading, type);
    }
  };

  const handleMenuClick = () => {
    setIsLoading(true);
    fetchFormData()
      .then(() => navigate("/userProfil"))
      .finally(() => setIsLoading(false));
  };

  const fetchFormData = async () => {
    try {
      const ligne_produit = import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO;
      const response = await axiosInstance.get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/admin-bpm/contrat/${ligne_produit}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      );

      let form = response?.data?.contrat?.form;
      if (role === "directeur") {
        form.pages = form?.pages.filter(
          (page) => page.name === "Données de Directeur de l’Établissement"
        );
      } else {
        form.pages = form?.pages.filter((page) => page.name === "page1");
      }
      setSurveyJson(form);
    } catch (error) {
      console.error("Error fetching form data:", error);
    }
  };

  const renderMenu = () => {
    if (role !== "formateur") {
      return (
        <Menu
          theme="light"
          mode="inline"
          defaultOpenKeys={["sub2", "20"]}
          onClick={handleMenuClickOutside}
        >
          {renderMenuItems()}
        </Menu>
      );
    }

    return (
      <div
        className="sider-menu-container"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Menu style={{ flex: 1 }}>{renderFormateurMenuItems()}</Menu>
      </div>
    );
  };

  const handleMenuClickOutside = () => {
    if (
      sessionStorage.getItem("consulter") === "true" ||
      sessionStorage.getItem("demande") === "true"
    ) {
      sessionStorage.removeItem("surveyData");
    } else {
      handleClickOutside();
    }
  };

  const renderMenuItems = () => (
    <>
      <Menu.SubMenu
        key="sub1"
        title={<span className="label">{t("Profil")}</span>}
        icon={<UserRoundPen strokeWidth={1.25} />}
      >
        <Menu.Item key="1" onClick={handleMenuClick}>
          <NavLink to="/userProfil" onClick={(e) => e.preventDefault()}>
            <span className="icon">
              <FileUser strokeWidth={1.25} />
            </span>
            <span className="label">{t("Mes Informations")}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/reclamation">
            <span
              className="icon"
              style={{ background: page === "rtl" ? color : "" }}
            >
              <MessagesSquare strokeWidth={1.25} />
            </span>
            <span className="label">{t("Réclamation")}</span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="2">
        <NavLink to="/etablissements">
          <span
            className="icon"
            style={{ background: page === "tables" ? color : "" }}
          >
            <Building2 strokeWidth={1.25} />
          </span>
          <span className="label">
            {role === "directeur"
              ? t("Mon établissement")
              : t("Mes établissements")}
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="10">
        <NavLink to="/taches">
          <span
            className="icon"
            style={{ background: page === "tables" ? color : "" }}
          >
            <LayoutList strokeWidth={1.25} />
          </span>
          <span className="label">
            {t("Mes tâches")}{" "}
            <Badge
              count={listTasks}
              overflowCount={99}
              style={{ backgroundColor: "#f5222d" }}
            />
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="22">
        <NavLink to="/annexes">
          <span
            className="icon"
            style={{ background: page === "tables" ? color : "" }}
          >
            <Split strokeWidth={1.25} />
          </span>
          <span className="label">
            {role !== "directeur" ? t("Mes annexes") : t("Mon annexe")}
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.SubMenu
        key="sub2"
        title={<span className="label">{t("Mes demandes")}</span>}
        icon={<Files strokeWidth={1.25} />}
      >
        <Menu.Item key="20">
          <NavLink to="/demandes">
            <span className="icon">
              <LoaderIcon strokeWidth={1.25} />
            </span>
            <span className="label">{t("En cours")}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="21">
          <NavLink to="/historique">
            <span
              className="icon"
              style={{ background: page === "rtl" ? color : "" }}
            >
              <HistoryIcon strokeWidth={1.25} />
            </span>
            <span className="label">{t("Historique")}</span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="4">
        <NavLink to="/brouillon">
          <span
            className="icon"
            style={{ background: page === "billing" ? color : "" }}
          >
            <NotepadTextDashed strokeWidth={1.25} />
          </span>
          <span className="label">
            {t("Brouillon")}{" "}
            <Badge
              count={nbrBrouillon}
              overflowCount={99}
              style={{ backgroundColor: "#f5222d" }}
            />
          </span>
        </NavLink>
      </Menu.Item>
      {role !== "directeur" && (
        <Menu.Item key="23">
          <NavLink to="/directeurs">
            <span
              className="icon"
              style={{ background: page === "rtl" ? color : "" }}
            >
              <BookUser strokeWidth={1.25} />
            </span>
            <span className="label">{t("Liste des directeurs")}</span>
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item key="11">
        <NavLink to="/formateurs">
          <span
            className="icon"
            style={{ background: page === "rtl" ? color : "" }}
          >
            <UsersRound strokeWidth={1.25} />
          </span>
          <span className="label">{t("Liste des formateurs")}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="12">
        <NavLink to="/formations">
          <span
            className="icon"
            style={{ background: page === "rtl" ? color : "" }}
          >
            <BookOpen strokeWidth={1.25} />
          </span>
          <span className="label">{t("Liste des formations")}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="6" onClick={handleLogout}>
        <NavLink to={`/signin?role=${redirectRole}`}>
          <span
            className="icon"
            style={{ background: page === "billing" ? color : "" }}
          >
            <ScreenShareOff strokeWidth={1.25} />
          </span>
          <span className="label">{t("Se déconnecter")}</span>
        </NavLink>
      </Menu.Item>
    </>
  );

  const renderFormateurMenuItems = () => (
    <>
      <Menu.Item key="2">
        <NavLink to="/profile">
          <span
            className="icon"
            style={{ background: page === "billing" ? color : "" }}
          >
            <UserOutlined />
          </span>
          <span className="label">{t("Mon profil")}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3" onClick={handleFormateurLogout}>
        <NavLink to="/signin?role=formateur">
          <span
            className="icon"
            style={{ background: page === "billing" ? color : "" }}
          >
            <LogoutOutlined />
          </span>
          <span className="label">{t("Se déconnecter")}</span>
        </NavLink>
      </Menu.Item>
    </>
  );

  const handleLogout = () => {
    handleClickOutside().then(() => {
      sessionStorage.clear();
      form.resetFields();
      navigate(`/signin?role=${redirectRole}`);
    });
  };

  const handleFormateurLogout = () => {
    sessionStorage.clear();
    navigate("/signin?role=formateur");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "1rem",
      }}
    >
      <img
        src={import.meta.env.VITE_APP_LOGO}
        style={{ width: "8rem" }}
        alt="logo"
      />
      <div
        className="brand"
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        <h3>
          {role === "formateur"
            ? t("Espace formateur")
            : role === "directeur"
            ? t("Espace directeur")
            : t("Espace investisseur")}
        </h3>
        {user?.nom && user?.prenom ? (
          <Row style={{ justifyContent: "center" }}>
            <UserOutlined style={{ marginRight: "5px" }} />
            <h4>
              {localStorage.getItem("language") === "ar" && user?.nom_ar
                ? `${user?.nom_ar} ${user?.prenom_ar}`
                : `${user?.nom} ${user?.prenom}`}
            </h4>
          </Row>
        ) : (
          user?.nom_ar &&
          user?.prenom_ar && (
            <Row>
              <UserOutlined style={{ marginRight: "5px" }} />
              <h4>{`${user?.nom_ar} ${user?.prenom_ar}`}</h4>
            </Row>
          )
        )}
      </div>
      {renderMenu()}
    </div>
  );
}

Sidenav.propTypes = {
  color: PropTypes.string,
};

export default Sidenav;
