import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import { pdfjs } from "react-pdf";
import * as Sentry from "@sentry/react";
import i18n from "./i18n.jsx";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
dayjs.locale("fr");
const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://aaee1f4d4f9872a0f117e1f5f325b368@sentry.neopolis-dev.com/86",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
    }),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      triggerLabel: "Signaler un problème",
      triggerAriaLabel: "Signaler un problème",
      formTitle: "Signaler un problème",
      submitButtonLabel: "Envoyer le rapport",
      cancelButtonLabel: "Annuler",
      confirmButtonLabel: "Confirmer",
      addScreenshotButtonLabel: "Ajouter une capture d'écran",
      removeScreenshotButtonLabel: "Supprimer la capture d'écran",
      nameLabel: "Nom",
      namePlaceholder: "Votre nom",
      emailLabel: "E-mail",
      emailPlaceholder: "votre.email@exemple.fr",
      isRequiredLabel: "(obligatoire)",
      messageLabel: "Description",
      messagePlaceholder: "Quel est le problème ? Qu'attendiez-vous ?",
      successMessageText: "Merci pour votre rapport !",
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  release: import.meta.env.VITE_APP_VERSION_SENTRY,
  environment: import.meta.env.VITE_APP_ENVIRONMENT_SENTRY,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^http:\/\/localhost:20081\/app/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>
);
