import { SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect } from "react";

import {
  checkDNCINDirector,
  getCadre,
  searchClick,
} from "./CreateEpfComponents/surveyTriggers";
import { useTranslation } from "react-i18next";
import { useLoader } from "../../../context/LoaderContext";
import PropTypes from "prop-types";

const ButtonComponent = ({ question, contrat_id  }) => {
  const { getSurveyData,setSurveyData,showingPreview } = useLoader();

  

  const { t } = useTranslation();
  const buttonExpressions = {
    director_search: {
      apiCall: (survey_) => checkDNCINDirector(survey_, t,getSurveyData,setSurveyData),
    },
    management_data_search: {
      apiCall: (survey_) => getCadre(survey_, t,getSurveyData,setSurveyData),
    },
    data_validation_provisoire_search: {
      apiCall: (survey_) => searchClick(survey_, getSurveyData,contrat_id,t),
    },
  };
  useEffect(() => {
    question.showRefreshBar = (isVisible) => {
      const searchInput = document?.querySelector(
        `div[data-name="${question?.name}"]`
      );
      if (searchInput) {
        searchInput.style.display = "block";
      }
    };
    return () => {
      delete question?.showRefreshBar;
    };
  }, [question]);

  const onClick = () => {
    let expression = buttonExpressions[question.name];
    let _survey = question.survey;
    if (expression && expression.apiCall) {
      return expression.apiCall(_survey);
    }
  };
  return (
    <Button
      id="search"
      type="primary"
      icon={<SearchOutlined />}
      onClick={onClick}
      // disabled={question.readOnly}
      disabled={showingPreview || question.readOnly}
    >
      {t("Rechercher")}
    </Button>
  );
};

ButtonComponent.propTypes = {
  question: PropTypes.object,
  contrat_id: PropTypes.string,
};

export default ButtonComponent;
